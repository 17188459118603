<template>
  <el-form size="small" :class="['no-border one-click-form', error && 'error']" @click.native="error = false">
    <el-form-item v-if="faculty.length">
      <div class="select-wrap">
        <owl-select
          class="select"
          :option-config="{ label: 'value', value: 'key' }"
          :options="faculty"
          :placeholder="facultyTitleName"
          v-model="facultyValue"
          @change="onFacultyChange"
        ></owl-select>
      </div>
    </el-form-item>
    <el-form-item v-if="subject_areaList.join('').length">
      <div class="select-wrap">
        <template v-for="(item, index) in subject_areaList">
          <owl-select
            class="select"
            :key="index"
            :option-config="{ label: 'value', value: 'key' }"
            v-show="finallyJudeShow(item, 'subject_area', index)"
            :options="filterList(item, 'subject_area', index)"
            :placeholder="subjectAreaTitleName"
            v-model="subject_areaValue[index]"
            @change="(val)=>onSubjectChange(val, index, filterList(item, 'subject_area', index))"
          ></owl-select>
        </template>
      </div>
    </el-form-item>
    <el-form-item v-if="faculty_cdetList.join('').length">
      <div class="select-wrap">
        <template v-for="(item, index) in faculty_cdetList">
          <owl-select
            class="select"
            :key="index"
            v-show="finallyJudeShow(item, 'faculty_cdet', index)"
            :option-config="{ label: 'value', value: 'key' }"
            :options="filterList(item, 'faculty_cdet', index)"
            :placeholder="facultyCdetTitleName"
            v-model="faculty_cdetValue[index]"
            @change="(val)=>onFacultyCdetChange(val, index, filterList(item, 'faculty_cdet', index))"
          ></owl-select>
        </template>
      </div>
    </el-form-item>
    <el-form-item v-if="cdet_crse_name4List.join('').length">
      <div class="select-wrap">
        <template v-for="(item, index) in cdet_crse_name4List">
          <owl-select
            class="select"
            :key="index"
            v-show="finallyJudeShow(item, 'cdet_crse_name4', index)"
            :option-config="{ label: 'value', value: 'key' }"
            :options="filterList(item, 'cdet_crse_name4', index)"
            :placeholder="cdetCrseName4TitleName"
            v-model="cdet_crse_name4Value[index]"
            @change="(val)=>onCdetCrseName4Change(val, index, filterList(item, 'cdet_crse_name4', index))"
          ></owl-select>
        </template>
      </div>
    </el-form-item>
    <el-form-item v-if="cdet_crse_name5List.join('').length">
      <div class="select-wrap">
        <template v-for="(item, index) in cdet_crse_name5List">
          <owl-select
            class="select"
            :key="index"
            v-show="finallyJudeShow(item, 'cdet_crse_name5', index)"
            :option-config="{ label: 'value', value: 'key' }"
            :options="filterList(item, 'cdet_crse_name5', index)"
            :placeholder="cdetCrseName5TitleName"
            v-model="cdet_crse_name5Value[index]"
            @change="(val)=>onCdetCrseName5Change(val, filterList(item, 'cdet_crse_name5', index))"
          ></owl-select>
        </template>
      </div>
    </el-form-item>
  </el-form>
</template>
<script>
import OwlSelect from "/packages/components/OwlSelect.vue";
export default {
  name: "OneClickForm",
  props:{
    faculty: Array,
    subject_area: Array,
    faculty_cdet: Array,
    cdet_crse_name4: Array,
    cdet_crse_name5: Array,
    facultyTitleName: String,
    subjectAreaTitleName: String,
    facultyCdetTitleName: String,
    cdetCrseName4TitleName: String,
    cdetCrseName5TitleName: String,
    initCourse: Object,
    initChoose: [Object, Array]
  },
  components:{
    OwlSelect
  },
  watch:{
    initCourse:{
      handler(newVal){
        if(newVal){
          setTimeout(()=>{
            if(this.initChoose && this.initChoose.faculty) return;
            this.setInitByMatchCourse(newVal)
          })
        } else {
          if (this.faculty && this.faculty.length === 1) {
            this.facultyValue = this.faculty[0].key;
          }
        }
      },
      immediate: true
    },
    // 父组件传进来faculty
    faculty: {
      handler(newVal){
        if (newVal && newVal.length === 1) {
          this.facultyValue = newVal[0].key;
        } else {
          this.facultyValue = '';
        }
      },
      immediate: true,
    },
    // 父组件传进来subject_area
    subject_area:{
      handler(newVal){
        if(!newVal || !newVal.length) {
          this.subject_areaList = ['', '']
          return;
        }
        const subject_areaList = JSON.parse(JSON.stringify(newVal))
        this.subject_areaList = ['', ''].map((item, i) => {
          if(i in subject_areaList){
            return subject_areaList[i]
          }
          return item;
        })
      },
      immediate: true
    },
    // 父组件传进来faculty_cdet
    faculty_cdet:{
      handler(newVal){
        if(!newVal || !newVal.length){
          this.faculty_cdetList = ['', '']
          return;
        }
        const faculty_cdetList = JSON.parse(JSON.stringify(newVal))
        this.faculty_cdetList = ['', ''].map((item, i) => {
          if(i in faculty_cdetList){
            return faculty_cdetList[i]
          }
          return item;
        })
        
      },
      immediate: true
    },
    // 父组件传进来cdet_crse_name4
    cdet_crse_name4:{
      handler(newVal){
        if(!newVal || !newVal.length){
          this.cdet_crse_name4List = ['', '']
          return;
        }
        const cdet_crse_name4List = JSON.parse(JSON.stringify(newVal))
        this.cdet_crse_name4List = ['', ''].map((item, i) => {
          if(i in cdet_crse_name4List){
            return cdet_crse_name4List[i]
          }
          return item;
        })
      },
      immediate: true
    },
    // 父组件传进来cdet_crse_name5
    cdet_crse_name5:{
      handler(newVal){
        if(!newVal || !newVal.length){
          this.cdet_crse_name5List = ['', '']
          return;
        }
        const cdet_crse_name5List = JSON.parse(JSON.stringify(newVal))
        this.cdet_crse_name5List = ['', ''].map((item, i) => {
          if(i in cdet_crse_name5List){
            return cdet_crse_name5List[i]
          }
          return item;
        })
      },
      immediate: true
    },
    // 回显数据
    initChoose: {
      handler(newVal){
        if(newVal){
          if(newVal.faculty) this.facultyValue = newVal.faculty.key
          newVal.subject_area && newVal.subject_area.map((item,index) => {
            this.subject_areaValue[index] = item.key
            this.onSubjectChange(item.key, index)
          })
          newVal.faculty_cdet && newVal.faculty_cdet.map((item,index) => {
            this.faculty_cdetValue[index] = item.key
            this.onFacultyCdetChange(item.key, index)
          })
          newVal.cdet_crse_name4 && newVal.cdet_crse_name4.map((item,index) => {
            this.cdet_crse_name4Value[index] = item.key
            this.onCdetCrseName4Change(item.key, index)
          })
          newVal.cdet_crse_name5 && newVal.cdet_crse_name5.map((item,index) => {
            this.cdet_crse_name5Value[index] = item.key
          })
        }
      },
      immediate: true,
      deep: true,
    }
  },
  data(){
    return {
      error: false,
      facultyValue: '',
      subject_areaValue: ['',''],
      faculty_cdetValue: ['',''],
      cdet_crse_name4Value: ['',''],
      cdet_crse_name5Value: ['',''],
      subject_areaList: ['',''],
      faculty_cdetList: ['',''],
      cdet_crse_name4List: ['',''],
      cdet_crse_name5List: ['',''],
    }
  },
  computed:{
    getLevel(){
      let level = [];
      if(this.faculty && this.faculty.length) level.push('faculty');
      if(this.subject_area && this.subject_area.length) level.push('subject_area');
      if(this.faculty_cdet && this.faculty_cdet.length) level.push('faculty_cdet');
      if(this.cdet_crse_name4 && this.cdet_crse_name4.length) level.push('cdet_crse_name4');
      if(this.cdet_crse_name5 && this.cdet_crse_name5.length) level.push('cdet_crse_name5');
      return level;
    },
    
    // 判断父级是否选了值，子级是否隐藏
    finallyJudeShow(){
      return (list, name, i) => {
        const level = this.getLevel
        const parentName = level[level.indexOf(name)-1] //上一级名字
        let show = false;
        if(parentName == 'faculty'){
          show = !!this[parentName+'Value'].length;
        } else {
          show = !!this[parentName+'Value'].join('').length > 0;
        }
        if(show){
          if(!list || !list.length) return false;
          
          if(parentName == 'faculty' ){
            if (this.facultyValue) {
              const newItem = list.find(item => item.parentId == this.facultyValue);
              return Boolean(newItem)
            } else {
              return false;
            }
          } else {
            let parentId = this[parentName+'Value'][i] || this[parentName+'Value'][0] || '';
            if(parentId) {
              const newItem = list.find(item => item.parentId == parentId);
              return Boolean(newItem)
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    }
  },
  methods:{
    filterList(list, name, i) {
      if(!list || !list.length) return []
      const level = this.getLevel
      let index = level.indexOf(name)-1;
      let newList = [];
      // 获取上一级的值，过滤当前数组
      const parentName = level[index] // 找到上一级名字
      if(parentName == 'faculty' ){
        if (this.facultyValue) {
          newList = list.filter(item => item.parentId == this.facultyValue);
        }
      } else {
        let parentId = this[parentName+'Value'][i] || this[parentName+'Value'][0] || '';
        if(parentId) {
          newList = list.filter(item => {
            return item.parentId == parentId
          });
        }
      }
      return newList;
    },
    // 获取提交的值
    getSubmitValue(flag){
      let error = false;  // 验证
      const faculty = this.faculty.find(item=> item.key == this.facultyValue)
      if(!faculty) {
        error = true;
      }
      const list2 = this.subject_areaList;
      const subject_area = this.subject_areaValue.map((item, index) =>{
        if(!error && 
          !item && 
          list2[index] && 
          this.finallyJudeShow(list2[index], 'subject_area', index)
        ){
          error = true;
        }
        if( list2[index] ){
          const ret = list2[index].find(jtem => item == jtem.key);
          if(ret) return JSON.parse(JSON.stringify(ret));
        }
        return '';
      });
      const list3 = this.faculty_cdetList;
      const faculty_cdet = this.faculty_cdetValue.map((item, index) => {
        if(!error && 
          !item && 
          list3[index] &&
          this.finallyJudeShow(list3[index], 'faculty_cdet', index)
        ){
          error = true;
        }
        if( list3[index] ){
          const ret = list3[index].find(jtem => item == jtem.key);
          if(ret) return JSON.parse(JSON.stringify(ret));
        }
        return '';
      
      });
      const list4 = this.cdet_crse_name4List;
      const cdet_crse_name4 = this.cdet_crse_name4Value.map((item, index) => {
        if(!error && 
          !item && 
          list4[index] &&
          this.finallyJudeShow(list4[index], 'cdet_crse_name4', index)
        ){
          error = true;
        }

        if( list4[index] ){
          const ret = list4[index].find(jtem => item == jtem.key);
          if(ret) return JSON.parse(JSON.stringify(ret));
        }
        return '';
      });
      const list5 = this.cdet_crse_name5List;
      const cdet_crse_name5 = this.cdet_crse_name5Value.map((item, index) => {
        if(!error && 
          !item && 
          list5[index] &&
          this.finallyJudeShow(list5[index], 'cdet_crse_name5', index)
        ){
          error = true;
        }
        if( list5[index] ){
          const ret = list5[index].find(jtem => item == jtem.key);
          if(ret) return JSON.parse(JSON.stringify(ret));
        }
        return '';
      });
      // 验证
      if(error && !flag){
        this.error = true
        return false;
      }
      // 如果有分支改个名字
      if(subject_area[1] !== ''){
        subject_area.map((item, i)=>{
          if(item) item.name += (i == 0? '_a': '_b')
          return item;
        })
      }
      if(faculty_cdet[1] !== ''){
        faculty_cdet.map((item, i)=>{
          if(item) item.name += (i == 0? '_a': '_b')
          return item;
        })
      }
      if(cdet_crse_name4[1] !== ''){
        cdet_crse_name4.map((item, i)=>{
          if(item) item.name += (i == 0? '_a': '_b')
          return item;
        })
      }
      if(cdet_crse_name5[1] !== ''){
        cdet_crse_name5.map((item, i)=>{
          if(item) item.name += (i == 0? '_a': '_b')
          return item;
        })
      }
      return {
        faculty: faculty || '',
        subject_area: subject_area,
        faculty_cdet: faculty_cdet,
        cdet_crse_name4: cdet_crse_name4,
        cdet_crse_name5: cdet_crse_name5,
      }
    },
    // 根据课程名赋值下拉选项值
    setInitByMatchCourse(val){
      let {key, name} = val;
      if(!name) return;
      name = name.replace(/_(a|b)$/, '')
      let level = this.getLevel;
      let lng = level.length;
      let index = level.indexOf(name);
      let jndex = index + 1;
      // 清空课程名后的级层
      while(jndex < lng){
        let name = level[jndex];
        this[name+'Value'] = ['', ''];
        jndex++;
      }
      // 自底向上查找
      while(index >= 0){
        let name = level[index];
        if(name == 'faculty'){
          // 如果是顶级
          const item = this[name].find(item => item.key == key);
          if(item) {
            this['facultyValue'] = item.key;
            key = item.parentId;
            // if (item.web) {
            //   this.$emit('get-web', item.web);
            // }
          }
        } else {
          for(let [i, value] of this[name].entries()){
            const item = value.find(item=> item.key == key);
            if(item) {
              // this[name+'Value'][i] = item.key;
              this[name+'Value'].splice(i, 1, item.key);
              key = item.parentId;
              // if (item.web) {
              //   this.$emit('get-web', item.web);
              // }
            }
          }
        }
        index--;
      }
    },
    
    // 第一级下拉改变后
    onFacultyChange(key){
      this.subject_areaValue = ['','']
      this.faculty_cdetValue = ['','']
      this.cdet_crse_name4Value = ['','']
      this.cdet_crse_name5Value = ['','']
      // 如果数据中web字段有数据，则将web字段数据赋值到“课程网申链接”
      // if (this.faculty.length) {
      //   const item = this.faculty.find((option) => option.key === key);
      //   if (item.web) this.$emit('get-web', item.web);
      // }
    },
    // 第二级下拉
    onSubjectChange(key, index, list = null){
      // 如果是分支下拉改动
      if(index == 1){
        // index in this.faculty_cdetValue && (this.faculty_cdetValue[index] = '');
        // index in this.cdet_crse_name4Value && (this.cdet_crse_name4Value[index] = '');
        // index in this.cdet_crse_name5Value && (this.cdet_crse_name5Value[index] = '');
        // if(!this.faculty_cdetList[0].length) return;
        // if(this.faculty_cdetList[1].length && this.faculty_cdetList[1][0].branch == 1) return;

        // 判断分支是否有第三级子项
        // const branch = this.faculty_cdetList[0].filter(item => item.parentId == key);
        // 设置分支值
        // this.$set(this.faculty_cdetList, 1, branch.length ? branch : '')
      } else if(index == 0) {
        this.faculty_cdetValue = ['', '']
        this.cdet_crse_name4Value = ['', '']
        this.cdet_crse_name5Value = ['', '']
      }
      // 如果数据中web字段有数据，则将web字段数据赋值到“课程网申链接”
      // if (list && list.length) {
      //   const item = list.find((option) => option.key === key);
      //   if (item.web) this.$emit('get-web', item.web);
      // }
    },
    // 第三级下拉
    onFacultyCdetChange(key, index, list){
      if(index == 1 ){
        // index in this.cdet_crse_name4Value && (this.cdet_crse_name4Value[index] = '');
        // index in this.cdet_crse_name5Value && (this.cdet_crse_name5Value[index] = '');
        // if(!this.cdet_crse_name4List[0].length) return;
        // if(this.cdet_crse_name4List[1].length && this.cdet_crse_name4List[1][0].branch == 1) return; 
        // 判断分支是否有第四级子项
        // const branch = this.cdet_crse_name4List[0].filter(item => item.parentId == key);
        // 设置分支值
        // this.$set(this.cdet_crse_name4List, 1, branch.length ? branch : '')
      } else if(index == 0) {
        this.cdet_crse_name4Value = ['', '']
        this.cdet_crse_name5Value = ['', '']
      }
      // if (list && list.length) {
      //   const item = list.find((option) => option.key === key);
      //   if (item.web) this.$emit('get-web', item.web);
      // }
    },
    // 第四级下拉
    onCdetCrseName4Change(key, index, list){
      if(index == 1){
        // index in this.cdet_crse_name5Value && (this.cdet_crse_name5Value[index] = '');
        // if(!this.cdet_crse_name5List[0].length) return;
        // if(this.cdet_crse_name5List[1].length && this.cdet_crse_name5List[1][0].branch == 1) return;
        // 判断分支是否有第五级子项
        // const branch = this.cdet_crse_name5List[0].filter(item => item.parentId == key);
        // 设置分支值
        // this.$set(this.cdet_crse_name5List, 1, branch.length ? branch : '')
      } else if(index == 0) {
        this.cdet_crse_name5Value = ['', '']
      }
      // if (list && list.length) {
      //   const item = list.find((option) => option.key === key);
      //   if (item.web) this.$emit('get-web', item.web);
      // }
    },
    onCdetCrseName5Change(key, list){
      // if (list && list.length) {
      //   const item = list.find((option) => option.key === key);
      //   if (item.web) this.$emit('get-web', item.web);
      // }
    },
    // 初始化
    initialValue(){
      this.facultyValue = '';
      this.subject_areaValue = ['',''];
      this.faculty_cdetValue = ['',''];
      this.cdet_crse_name4Value = ['',''];
      this.cdet_crse_name5Value = ['',''];
    }
  }
}
</script>
<style lang="scss" scoped>
.one-click-form{
  padding-top: 16px;
  &.error{
    border-color: #ff9700;
    border-color: var(--form-color-primary);
  }
}
.select-wrap{
  display: flex;
  align-items: center;
  justify-content: space-around;
  .select{
    flex:1;
    margin: 0 6px;
    &[style=""] +.select{
      margin-left: 12px;
    }
  }
}
@media screen and(max-width: 768px){
  .select-wrap{
    .select{
      margin: 0 6px;
      flex: 1;
    }
  }
}
</style>