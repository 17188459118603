<template>
  <el-dialog
    :modal="!isMobile"
    :title="t('stuManagement.applyCofirm')"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    z-index="140"
    center
  >
    <div class="apply-content">
      <div class="apply-title">{{ t("stuManagement.applyStatement") }}</div>
      <p>{{ t("stuManagement.applyTip") }}</p>
      <div class="checkbox-content">
        <el-checkbox-group v-model="checkList">
          <el-checkbox :label="0">{{ t("stuManagement.applyBox1") }}</el-checkbox>
          <el-checkbox :label="1">{{ t("stuManagement.applyBox2") }}</el-checkbox>
          <el-checkbox :label="2">{{ t("stuManagement.applyBox3", {platform: platformType == 'get_mso'? 'MSO': 'ISSUE'}) }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <p class="student-name">
        <span>{{ t("stuManagement.applyName") }} </span>
        <strong>{{ stufullName.lastName }} {{ stufullName.firstName }}</strong>
      </p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">
        {{ t("stuManagement.toBack") }}
      </el-button>
      <el-button type="primary" size="small" :loading="loading" @click="handleConfirm">
        {{ t("stuManagement.hadConfirm") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import i18n from "@/locale/index";
import {
  getissueSubmit,
  getSubmitOnlineForm
} from "@/api/common.js";
export default {
  name: "applyConfrim",
  mixins: [i18n],
  inject: ['platformType','stuid'],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    stufullName: {
      type: Object,
      default: function () {
        return {};
      },
    },
    submitApi:{
      type: String,
      default: 'getissueSubmit'
    }
  },
  data() {
    return {
      checkList: [],
      loading: false,
      isMobile: this.$isMobile(),
    };
  },
  
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    async handleConfirm() {
      if(this.checkList.length !== 3){
        this.$msge.error(this.t("stuManagement.applyTip"))
        return;
      }
      if(this.loading) return;
      this.loading = true;
      let submitTime = null;
      try{
        if(this.submitApi == 'getSubmitOnlineForm') {
          await this.submitOnlineForm();
        } else {
          const { data } = await getissueSubmit(this.stuid());
          console.log(data.data);
          submitTime = data.data;
          // 缺提交时间
        }
        this.$emit("handle-save-form", submitTime);
      } catch {
        this.$alrt(this.t("common.submitFail"), this.t("common.tip"), {confirmButtonText: this.t("common.confirm")})
      }
      this.loading = false;
    },
    async submitOnlineForm(){
      const formData = new FormData();
      formData.append('fkStudentId', this.stuid())
      formData.append('status', 4)
      const html = await this.$parent.$refs.exportFormRef.initFormGetHtml()
      const blob = new Blob([html],{type : 'application/msword'})
      const file = new File([blob], 'document.doc', {type: 'application/msword'})
      formData.append('file', file)
      await getSubmitOnlineForm(formData)
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  text-align: left;
  min-width: 790px;
  padding: 0 40px;
  max-width: 790px;
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__body {
    .apply-content {
      width: 100%;
      p{
        line-height: 22px;
      }
      .el-checkbox-group {
        .el-checkbox {
          width: 100%;
          margin-right: 0;
          margin-top: 18px;
          display: flex;
          span{
            vertical-align: top;
          }
          .el-checkbox__inner{
            margin-top: 4px;
            border-color: #ffb64d;
            border-color: var(--form-color-primary-light);
          }
          .el-checkbox__label {
            white-space: pre-wrap;
            line-height: 22px;
            word-break: break-all;
          }
        }
      }
      .apply-title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
      }
      .student-name{
        margin-top: 16px;
        margin-bottom: 12px;
      }
    }
  }
  .el-dialog__footer{
    padding-right: 0;
    text-align: right;
  }
}
@media screen and(max-width: 768px){
/deep/ .el-dialog {
  width: calc(100% - 20px) !important;
  margin: 10px !important;
  min-width: unset;
  padding: 0 12px;
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__header{
    text-align: center;
  }
  .el-dialog__body {
    padding: 0;
    .apply-content {
      height: 75vh;
      overflow-y: auto;
      .apply-title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
      }
      
    }
    .el-checkbox-group .el-checkbox .el-checkbox__label{
      white-space: pre-line;
    }
  }
}
}

</style>