<template>
  <div>
    <template v-if="!disabled">
      <el-radio-group v-model="_value">
        <template v-if="options && options.length">
          <el-radio 
            v-for="(item, index) of options"
            :key="index"
            :label="item[optionConfig.value]"
          >{{item[optionConfig.label]}}</el-radio>
        </template>
      </el-radio-group>
    </template>
    <div v-else class="label">{{label}}</div>
  </div>
</template>

<script>
export default {
  model:{
    prop: "value",
    event: "change"
  },
  props:{
    value: {
      type: [String, Number, Boolean]
    },
    disabled: {
      type: Boolean
    },
    options: {
      type: Array,
    },
    optionConfig: {
      type: Object,
      require: false,
      defalut: {
        label: "label",
        value: "value"
      }
    }
  },
  
  computed:{
    label(){
      if(!this.options || this.options.length == 0) return "";
      const item = this.options.find(item=> item[this.optionConfig.value] === this.value)
      return item ? item[this.optionConfig.label] : ""
    },
    _value:{
      get(){
        return this.value;
      },
      set(val){
        this.$emit("change", val)
      }
    }
  }
}
</script>