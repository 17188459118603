<template>
  <el-form
    :class="['stu-parent-contact-form no-border', isDisabled && 'disabled']"
    ref="stuParentContactForm"
    :rules="stuParentContactRules"
    :model="stuParentContactData"
    label-position="top"
    size="small"
  >
    <div class="sub-title">
      <owl-btn-set
        :updateShow="isDisabled"
        @update="getUpdate"
        v-if="!hideSubmitBtn()"
      />
    </div>
    <div class="form-tip">
      <p>{{ t("parentContact.suitAge18") }}</p>
    </div>
    <el-row :gutter="10">
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fatherLastName')"
          prop="fatherLastName"
        >
          <owl-input
            v-model="stuParentContactData.fatherLastName"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fatherFirstName')"
          prop="fatherFirstName"
        >
          <owl-input
            v-model="stuParentContactData.fatherFirstName"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fatherPhoneNumber')"
          prop="fatherPhoneNumber"
        >
          <owl-input
            v-model="stuParentContactData.fatherPhoneNumber"
            :disabled="isDisabled"
            :characterOnly="true"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fatherEmail')"
          prop="fatherEmail"
        >
          <owl-input
            v-model="stuParentContactData.fatherEmail"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.monterLastName')"
          prop="monterLastName"
        >
          <owl-input
            v-model="stuParentContactData.monterLastName"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.monterFirstName')"
          prop="monterFirstName"
        >
          <owl-input
            v-model="stuParentContactData.monterFirstName"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.monterPhoneNumber')"
          prop="monterPhoneNumber"
        >
          <owl-input
            v-model="stuParentContactData.monterPhoneNumber"
            :disabled="isDisabled"
            :characterOnly="true"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.monterEmail')"
          prop="monterEmail"
        >
          <owl-input
            v-model="stuParentContactData.monterEmail"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fkStudentAppCountryIdParent')"
          prop="fkStudentAppCountryIdParent"
        >
          <owl-select
            :disabled="isDisabled"
            v-model="stuParentContactData.fkStudentAppCountryIdParent"
            :options="countryOptions"
            :option-config="{ label: 'name', value: 'id' }"
            @change="handleSelectCountry"
            :isMobileCanSearch="isMobileCanSearch"
          ></owl-select>
        </el-form-item>
      </el-col>
      <!-- 国家下拉框是fkAreaCountryId字段有值省，城市显示下拉框，否则输入框-->
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fkAreaStateIdParent')"
          prop="fkAreaStateIdParent"
        >
          <owl-select
            allow-create
            :disabled="isDisabled"
            v-model="stuParentContactData.fkAreaStateIdParent"
            @change="handleSelectState"
            :options="stateOptions"
            :option-config="{ label: 'fullName', value: 'id' }"
            :isMobileCanSearch="isMobileCanSearch"
          ></owl-select>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.fkAreaCityIdParent')"
          prop="fkAreaCityIdParent"
        >
          <owl-select
            allow-create
            :disabled="isDisabled"
            v-model="stuParentContactData.fkAreaCityIdParent"
            :options="cityOptions"
            :option-config="{ label: 'name', value: 'id' }"
            @change="changeVal"
          ></owl-select>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.parentContactAddress1')"
          prop="parentContactAddress1"
        >
          <owl-input
            v-model="stuParentContactData.parentContactAddress1"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.parentContactAddress2')"
          prop="parentContactAddress2"
        >
          <owl-input
            v-model="stuParentContactData.parentContactAddress2"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-form-item
          :label="t('parentContact.parentContactPostcode')"
          prop="parentContactPostcode"
          :rules="[checkPostCard(stuParentContactData.fkStudentAppCountryIdParent)]"
        >
          <owl-input
            v-model="stuParentContactData.parentContactPostcode"
            :disabled="isDisabled"
            @change="changeVal"
          ></owl-input>
        </el-form-item>
      </el-col>
    </el-row>
    <owl-btn-save v-if="!isDisabled" @save="handleSave" show-delete @delete="cancelEditing" />
  </el-form>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import {
  getStateOptions,
  getCityOptions,
  saveOrUpdateStudentParentData,
} from "@/api/common.js";
export default {
  name: "StudentParentContact",
  components: { OwlInput, OwlSelect, OwlBtnSave, OwlBtnSet },
  mixins: [mixin, i18n],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn","platformType", "platformLimitList"],
  props: {
    studentParentsDto: {
      type: Object,
      default: function () {
        return {};
      },
    },
    required: Boolean,
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      showDetailData: false,
      stateOptions: [],
      cityOptions: [],
      // 国家决定省份城市是输入框
      isShowInput: false,
      stuParentContactData: {
        id: this.stuid(), // 学生id
        fatherLastName: "", // 父亲的姓
        fatherFirstName: "", // 父亲的名
        fatherEmail: "", // 父亲的邮箱地址
        fatherPhoneNumber: "", // 父亲的联系电话
        monterLastName: "", // 母亲的姓
        monterFirstName: "", // 母亲的名
        monterEmail: "", // 母亲的邮箱地址
        monterPhoneNumber: "", // 母亲的联系电话
        fkStudentAppCountryIdParent: "", // 父母的国家
        fkAreaStateIdParent: "", // 父母的省份
        fkAreaCityIdParent: "", // 父母的城市
        parentContactAddress1: "", // 父母联系地址1
        parentContactAddress2: "", // 父母联系地址2
        parentContactPostcode: "", // 父母联系地址邮编
      },
      stuParentContactRules: {},
      rulesDataList:[
        'fatherLastName','fatherFirstName','fatherPhoneNumber','fatherEmail',
        'monterLastName','monterFirstName','monterPhoneNumber','monterEmail',
        'fkStudentAppCountryIdParent','fkAreaStateIdParent','fkAreaCityIdParent',
        'parentContactAddress1','parentContactAddress2','parentContactPostcode'
      ]
    };
  },
  computed: {
    countryOptions() {
      return this.ctryOptions();
    },
    isDisabled(){
      return this.hideSubmitBtn() || !this.isEditing('parentContact')
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  watch: {
    studentParentsDto(newVal) {
      if ( newVal && newVal.fatherFirstName !== null) {
        this.setInitData(newVal);
      }
    },
  },
  mounted() {
    this.stuParentContactRules = {
      fatherLastName:[],
      fatherFirstName:[],
      fatherPhoneNumber:[],
      monterLastName:[],
      monterFirstName:[],
      monterPhoneNumber:[],
      fatherEmail: [
        {
          required: false,
          message: this.t("validate.requiredField"),
          trigger: "blur",
        },
        {
          type: "email",
          message: this.t("validate.emailValid"),
          trigger: ["blur", "change"],
        },
      ],
      monterEmail: [
        {
          required: false,
          message: this.t("validate.requiredField"),
          trigger: "blur",
        },
        {
          type: "email",
          message: this.t("validate.emailValid"),
          trigger: ["blur", "change"],
        },
      ],
      fkStudentAppCountryIdParent:[],
      fkAreaStateIdParent:[],
      fkAreaCityIdParent:[],
      parentContactAddress1:[],
      parentContactAddress2:[],
      parentContactPostcode:[],
    };
    for(const item of this.rulesDataList){
      const requiredRules=[{
        required: false,
        message: this.t("validate.requiredField"),
        trigger: ["change", "blur"],
      }];
      this.stuParentContactRules[item]=requiredRules;
    }
    this.changeVal();
    this.$nextTick(() => {
      this.$refs.stuParentContactForm.clearValidate();
    });
  },
  methods: {
    getUpdate() {
      this.switchEditStatus('parentContact', this.stuParentContactData)
      this.onFormStatusChanged();
    },
    // 获取已有回显数据
    async setInitData(showData) {
      if(!showData){
        return;
      }
      this.stuParentContactData = {
        fatherLastName: showData.fatherLastName,
        fatherFirstName: showData.fatherFirstName,
        fatherEmail: showData.fatherEmail,
        fatherPhoneNumber: showData.fatherPhoneNumber,
        monterLastName: showData.monterLastName,
        monterFirstName: showData.monterFirstName,
        monterEmail: showData.monterEmail,
        monterPhoneNumber: showData.monterPhoneNumber,
        parentContactAddress1: showData.parentContactAddress1,
        parentContactAddress2: showData.parentContactAddress2,
        parentContactPostcode: showData.parentContactPostcode,
        fkStudentAppCountryIdParent: this.getIdTypeValue(showData.fkStudentAppCountryIdParent),
        fkAreaStateIdParent: this.getIdTypeValue(showData.fkAreaStateIdParent),
        fkAreaCityIdParent: this.getIdTypeValue(showData.fkAreaCityIdParent),
        id: this.stuid(),
      };
      const ctryData = this.stuParentContactData.fkStudentAppCountryIdParent;
      const stateData = this.stuParentContactData.fkAreaStateIdParent;
      await this.getStateData(ctryData);
      if (stateData && !isNaN(Number(stateData))) {
        await this.getCityData(stateData);
      }
    },
    // 值发生改变时
    changeVal(){
      const data=this.stuParentContactData;
      const el=this.stuParentContactRules;
      let flag=false;
      for(const item of this.rulesDataList){
        el[item][0].required=false;
        // 父亲
        if(data.fatherLastName||data.fatherFirstName||data.fatherPhoneNumber||data.fatherEmail){
          el.fatherLastName[0].required=true;
          el.fatherFirstName[0].required=true;
          el.fatherPhoneNumber[0].required=true;
          el.fatherEmail[0].required=true;
          flag=true;
        }
        // 母亲
        if(data.monterLastName||data.monterFirstName||data.monterPhoneNumber||data.monterEmail){
          el.monterLastName[0].required=true;
          el.monterFirstName[0].required=true;
          el.monterPhoneNumber[0].required=true;
          el.monterEmail[0].required=true;
          flag=true;
        }
        // 其他资料
        if(flag){
          el.fkStudentAppCountryIdParent[0].required=true;
          el.fkAreaStateIdParent[0].required=true;
          el.fkAreaCityIdParent[0].required=true;
          el.parentContactAddress1[0].required=true;
          el.parentContactAddress2[0].required=true;
          el.parentContactPostcode[0].required=true;
        }else{
          this.$nextTick(() => {
            this.$refs.stuParentContactForm.clearValidate();
          });
        }
      }
    },
    // 获取省
    getStateData(country) {
      if (country) {
        getStateOptions(Number(country)).then((res) => {
          this.stateOptions = res.data.datas || [];
        });
      }
    },
    // 获取城市
    getCityData(state) {
      if (!state || isNaN(state)) return;
      getCityOptions(state).then((res) => {
        this.cityOptions = res.data.datas || [];
      });
    },
    // 国家
    handleSelectCountry(countryId) {
      this.stuParentContactData.fkAreaStateIdParent = "";
      this.stuParentContactData.fkAreaCityIdParent = "";
      this.getStateData(countryId);
      let optionIndex = "";
      this.countryOptions.forEach((v, i) => {
        if (v.id === countryId) {
          optionIndex = i;
        }
      });
      if (
        this.countryOptions[optionIndex] &&
        this.countryOptions[optionIndex].fkAreaCountryId
      ) {
        this.isShowInput = true;
      } else {
        this.isShowInput = false;
      }
      this.changeVal();
    },
    // 省
    handleSelectState(stateId) {
      this.stuParentContactData.fkAreaCityIdParent = null;
      this.cityOptions = [];
      if (!stateId || isNaN(stateId)) {
        return;
      }
      if (this.stateOptions && this.stateOptions.length !== 0) {
        this.getCityData(stateId);
      }
      this.changeVal();
    },
     // 提供检查当前表单状态方法
    async checkFormStatus() {
      const isEdit = this.editList.length == 0
      if(!this.required) return {isEdit, validate: true, error: this.t('validate.relativesContact')};
      let validate = true;
      try {
        await this.$refs.stuParentContactForm.validate()
      } catch {
        validate = false;
      }
      return {
        isEdit,
        validate,
        error: this.t('validate.relativesContact')
      }
    },
    // 保存
    async handleSave() {
      let error = false;
      this.$refs.stuParentContactForm.validate((valid) => {
        if (!valid) {
          error = true;
        }
      });
      if(error) return;
      try {
        const res = await saveOrUpdateStudentParentData(this.stuParentContactData)
        if (res.data.code === "1003") {
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          this.addCompletedStatus()
          this.switchEditStatus('parentContact')
          this.onFormStatusChanged()
        } else {
          this.$msge({
            message: this.t("common.saveFail"),
            type: "error",
          });
        }
      } catch {
        this.$msge({
          message: this.t("common.saveFail"),
          type: "error",
        });
      }
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(this.isDisabled) return;
      await this.handleSave()
    },
    // 取消
    cancelEditing(){
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(()=>{
        const backup = this.getEditBackupByKey('parentContact')
        this.$refs.stuParentContactForm.clearValidate();
        this.setInitData(backup)
        this.switchEditStatus('parentContact')
        this.onFormStatusChanged()
      })
    },
    onFormStatusChanged(){
      let proccess = 0;
      if(!this.editList.length && this.stuParentContactData.fatherLastName){
        proccess = 100;
      }
      this.$emit('on-porccess-changed', proccess)
    }
  },
};
</script>