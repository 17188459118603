<template>
  <div>
    <template v-if="!disabled ">
      <el-input 
        v-if="type == 'input' || type == 'number' || type == 'textarea'" 
        v-model="_value" 
        clearable 
        :disabled="isDisable"
        :type="type" 
        :rows="rows"
        :size="size"
        :placeholder="placeholder"
        :maxlength="maxlength"
        @blur="$emit('blur',$event)"
      >
        <template slot="prepend">
          <slot name="prepend"></slot>
        </template>
        <template slot="suffix">
          <span v-show="maxlength">{{(_value+'').length +'/'+maxlength}}</span>
          <slot name="suffix" ></slot>
        </template>
      </el-input>
      <template v-if="type == 'date' || type == 'month'">
        <el-date-picker
          v-if="!isMobile"
          :value-format="valueFormat"
          v-model="_value"
          :type="type"
          :size="size"
          clearable 
          :picker-options="pickOptions"
          :placeholder="placeholder"
        />
        <owl-mobile-date v-else v-model="_value" :placeholder="placeholder"/>
      </template>
      
    </template>
    <div v-else class="label">
      <slot name="prepend"></slot>
      <p v-html="wrap(_value)||'&nbsp;'"></p>
      <span class="suffix"><slot name="suffix"></slot></span>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props: {
    size: {
      type: String,
      default: '',
      require: false,
    },
    value: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: "input",
      require: false
    },
    isDisable:{
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd",
      require: false
    },
    rows: {
      type: Number,
      default: 2,
      require: false
    },
    englishOnly:{
      type: Boolean,
      default: true,
      require: false,
    },
    placeholder: String,
    characterOnly: {
      type: Boolean,
      default: false,
      require: false
    },
    maxlength: {
      type: [Boolean, Number, String],
      default: false,
      require: false,
    },
    pickOptions: {
      type: Object,
      require: false,
      default: ()=>({
        shortcuts: [
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 3)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 3)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 10)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 10)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 18)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 18)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
          {
            text: (()=>{
              const date = new Date()
              date.setFullYear(date.getFullYear() - 22)
              return date.getFullYear()+'-01-01'
            })(),
            onClick(picker){
              const date = new Date()
              date.setFullYear(date.getFullYear() - 22)
              date.setMonth(0)
              date.setDate(1)
              picker.$emit('pick', date)
            }
          },
        ]
      })
    }
  },
  computed:{
    isMobile(){
      return this.$isMobile();
    },
    wrap(){
      return(val)=>{
        if(this.type == 'textarea'){
          if(val && typeof val=== 'string'){
            if(this.disabled){
              return val.replace(/\n/g, '<br>');
            }
          }
        }
        return val;
      }
    },
    _value: {
      get(){
        return this.value;
      },
      set(val){
        if(this.maxlength && typeof val != 'number' && this.maxlength < val.length ){
          val = val.substring(0, this.maxlength);
        }
        if(val&&val.toString().match(/[。，！？·【】—《》‘’“”、；：|（）]/)){
          val = val.toString().replace(/[。，！？·【】—《》‘’“”、；：|（）]/g, '');
          this.$msge.error(this.t('validate.pleaseEnPunctuation'));
        }
        if(this.englishOnly && val  ){
          if(val.toString().match(/[\u4e00-\u9fa5]/)) {
            val = val.toString().replace(/[\u4e00-\u9fa5]/g, '');
            this.$msge.error(this.t('validate.onlyEnlishInput'));
          }
        }
        if(this.characterOnly && val && val.toString().match(/[\u4e00-\u9fa5a-zA-Z]/) ){
          val = val.toString().replace(/[\u4e00-\u9fa5wa-zA-Z]/g, '');
          this.$msge.error(this.t('validate.onlyNumberOrCharInput'));
        }
        this.$emit("change", val);
      }
    }
  },
}
</script>