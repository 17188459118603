import Vue from "vue";
import VueRouter from "vue-router";
import StudentAllFormInfo from "/packages/student-all-form-info/src/main.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: StudentAllFormInfo,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: '/mso-student-form',
    name: 'MSOStudentForm',
    component: () => import('/packages/mso-student-form/src/main.vue')
  },
  {
    path: '/apply-student-online-form',
    name: 'applyStudentOnlineForm',
    component: ()=> import(/* webpackChunkName: "applyStudentOnlineForm" */ "../views/applyStudentOnlineForm.vue"),
  },
  {
    path: '/scholarship-to-form',
    name: 'scholarshipToForm',
    component: ()=> import(/* webpackChunkName: "scholarshipToForm" */ "../views/scholarshipToForm.vue"),
  }
];
if(process.env.NODE_ENV === 'production'){
  const route = routes.find(item=>item.name == 'Home')
  route.redirect = {name: 'applyStudentOnlineForm'}
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
