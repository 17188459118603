<template>
  <div class="stu-prof-qual">
    <title-button-comp
      :showMainTitle="showMainTitle"
      :mainTitle="t('qualificationInfo.qualificationInfo')"
      :buttonName="t('qualificationInfo.addQualf')"
      @handleAdd="handleAdd"
      :loading="loading"
      :addShow="!hideSubmitBtn()"
    ></title-button-comp>
    <el-form
      v-for="(profQualif, index) in stuProfQualFormData.profQualifs"
      :key="profQualif.uniqueKey"
      :class="[
        'stu-prof-form',
        !isEditing(profQualif.uniqueKey) && 'disabled show-form',
      ]"
      :ref="'stuWorkForm'+profQualif.uniqueKey"
      :rules="stuProfRules"
      :model="stuProfQualFormData"
      label-position="top"
      size="small"
    >
      <div class="sub-title">
        {{ t("qualificationInfo.qualificationInfo") }}
        {{ stuProfQualFormData.profQualifs.length - index }}
        <owl-btn-set
          v-if="!hideSubmitBtn()"
          :updateShow="!isEditing(profQualif.uniqueKey)"
          @update="editProfItem(profQualif)"
          deleteShow
          @delete="deleteProfQual(profQualif, index)"
        />
      </div>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationName')"
            :prop="'profQualifs.' + index + '.qualificationName'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="profQualif.qualificationName"
              :disabled="!isEditing(profQualif.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationGroup')"
            :prop="'profQualifs.' + index + '.qualificationGroup'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="profQualif.qualificationGroup"
              :disabled="!isEditing(profQualif.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.fkStudentAppCountryId')"
            :prop="'profQualifs.' + index + '.fkStudentAppCountryId'"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(profQualif.uniqueKey)"
              v-model="profQualif.fkStudentAppCountryId"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationGrade')"
            :prop="'profQualifs.' + index + '.qualificationGrade'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              v-model="profQualif.qualificationGrade"
              :disabled="!isEditing(profQualif.uniqueKey)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationStartDate')"
            :prop="'profQualifs.' + index + '.qualificationStartDate'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              type="date"
              v-model="profQualif.qualificationStartDate"
              :disabled="!isEditing(profQualif.uniqueKey)"
              @change="handleQualStartDate(profQualif, index)"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationGetDate')"
            :prop="'profQualifs.' + index + '.qualificationGetDate'"
            :rules="rules.needFiledRule"
          >
            <owl-input
              type="date"
              v-model="profQualif.qualificationGetDate"
              :disabled="!isEditing(profQualif.uniqueKey)"
              @change="handleQualGetDate(profQualif, index)"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('qualificationInfo.qualificationGetStatus')"
            :prop="'profQualifs.' + index + '.qualificationGetStatus'"
            :rules="rules.numberSelectRule"
          >
            <owl-select
              :disabled="!isEditing(profQualif.uniqueKey)"
              v-model="profQualif.qualificationGetStatus"
              :options="getStatusOption"
              :option-config="{ label: 'name', value: 'id' }"
              @change="qualificationGetStatusChange(profQualif, index)"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <owl-btn-save
        v-if="isEditing(profQualif.uniqueKey)"
        @save="handleSave(profQualif.uniqueKey, index)"
        @delete="cancelProfQual(profQualif, index)"
        show-delete
        :loading="loading"
      />
    </el-form>
    <div class="form-no-data-tip" v-if="!stuProfQualFormData.profQualifs.length">
      {{t('validate.noFormDataTip')}}
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import {
  saveOrUpdateQualification,
  deleteQualification,
} from "@/api/common.js";
export default {
  name: "StudentProfessionalQualification",
  mixins: [mixin, i18n],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn","platformType", "platformLimitList"],
  components: {
    OwlSelect,
    OwlInput,
    OwlBtnSet,
    OwlBtnSave,
  },
  props: {
    studentQualificationDtos: {
      type: Array,
      default: function () {
        return [];
      },
    },
    required: Boolean,
    showMainTitle: Boolean
  },
  computed: {
    countryOptions() {
      return this.ctryOptions();
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      loading: false,
      getStatusOption: [],
      stuProfRules: {},
      showDetailData: false,
      stuProfQualFormData: {
        profQualifs: [
          // {
          //   fkStudentId: "", //
          //   fkStudentAppCountryId: "", // 认证的资格国家或地区
          //   qualificationGetDate: "", // 取得资格日期 / 预计取得资格日期
          //   qualificationGetStatus: "", // 专业资格状态
          //   qualificationGrade: "", // 认证级别
          //   qualificationGroup: "", // 认证机构
          //   qualificationName: "", // 资格名称
          //   qualificationStartDate: "", // 资格课程开始日期
          //   id: null,
          // },
        ],
      },
    };
  },
  mounted() {
    this.getStatusOption = [
      { id: 0, name: this.t("qualificationInfo.qualificationGetStatus0") },
      { id: 1, name: this.t("qualificationInfo.qualificationGetStatus1") },
    ];
  },
  methods: {
    async setInitData(data) {
      if(!data || !data.length) return
      this.loading = true;
      const profQualifs = [];
      for await (let item of data) {
        let key = Date.now();
        profQualifs.push({
          fkStudentAppCountryId: item.fkStudentAppCountryId,
          qualificationGetDate: item.qualificationGetDate,
          qualificationGetStatus: item.qualificationGetStatus,
          qualificationGrade: item.qualificationGrade,
          qualificationGroup: item.qualificationGroup,
          qualificationName: item.qualificationName,
          qualificationStartDate: item.qualificationStartDate,
          id: item.id,
          fkStudentId: this.stuid(),
          uniqueKey: key,
        });
      }
      this.stuProfQualFormData.profQualifs = profQualifs;
      this.loading = false;
    },
    qualificationGetStatusChange(profQualifs, index) {
      if (profQualifs.qualificationGetStatus === 1) {
        this.AcquiredDate(profQualifs.qualificationGetDate, index);
      }
    },
    // 取得日期提示
    AcquiredDate(endDate, index) {
      if (endDate) {
        if (new Date(endDate).getTime() >= Date.now()) {
          endDate = "";
          this.$alrt(this.t("validate.checkeQualificationGetStatus"),'', {
            confirmButtonText: this.t("common.confirm"),
          });
          this.stuProfQualFormData.profQualifs[index].qualificationGetDate = "";
        }
      }
    },
    // 新增
    handleAdd() {
      if(this.hasEdit()) return;
      const uniqueKey = Date.now();
      this.stuProfQualFormData.profQualifs.unshift({
        fkStudentId: this.stuid(),
        qualificationName: "",
        qualificationGroup: "",
        fkStudentAppCountryId: "",
        qualificationGrade: "",
        qualificationStartDate: "",
        qualificationGetDate: "",
        qualificationGetStatus: "",
        uniqueKey,
      });
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged();
    },
    // 日期
    handleQualStartDate(profQual, index) {
      const dateStr = profQual.qualificationGetDate;
      const qualStartDate = new Date(profQual.qualificationStartDate).getTime();
      const qualGetDate = new Date(dateStr).getTime();
      if (dateStr) {
        if (qualStartDate > qualGetDate) {
          this.$alrt(
            this.t("validate.qualDateValid", {
              startDate: "(" + profQual.qualificationStartDate + ")",
            }),
            "",
            {
              confirmButtonText: this.t("common.confirm"),
            }
          );
          this.stuProfQualFormData.profQualifs[index].qualificationStartDate =
            "";
        }
      }
    },
    // 日期
    handleQualGetDate(profQual, index) {
      const dateStr = profQual.qualificationStartDate;
      const qualGetDate = new Date(profQual.qualificationGetDate).getTime();
      const qualStartDate = new Date(dateStr).getTime();
      if (dateStr) {
        if (qualStartDate > qualGetDate) {
          this.$alrt(
            this.t("validate.qualDateValid", {
              getDate: "(" + profQual.qualificationGetDate + ")",
            }),
            "",
            {
              confirmButtonText: this.t("common.confirm"),
            }
          );
          this.stuProfQualFormData.profQualifs[index].qualificationGetDate = "";
        }
      }
      if (
        this.stuProfQualFormData.profQualifs[index].qualificationGetStatus === 1
      ) {
        this.AcquiredDate(profQual.qualificationGetDate, index);
      }
    },
    // 删除
    deleteProfQual(profQual, index) {
      if(this.loading) return;
      const vueComponent = this.$refs['stuWorkForm'+profQual.uniqueKey][0];
      if (vueComponent) {
        vueComponent.clearValidate();
      }
      this.$confIssue(this.t("validate.delMsgQualInfo"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async () => {
        if (profQual.id) {
          this.loading = true;
          try {
            await deleteQualification(profQual.id)
          } catch {
            this.$msge({
              type: "error",
              message: this.t("common.deleteFail"),
            });
            return;
          } finally {
            this.loading = false;
          }
        }
        this.stuProfQualFormData.profQualifs.splice(index, 1);
        if (!this.stuProfQualFormData.profQualifs.length) {
          this.addUncompletedStatus();
        }
        this.isEditing(profQual.uniqueKey) && this.switchEditStatus(profQual.uniqueKey)
        this.onFormStatusChanged();
        this.$msge({
          type: "success",
          message: this.t("common.deleteSucc"),
        });
      });
    },
    // 取消
    cancelProfQual(profQual, index){
       this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(()=>{
        const backup = this.getEditBackupByKey(profQual.uniqueKey)
        this.switchEditStatus(profQual.uniqueKey)
        this.onFormStatusChanged();
        if(backup) {
          this.$set(this.stuProfQualFormData.profQualifs, index, backup)
        } else {
          this.stuProfQualFormData.profQualifs.splice(index, 1)
        }
      })
    },
    async checkFormStatus() {
      if(!this.required) return {
        isEdit: this.editList.length == 0, 
        validate: true,
        error: this.t('validate.qualifications')};
      let validate = true;
      try {
        const temp = Object.keys(this.$refs);
        if(this.$refs && temp && temp.length > 1) {
          for await(let key of temp) {
            if (this.$refs[key] && !this.$refs[key].length) {
                this.$refs[key] = null;
                continue;
              }
            if(this.$refs[key] && this.$refs[key].length){
              await this.$refs[key][0].validate();
            }
          }
        }
      } catch {
        validate = false;
      }
      return {
        isEdit: this.editList.length == 0 && this.stuProfQualFormData.profQualifs.length > 0,
        validate,
        error: this.t('validate.qualifications')
      }
    },
    // 更新
    editProfItem(item) {
      if(this.hasEdit()) return;
      this.switchEditStatus(item.uniqueKey, item);
      this.onFormStatusChanged()
    },
    // 单个提交更新
    async handleSave(uniqueKey, index) {
      let error = false;
      this.$refs['stuWorkForm'+uniqueKey][0].validate((valid) => {
        if(!valid) error = true;
      })
      if(error) return;
      this.loading = true;
      const item = this.stuProfQualFormData.profQualifs[index];
      try {
        const res = await saveOrUpdateQualification(item);
        if (res.data.code === "1003") {
          this.switchEditStatus(uniqueKey);
          this.onFormStatusChanged();
          if (this.stuProfQualFormData.profQualifs.length === 1) {
            this.addCompletedStatus()
          }
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          if (!item.id) item.id = res.data.data;
        } else {
          this.$msge({
            message: this.t("common.saveFail"),
            type: "error",
          });
        }
      } finally {
        this.loading = false;
      }
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.stuProfQualFormData.profQualifs.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    onFormStatusChanged(){
      let lng = this.stuProfQualFormData.profQualifs.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    },
  },
};
</script>