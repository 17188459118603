import wx from 'weixin-js-sdk'
export function debounce(
  fun,
  delay = 200,
  immediate = false
) {
  let timer = null;
  return function () {
    const args = arguments;

    if (timer) clearTimeout(timer);
    if (immediate) {
      // @ts-ignore
      if (!timer) fun.apply(this, args);
      timer = setTimeout(() => (timer = null), delay);
    } else {
      timer = setTimeout(() => {
        // @ts-ignore
        fun.apply(this, args);
      }, delay);
    }
  };
}

export function createWrapDom(id){
  if(!id){
    return;
  } else if(document.querySelector(id)){
    return;
  }
  const dom = document.createElement('div')
  dom.id = id
  const bodyDom = document.querySelector('body')
  bodyDom.appendChild(dom)
}
export const EmailReg = /[\w.%+-]+@[\w.-]+\.\w{2,6}/
// 相对严谨的邮件校验
export const checkEmail2 = (email) => {
  // const regEmail: RegExp = /^([a-zA-Z0-9_-])+@([a-zA-Z-0-9_-])+(\.[a-zA-Z0-9_-])+/
  // const regEmail: RegExp = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/;
  const regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z-0-9-]+\.{0,1}[a-zA-Z-0-9-]+\.{0,1}[a-zA-Z0-9-]*\.[a-zA-Z0-9.]{2,20}$/;
  return regEmail.test(email)
}
// 检查邮箱是否多个// 根据;或空格进行分割填写多个邮箱
export const checkEmailList = (email)=>{
  let val=[]
  if(email){
    // 根据;或空格进行分割填写多个邮箱
    const emailList=email.trim().split(/[(;)][(\s)]/);
    if(emailList&&emailList.length>1){
      val=emailList;
    }else{
      val=emailList;
    }
  }
  return val
}
// 检查当前域名，根据域名区别来调用哪个账号
export function checkHostNameUrl(){
  const hostName=window.location.hostname;
  let hostType='gea';// 默认gea
  if(hostName.indexOf('app.ht-international.net')!==-1){
    hostType='hti';
  }
  return hostType
}
// 检查域名，调用对应的账号
export function checkUserName(){
  const hostType=checkHostNameUrl();
  let username='apply_gea';// 默认gea账号
  if(hostType==='hti'){
    username='apply_iae';
  }
  return username
}

// 复制到剪贴板
export function copyToClipboard (text) {
  if (navigator.clipboard) {
      // clipboard api 复制
      navigator.clipboard.writeText(text);
  } else {
      var textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = 'fixed';
      textarea.style.clip = 'rect(0 0 0 0)';
      textarea.style.top = '10px';
      // 赋值
      textarea.value = text;
      // 选中
      textarea.select();
      // 复制
      document.execCommand('copy', true);
      // 移除输入框
      document.body.removeChild(textarea);
  }
}
// 判断是否在微信小程序中浏览
// 是否是微信小程序
export function getIsWexinMiniprogram() {
  let status = false;
  let ua = navigator.userAgent.toLowerCase();
  let isWeixin = ua.indexOf('micromessenger') !== -1;
  // 判断是否在微信浏览器内
  if(isWeixin) {
    wx.miniProgram.getEnv((res) => {
      status = res.miniprogram ? true : false;
    })
  }else {
    status = false;
  }
  return status;
}