export default {
  methods: {
    // TOEFL Ibt  IELTS  PTE  University Test公共部分
    showToeflType(type) {
      if (
        type === 2 ||
        type === 1 ||
        type === 4 ||
        type === 10
      ) {
        return true;
      } else {
        return false;
      }
    },
    // GMAT GRE 公共部分
    showGmatType(type) {
      if (type === "GMAT" || type === "GRE") {
        return true;
      } else {
        return false;
      }
    },
    // GMAT GRE SAT ACT 公共部分
    showGGSAType(type) {
      if (
        // type === "GMAT" ||
        // type === "GRE" ||
        // type === "SAT" ||
        // type === "ACT" ||
        type === 7 ||
        type === 9 ||
        type === 8 ||
        type === 6
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
