export default {
  data() {
    return {
      data: 2,
      rules: {
        needFiledRule: {
          required: true,
          validator: this.checkIsEmpty,
          trigger: ["change", "blur"],
        },
        urlRule: {
          validator: this.checkUrlRule,
          tirgger: ["blur"]
        },
        needFiledRuleInput: {
          required: true,
          validator: this.checkIsEmpty,
          trigger: ["blur"],
        },
        needFieldRadio: {
          required: true,
          validator: this.checkRadioNull,
          trigger: ["change"],
        },
        numberSelectRule: {
          required: true,
          validator: this.checkNumberSelect,
          trigger: ["change"],
        },
        noChinese: {
          validator: this.checkChinese,
          tirgger: ['blur']
        }
      },
      editList: [], // 保存模块下启动编辑状态的key
      editBackUp: {} // 存储备份数据
    };
  },
  props: {
    compName: {
      type: String,
      default: ''
      // required: true,
    }
  },
  computed:{
    // 正在编辑
    isEditing() {
      return (val) => {
        if (!val) return false;
        return this.editList.indexOf(val) >= 0;
      }
    },
    // 邮政编码验证
    checkPostCard() {
      return (country) => {
        return {
          validator: (rule, value, callback)=>{
            if(country == 44){
              if(value.length != 6){
                callback(new Error(this.t("validate.pleaseInputChinaPost")));
              } else {
                callback()
              }
            } else if(country == 235){
              if(value.length != 5){
                callback(new Error(this.t("validate.pleaseInputVietnamPost")));
              } else {
                callback()
              }
            } else if (country == 211) {
              if(value.length >= 3 && value.length <= 6) {
                callback();
              } else {
                callback(new Error(this.t('validate.contactPostcodeTaiWanValid')));
              }
            } else if(country == 228){
              if(value.length >= 5 && value.length <= 8) {
                callback();
              } else {
                callback(new Error(this.t('validate.contactPostcodeUKValid')));
              }
            }else{
              callback()
            }
          },
          trigger: ["blur"],
        }
      }
    },
    // 手机号码验证
    checkAreaCode() {
      return (area_code) => {
        return {
          validator: (rule, phoneval, callback)=>{
            let checkFlag = false;
            let phone_reg;
            area_code = area_code + '';
            switch (area_code) {
              case "86":
                phone_reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/; // 中国大陆：开头1 3-8号段，后边跟9位数字
                checkFlag = phone_reg.test(phoneval);
                break;
              case "852":
                // phone_reg = /^([4|5|6|7|9])\d{7}$/; // 5或9或6开头后面跟7位数字
                phone_reg = /^\d{8}$/; // 香港8位数字
                checkFlag = phone_reg.test(phoneval);
                break;
              case "853":
                phone_reg = /^[6]\d{7}$/; // 6开头后面跟7位数字
                checkFlag = phone_reg.test(phoneval);
                break;
              case "886":
                phone_reg = /^([0]?[9])\d{8}$/; // 9(或09)开头后面跟8位数字
                checkFlag = phone_reg.test(phoneval);
                break;
              case "61":
                phone_reg = /^[4]\d{8}$/; // 澳大利亚4开头，后面跟8位数
                checkFlag = phone_reg.test(phoneval);
                break;
              case "44":
                phone_reg = /^([0]?[7])\d{9}/; // 英国电话号码是7(或07)开头的,后面跟9位数
                checkFlag = phone_reg.test(phoneval);
                break;
              default:
                checkFlag = /^\d+$/.test(phoneval);
                break;
            }
            if(checkFlag || !area_code){
              callback();
            } else {
              callback(new Error(this.t("validate.pleaseCheckPhoneNumber")))
            }
          },
          trigger: ["blur"],
        }
      }
    }
  },
  methods: {
    // 切换编辑和回显状态
    switchEditStatus(val, backup = '') {
      let retIndex = this.editList.indexOf(val);
      if (retIndex >= 0) {
        this.editList.splice(retIndex, 1);
        this.editBackUp[val] = ''
      } else {
        this.editList.push(val);
        // 存储备份数据
        if(backup){
          this.editBackUp[val] = JSON.stringify(backup);
        }
      }
    },
    // 获取备份数据
    getEditBackupByKey(key){
      return this.editBackUp[key] ? JSON.parse(this.editBackUp[key]) : undefined;
    },
    // 检查是否有编辑状态
    hasEdit(hidePop){
      this.editList.length && !hidePop && this.$confIssue(this.t("validate.currentHasEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      })
      return this.editList.length ? true: false;
    },
    checkIsEmpty(rule, value, callback) {
      let val = '';
      if(typeof value === 'number') {
        val = String(value).trim()
      }else if(typeof value === "string"){
        val = value.trim()
      }else {
        val = value
      }
      if (!val) {
        callback(new Error(this.t("validate.requiredField")));
      } else {
        callback();
      }
    },
    checkRadioNull(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(this.t("validate.requiredField")));
      } else {
        callback();
      }
    },
    checkNumberSelect(rule, value, callback) {
      if (value === "" || value === null) {
        callback(new Error(this.t("validate.requiredField")));
      } else {
        callback();
      }
    },
    // id与字符串双类型字段的判断与转化处理
    getIdTypeValue(value) {
      if (!value) {
        return '';
      }
      if ((typeof value === 'string' && !value.trim()) || isNaN(Number(value))) {
        return value;
      } else {
        return value * 1;
      }
    },
    
    /**
     * 根据id获取名字--适用下拉选项
     * @param {Array} options 要查询的数组
     * @param {*} id 查询的参照值
     * @param {*} key 查询的字段名
     * @param {*} idKey 查询的参照字段名
     * @returns 查询的名字
     */
    getNameFromId(options, id, key, idKey) {
      if (options && options.length > 0) {
        const index = options.findIndex((item) => item[idKey] === id);
        if (index !== -1) {
          return options[index][key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    waitMoment(s) {
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, s);
      });
    },
    checkUrlRule(rule, value, callback){
      if(value.length == 0) {
        callback(); return 
      }
      if(!/^https:\/\/|^http:\/\//.test(value)){
        callback(new Error(this.t('validate.pleaseCheckURL')))
      }
      callback();
    },
    checkChinese(rule, value, callback){
      if(value && value.toString().match(/[\u4e00-\u9fa5]/)) {
        return callback(new Error(this.t('validate.onlyEnlishAddres')));
      }
      return callback();
    },
    addCompletedStatus() {
      if(this.fkPlatformType==='get_issue'){
        this.$root.toolMutations.addCompleted(this.$root.toolState, this.compName);
        this.$root.toolMutations.subUncompleted(this.$root.toolState, this.compName);
      }
    },
    addUncompletedStatus(name) {
      if(this.fkPlatformType==='get_issue'){
        const compName = name || this.compName
        this.$root.toolMutations.subCompleted(this.$root.toolState, compName);
        this.$root.toolMutations.addUncompleted(this.$root.toolState, compName);
      }
    }
  },
};
