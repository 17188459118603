<template>
  <div class="step-circle-wrap">
    <div class="dots">
      <span class="dot" :style="{'background-color': proccess > 33 ? color: ''}"></span>
      <span class="dot" :style="{'background-color': proccess > 66 ? color: ''}"></span>
      <span class="dot" :style="{'background-color': proccess == 100 ? color: ''}"></span>
    </div>
    <div class="step-circle">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="circle">
        <defs>
          <linearGradient :id="id+'bgm'" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" :style="`stop-color:${subColor};stop-opacity:1`" />
            <stop offset="100%" :style="`stop-color:${color};stop-opacity:1`" />
          </linearGradient>
          <linearGradient id="circleBorderBtm" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:#595959;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#C2C2C2;stop-opacity:1" />
          </linearGradient>
        </defs>
        <path d="m5, 55 a50 50 0 1 1 100 0a50 50 0 1 1 -100 0" 
          stroke="url(#circleBorderBtm)" 
          stroke-width="10" 
          class="bgm-path"
          fill="transparent"></path>
        <path d="m5, 55 a50 50 0 1 1 100 0a50 50 0 1 1 -100 0" 
          :stroke="`url(#${id}bgm)`" 
          stroke-width="10" 
          class="fgm-path"
          :style="{
            'stroke-dashoffset': dashoffset
          }"
          fill="transparent"></path>
      </svg>
      <div class="label-wrap" :style="{color: proccess == 100 ? color: ''}">{{label || ''}}</div>
    </div>
    <div class="compelete" :style="{color: proccess == 100 ? color: ''}">{{t('mso.compelete')+' '+proccess}} %</div>
  </div>
</template>

<script>
import i18n from "@/locale/index";
export default {
  name: 'stepCircle',
  props:{
    label: String,
    proccess: Number, // 0 - 100 完成度
    color: String,
    subColor: String,
    id: String,
  },
  mixins: [i18n],
  data(){
    return {
      dashoffset: 315,
    }
  },
  watch: {
    proccess:{
      handler(newVal){
        setTimeout(()=>{
          this.dashoffset = 315 - newVal * 3.15
        })
      },
      immediate: true
    }
  },
}
</script>
<style scoped lang="scss">
.step-circle-wrap{
  width: 158px;
  overflow: hidden;
  padding-right: 10px;
  .dots{
    float: right;
    display: flex;
    align-items: center;
    margin-left: 10px;
    transform: translateY(60px);
    .dot{
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #8A8A8A;
      &:nth-child(2){
        margin: 0 6px;
      }
    }
  }
  .compelete{
    margin-top: 4px;
    font-size: 14px;
    color: #333;
    width: 118px;
    text-align: center;
    .lbl{
      display: inline-block;
    }
  }
  &:last-child{
    width: 118px;
    .dots{
      display: none;
    }
  }
}
.step-circle{
  width: 118px;
  height: 118px;
  border-radius: 50%;
  position: relative;
  padding: 2px;
  .circle{
    width: 110px;
    height: 110px;
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: transparent;
    filter: drop-shadow(3px 2px 3px rgba(0,0,0,.06));
    .fgm-path{
      stroke-dashoffset: 315;
      stroke-dasharray: 315, 315;
      transition: stroke-dashoffset .4s ease-in;
    }
  }
  .label-wrap{
    width: 100%;
    height: 100%;
    padding: 10px 18px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 16px;
    color: #666;
    text-align: center;
  }
}
</style>