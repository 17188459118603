<template>
  <div class="title-button-comp">
    <h2 class="main-title" >
      {{showMainTitle? mainTitle : ''}}
    </h2>
    <div>
      <div style="text-align:right;padding-right: 20px"><slot></slot></div>
      <div class="t-l-w" v-show="loading">
        <loading-icon :loading="loading"></loading-icon>
        <span v-show="loading">loading</span>
      </div>
      <el-button
        v-show="addShow && !loading"
        type="text"
        :class="['subtitle-add-btn', disabled && 'disabled']"
        icon="el-icon-plus"
        @click.stop="handleClick"
      >
        {{ buttonName }}
      </el-button>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import LoadingIcon from "../../svg-icon/LoadingIcon.vue";
export default {
  name: "TitleButtonComp",
  mixins: [i18n],
  components: {
    LoadingIcon,
  },
  props: {
    mainTitle: {
      type: String,
      require: true,
    },
    showMainTitle:{
      type: Boolean,
      require: false,
      default: false,
    },
    buttonName: {
      type: String,
      default: "",
    },
    addShow: {
      type: Boolean,
      default: false,
      require: false
    },
    updateShow: {
      type: Boolean,
      default: false,
      require: false,
    },
    deleteShow: {
      type: Boolean,
      default: false,
      require: false,
    },
    loading: {
      type: Boolean,
      default: false,
      require: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      require: false,
    }
  },
  methods: {
    handleClick() {
      this.$emit("handleAdd");
    },
  },
};
</script>
