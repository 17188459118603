export default {
  stuManagement: {
    // 学生注册
    studentReg: "注册申请 Student Registration",
    nameZh: "中文名/全名 Chinese Name or Full Name",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    gender: "性别 Gender",
    female: "女 Female",
    male: "男 Male",
    dateBirth: "出生日期 Date of Birth",
    phoneNumber: "联系电话 Contact Number",
    phoneRegion: "区号 Area Code",
    email: "电子邮箱 Email Address",
    emailPassword: "电子邮件密码（选填） Email Password（optional）",
    country: "居住国家或地区 Country or Region of residence",
    state: "省/州/县 Province/State/County",
    city: "市/区 City/District",
    region: "区 District",
    postcode: "邮政编码 Postal Code",
    address: "联系地址 Contact Address",
    alternativeAddr: "备选联系地址 Alternative Contact Address",
    fillApply: "保存并填写入学申请 Save and fill out the admission application",
    moreEmailTips:' (多个邮箱请用(;+空格)隔开)',
    // 个人信息
    personalInfo: "个人信息 Personal Information",
    title: "称谓 Title",
    birthday: "生日 Birthday",
    surname: "姓 Surname/Family Name",
    givenName: "名 Given/First Name",
    nationality: "国籍/地区 Country / Region",
    ancestry: "名族血统 Ethnic origin",
    firstLanguage: "第一语言 First Language",
    birthCountry: "出生国家或地区 Country or Region of Birth",
    birthCity: "出生城市 Birth City",
    isBornLive: "是否自出生便在该国家或地区居住 Have you lived in this country or region since birth",
    liveCountry: "居住国家或地区 Country or Region of residence",
    moveToCountryTime: "在什么时候移居至该国家或地区？ when did you move to your country or region of permanent residence?",
    getPermanentResidentTime: "在什么时候取得该国家或地区的居留权？ When did you get permanent residence of this country or region?",
    shareLink:'共享链接',
    // 护照信息
    passportInfo: "护照信息 Passport Information",
    isHasPassport: "是否有护照? Do you have a passport?",
    issueCountry: "签发国家或地区 Countrty or Region of Issue",
    passportIssueDate: "签发日期 Date of Issued",
    passportIssueExpried: "有效期至 Date of Expired",
    passportNum: "护照号码 Passport No.",
    // 联系负责人
    contactPerson: "联系负责人 Contact Person",
    contactNotice: "(建议提供顾问老师手机号码，以便收取系统推送学生个案进展) ",
    atLeastOne: "（必须填至少一个 At least one）",
    // 背景资料
    backgroundInfo: "背景资料 Further Information",
    destCtry: "目的国家或地区 Destination Country or Region",
    deleteDestCtry: "删除目的国家或地区 Delete Destination Country or Region",
    isStudiesInCtry: "是否有在申请目的国家或地区学习过？ Have you ever studied in the country or region of application?",
    updatedVisa: "请提供最新签证 Please provide the latest visa",
    visaIssueDate: "签证签发日期 Visa Issuance Date",
    visaType: "签证类型 Visa Type",
    visaExpiryDate: "签证有效期至 Visa Expiry Date",
    lengthStudy: "学期时期(整月数) Semester period (number of whole months)",
    addBackgroundInfo: "新增背景资料 Add background information",
    isHasReligion: "有特别的宗教信仰吗? Are there any special religious beliefs?",
    explainReligion: "请说明宗教信仰 Please explain religious belief",
    isRefusedRegion: "是否被任何国家或地区拒签过 Whether the visa has been rejected by any country or region",
    fullDetails: "请提供详细情况 Please provide details",
    specialNeed: "残疾或者特殊需求 Disability or special needs",
    listSeparately: "请分别列举出来 Please list them separately",
    isEnglishFirLang: "英语是第一语言吗？ Is English the first language?",
    isStudiedInstruction: "修读过英语为授课语言的高中或以上课程吗？ Have you taken a high school or above course with English as the language of instruction?",
    expectedPayWay: "谁将支付费用？ Who will pay the fee?",
    expectedPayWayTips: "(本人或家长资助的都选自费) (I or my parents funded are all selected fees)",
    selfFund: "自费 Self-funded",
    otherFund: "其他 Other",
    whatIsDestinationCountry: "你的目的国家/地区是? What is your destination country?",
    // 院校申请
    applicationSch: "院校申请 University Application",
    addApplicationSch: "新增课程 New Course",
    course: "课程 Course",
    deleteCourse: "删除课程 Delete Course",
    school: "学校 Institution",
    courseLevel: "课程等级 Course Level",
    courseCountry: "国家或地区 Country or Region",
    openingTime: "开学日期 Starting Date",
    addCourseAgain: "加申课程 New Course",
    faculty: "院校 Faculty",
    zone: '校区 Campus',
    // 教育背景
    fkAreaRegion: '区域 Region',
    educationType: "教育背景类型 Type of Education Background",
    educationTypeTips: '请先选择教育背景类型 Please Select Type of Education Background at first!',
    institution: "学校名 Institution",
    institutionAnalysis: "提示：智能识别的学校 Intelligent identification of the school",
    copy: '复制 Copy',
    institutionAddress: "学校详细地址 School address",
    otherInstitution: "其他学校名 Other Institution",
    instructedInEng: "以英语为教学考试语言？ Using English as the language of teaching and testing?",
    durationSch: "在校时间 Duration",
    isComplated: "是否完成学习 Complete the study or not?",
    isWillComplated: "将来是否会完成 Will it be completed in future?",
    highSchool: "中学 High School",
    higherEducation: '高等教育 Higher Education',
    university: "大学 University",
    delEduBackground: "删除教育背景 Delete Education Background",
    eduBackground: "教育背景 Educational Background ",
    eduBackgroundFile: "教育背景资料上传 Upload educational background information",
    education: '教育背景',
    institutionNum: "院校序号（最小号码为最近者） Institution Number(The smaller number, the more recent)",
    addEduBackground: "添加教育背景 Add educational background",
    diploma: "专科 Diploma",
    associatesDegree: "副学士 Associate Degree",
    bachelor: "本科 Bachelor",
    master: "硕士 Master",
    preMaster: "硕士预科 Pre Master",
    phD: "博士 PhD",
    foundation: "Foundation",
    class: "Class",
    scoreTypeFour: "4分满分 out of 4 points",
    scoreTypeFourToTwo: "两位小数点 上限4.2 out of 4.2, retain two decimal places.",
    scoreTypeFourToFive: "两位小数点 上限4.5 out of 4.5, retain two decimal places.",
    scoreTypeFive: "5分满分 out of 5 points",
    scoreTypeSeven: "7分满分 out of 7 points",
    scoreTypeNine: "两位小数点, 上限9 out of 9, retain two decimal places.",
    percentage: "Percentage Percentage",
    grading: "Grading Grading",
    classFirst: "1st",
    classTwoToOne: "2:1",
    classTwoToTwo: "2:2",
    classThird: "Third Class",
    classOrdnary: "Ordnary",
    twoDecimals: "可保留两位小数 Can keep two decimal places",
    eduBackgroundNumMsg: '教育背景序号重复 Education Background Number Recurrences: {num}',
    edubgmNumCount: '最多只能保存三个学历 You can only save up to three degrees',
    inputSubjectScores: '录入分科成绩Input subject scores',
    checkSubjectScores: '查看分科成绩check subject scores',
    // 语言成绩
    examination: "语言考试成绩 Language examination results",
    language: '语言考试成绩',
    hasExamination: "是否有留学标化考试(如: 雅思/托福)？ Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    examType: "考试类型 Exam Type",
    toefl: "TOEFL Ibt 托福考试 TOEFL Ibt TOEFL Test",
    ielts: "IELTS 雅思 IELTS",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    pte: "PTE",
    builtInTest: "院校内部测试 School Internal Test",
    listening: "听 Listening",
    reading: "读 Reading",
    writing: "写 Writing",
    speaking: "说 Speaking",
    overall: "总分 Total Score",
    registrationNo: "查询账号 Check account number",
    testCenterNum: "测试中心号码 Test Center Number",
    testDate: "考试日期 Test Date",
    testTakerId: "考生号码 Candidate Number",
    awardingCtryRegion: "认证国家或地区 Certification Country or Region",
    ifRetake: "是否想重考 Do you want to retake the exam",
    isForUKVI: "是否 UKVI IELTS? It is UKVI IELTS?",
    examUserName: "考试用户名 Exam User Name",
    examUserPwd: "登录密码 Login Password",
    score: "分数 Score",
    deleteExamType: "删除考试类型 Delete Exam Type",
    major: "主修 Major",
    qualification: "学历 Education",
    gradeCategory: "成绩类别 Grade Category",
    scoreValue: "总分/平均分/百分制Total score/Average score/Percentage system",
    inEngAndExam: "以英语为教学和考试语言 English as the language of teaching and examination",
    inEngNotExam: "以英语为教学语言但不包括考试 English is the language of instruction but not including exams",
    inschoolTime: "在校 At school",
    applicationMsg: "请先将前面的内容选择完整 Please complete the previous content selection first",
    // 专业资格
    profQual: "专业资格 Professional Qualification",
    qualifications: '专业资格',
    addProfQual: "添加专业资格 Add professional qualification",
    qualName: "资格名称 Qualification Name",
    qualGroup: "认证机构 Certification Agency",
    fkStudentAppCtry: "认证国家或地区 Certified Country or Region",
    qualGrade: "评分级别(如适用) Grading level (if applicable)",
    qualStartDate: "资格课程开始日期 Qualification Course Start Date",
    qualGetDate: "取得资格日期 Qualification Date",
    qualGetStatus: "专业资格状态 Professional Qualification Status",
    inProgress: "进行中 In Progress",
    obtained: "已获得 Obtained",
    deleteProfQual: "删除专业资格 Delete Professional Qualification",
    // 推荐人
    referee: "推荐人 Refereer",
    recommend: '推荐人',
    addReferee: "添加推荐人 Add referrer",
    instituCompany: "院校/公司 Institution/Company",
    position: "职位 Position",
    contactAddress1: "联系地址一 Contact Address One",
    contactAddress2: "联系地址二 Contact Address Two",
    deleteReferee: "删除推荐人 Delete Referrer",
    mainReferee: '(首选推荐人 Main Refereer)',
    mainRefereeText: '置为首选推荐人',
    refereeNum: '推荐人序号',
    refereeNumMsg: '推荐人序号重复 Referee Number Recurrences: {num}',
    refereeCountry: "院校/公司所在国家或地区 Country or Region of Institution/Company",
    ofLetter: '的推荐信',
    recommendationLetter: '推荐信 Letter of recommendation',
    inputLetterName: '请输入推荐信名称 Please input the name of recommendation',
    uploadLetter: "上传推荐信 Upload recommendation",
    letterName: "推荐信名称 recommendation",
    letterMatchCourse: '推荐信适用课程 Recommended courses',
    specifyCourses: '指定课程',
    updateLetterName: '修改推荐信名',
    specifyCoursesTitle: '指定推荐信使用课程',
    specifyCoursesTip: '*如推荐信适用于所有课程，则无需设置，默认适用所有',
    saveSetting: '保存设置',
    beCurrent: '通用',
    // 工作经验
    experience: "工作经验 Work Experience",
    workExperience: '工作经验',
    addWorkExperience: "添加工作经验 Add work experience",
    workTime: "在职",
    workStartTime: "在职开始日期 Start date of Employment",
    workEndTime: "在职结束日期 End date of Employment",
    duties: "职责 Duties",
    countryOranisation: "单位所在国家或地区 Country or Region of Organisationd",
    stateOranisation: "单位所在省/州/县  Province/State/County of Organisationd/Region",
    cityOranisation: "单位所在城市/區 City/District of Organisation",
    companyName: "单位名称 Name of Organisation",
    isOnDuty: "目前是否在职 Are you currently employed in this position?",
    workMode: "全职或业余 Full-time or Part-Time",
    fullTime: "全职 Full Time",
    partTime: "业余 Part-Time",
    recentWork: "最近的工作 Most recent work",
    workExperienceNum: '工作经验(最小号码为最近的工作) Work Experience Number(The smaller number, the more recent)',
    workExperienceNumMsg: '工作经验序号重复 Work Experience Number Recurrences: {num}',
    // 学生资料上传
    clickUpload: "上传",
    clickUploadAgain: '重新上传',
    stuInfoUpload: "学生资料上传 Student Information Upload",
    fileData: '学生资料上传',
    uploadTip: "2M以下 | 格式: PDF",
    uploadLimit: "上传文件只能是PDF格式  The uploaded file can only be in PDF format",
    uploadSize: "上传文件不能超过{limit}M  The uploaded file cannot exceed {limit}M",
    uploadDesc: "上传说明 Upload description:",
    file: "文件 File",
    downloadAllFile: "打包并下载全部文件 Package and download all files",
    downloadAllFileNav:"打包并下载全部文件",
    fileIdentification: "上传资料识别信息",
    fileIdentificationForIELTS: "上传识别雅思成绩",
    fileIdentificationForHKDSE: "上传识别HKDSE",
    fileIdentificationForReferee: "上传识别推荐信",
    studentInformation: "学生资料 Student information",
    plsUploadFile: "请上传文件 Please upload the file",
    download: "下载",
    downloadTemplate: "下载模板",
    // 后续安排
    isNeedAccommodation: "你是否需要安排住宿？ Do you need to arrange accommodation?",
    isNeedPickUp: "你是否需要安排接机？ Do you need to arrange pick-up?",
    accommodationType: "如需要学校安排住宿请提供你的选择 If you need the school to arrange accommodation, please provide your choice",
    homestay: "寄宿家庭 Homestay",
    universityAccmmodation: "学校宿舍 University Accommodation",
    offCampusAccmmodation: "校外宿舍 Off Campus Accommodation",
    FollowUpArrangements: "后续安排 Follow-up Arrangements",
    arrangements: '后续安排',
    isAccommodationSmoke: "你是否抽烟 Do you smoke",
    isAccommodationPet: "你是否能接受有宠物的家庭？ Do you want to live in a home with pets ?",
    isAccommodationChildren: "你是否能接受有小孩的家庭？ Do you want to live in a home with children ?",
    isAccommodationFood: "你对食物是否有特殊要求 例如: 素食 Do you need any special food(eg vegetarian) ?",
    accommodationFoodNote: "请详细说明 Details",
    isAccommodationOther: "你是否有其他要求？ Do you have other requirements ?",
    accommodationOtherNote: "其他要求说明 Other requirements",
    // 父母的联系方式
    fatherLastName: "父亲的姓 Father's Last Name",
    fatherFirstName: "父亲的名 Father's First Name",
    fatherEmail: "父亲的邮箱地址 Father's Email Address",
    fatherPhoneNumber: "父亲的联繫电话 Father's Contact Number",
    monterLastName: "母亲的姓 Mother's Last Name",
    monterFirstName: "母亲的名 Mother's First Name",
    monterEmail: "母亲的邮箱地址 Mother's email address",
    monterPhoneNumber: "母亲的联繫电话 Mother's Contact Number",
    parentContactWay: "父母联系方式 Parent Contact Way",
    relativesContact: '父母联系方式',
    // suitAge18: "适用于申请集团的课程或是18岁以下的学生。\nApplicable to applying for group courses or students under the age of 18.",
    jumpOver: "如不适用，请直接进入下一步。\nIf not applicable, please go directly to the next step.",
    // 导航
    commitApp: "总申请表单提交",
    commit: "提交",
    navPersonalInfo: "个人信息",
    navBackgroundInfo: "背景资料",
    navApplicationSch: "院校申请",
    // 立即申请
    applyCofirm: "申请确认 Apply for Confirmation",
    applyStatement: "信息表声明如下: \nThe information table is declared as follows:",
    applyTip: "请勾选以下的方框以确定您已了解完整的申请流程\nPlease check the box below to make sure you understand the complete application process",
    applyBox1: "我已在系统上传了所有用于申请的支持文件\nI have uploaded all supporting documents for application in the system",
    applyBox2: "我提供的所有学生申请文件都是真实的\nAll the student application documents I provided are true",
    applyBox3: "我同意{platform}平台使用我所填写的申请表格资料，及相关申请内容文件（包括但不限于个人陈述、成绩单、毕业证书、学位证书、英语考试成绩单、推荐信、实习证明、在读证明、签证、护照等资料），代表我进行相关入学申请。\nI agree that the {platform} platform can use the application form materials and relevant application documents (including but not limit to Personal statement,  transcript,  Graduation certificate,  Degree certificate,   English test certificate,  Reference letter, Internship certificate, Study certificate, VISA, Passport etc) to apply for admission on my behalf. ",
    applyName: "申请者姓名 Applicant's name:",
    toBack: "返回",
    hadConfirm: "确认",
    nextStep: '下一步 Next',
    pleaseSelect: '请选择 Please select',
    exportForm: '导出为Word'
  },
  // 父母的联系方式
  parentContact: {
    fatherLastName: "父亲的姓 Father's Last Name",
    fatherFirstName: "父亲的名 Father's First Name",
    fatherEmail: "父亲的邮箱地址 Father's Email Address",
    fatherPhoneNumber: "父亲的联系电话 Father's Contact Number",
    monterLastName: "母亲的姓 Mother's Last Name",
    monterFirstName: "母亲的名 Mother's First Name",
    monterEmail: "母亲的邮箱地址 Mother's email address",
    monterPhoneNumber: "母亲的联系电话 Mother's Contact Number",
    parentContactWay: "父母联系方式 Parent Contact Way",
    suitAge18: "适用于申请集团的课程或是18岁以下的学生，或部分学校网申需要紧急联系人信息的，请提供父母其中一方或双方信息。\nApplicable to applying for group courses or students under the age of 18, or for some schools applying online who require emergency contact information, please provide information on one or both parents.",
    jumpOver: "如不适用，请直接进入下一步。If not applicable, please go directly to the next step.",
    fkStudentAppCountryIdParent: "国家或地区 Country or Region",
    fkAreaStateIdParent: "省/州/县 Province/State/County",
    fkAreaCityIdParent: "市/区 City / District",
    parentContactAddress1: "联系地址 Contact Address",
    parentContactAddress2: "备选联系地址 Alternative Contact Address",
    parentContactPostcode: "邮政编码 Postal Code",
  },
  // 个人信息
  personInfo: {
    personalInfo: "个人信息 Personal Information",
    gender: "性别 Gender",
    gender0: "女 Female",
    gender1: "男 Male",
    title: "称谓 Title",
    title1: "Mr. 先生",
    title2: "Ms 女士",
    title3: "Miss 小姐",
    title4: "Mrs 夫人",
    birthday: "生日 Birthday",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    fkStudentAppCountryId: "学生国籍/地区 Student Country/Region",
    fkStudentAppAncestryId: "学生种族 Student Race",
    fkStudentAppMotherLanguageId: "学生母语 Student's native language",
    fkStudentAppCountryIdBirth: "出生国家或地区 Country or Region of Birth",
    cityBirth: "出生城市 Birth City",
    isLivedSinceBirth: "是否自出生便在这个国家或地区居住？Have you lived in this country or region since birth?",
    isLivedSinceBirth0: "否 No",
    isLivedSinceBirth1: "是 Yes",
    fkStudentAppCountryIdLive: "目前所在国家/地区 Current country/region",
    stuPhoneNumber: '学生联系电话 Student Contact Number',

    migrationTime: "移居时间 Migration Time",
    stayingRightTime: "取居留权时间 Take the right of abode time",
    isHavePassport: "是否有护照 Do you have a passport",
    isHavePassport0: "否 No",
    isHavePassport1: "是 Yes",
    fkStudentAppCountryIdPassport: "签发国家或地区 Issuing Country or Region",
    passportIssueDate: "签发日期 Issue Date",
    passportIssueExpried: "有效期至 Valid until",
    passportNumber: "护照号码 Passport Number",
    followUpPhoneCode: "区号 Area Code",
    followUpPhoneNumber1: "跟进联系人电话号码1 Follow up the contact's phone number 1",
    followUpPhoneCode1: "区号1 Area Code 1",
    followUpEmail1: "跟进人电邮1 Follow Up Email 1",
    followUpPhoneNumber2: "跟进联系人电话号码2 Follow up the contact's phone number 2",
    followUpPhoneCode2: "区号2 Area Code 2",
    followUpEmail2: "跟进人电邮2 Follow Up Email 2",
    followUpPhoneNumber3: "跟进联系人电话号码3 Follow up the contact's phone number 3",
    followUpPhoneCode3: "区号3 Area Code 3",
    followUpEmail3: "跟进人电邮3 Follow Up Email 3",
    passportInfo: "护照信息 Passport Information",
    contactPerson: "联系负责人 Contact Person in Charge",
    contactNotice: "(建议提供顾问老师手机号码，以便收取系统推送学生个案进展)",
    atLeastOne: "(至少填写一个) (Fill in at least one)",
  },
  registerInfo: {
    nameZh: "中文名/全名 Chinese Name or Full Name",
    lastName: "姓(拼音/英文) Surname (Pinyin/English)",
    firstName: "名(拼音/英文) First name (Pinyin/English)",
    gender: "性别 Gender",
    gender0: "女 Female",
    gender1: "男 Male",
    birthday: "出生日期 Date of Birth",
    phoneNumber: "联系电话 Telephone Number for Correspondence",
    phoneRegion: "区号 Area Code",
    email: "电子邮箱 Email",
    emailPassword: "电子邮件密码 Email Password",
    fkStudentAppCountryIdFrom: "居住国家或地区 Country or Region of residence",
    fkAreaStateIdFrom: "省/州/县 Province/State/County",
    fkAreaCityIdFrom: "市/区 City/District",
    fkAreaCotuDovosopmIdFrom: "区 Area",
    contactPostcode: "邮政编码 Postal Code",
    contactAddress1: "联系地址 Contact Address",
    contactAddress2: "备选联系地址 Alternative contact address",
  },
  backgroundInfo: {
    backgroundInfo: "背景资料 Further Information",
    updatedVisa: "请提供最新签证 Please provide the latest visa",
    deleteDestCtry: "删除目的国家或地区 Delete Destination Country or Region",
    whoPayTips: "本人或家长支付的都选择自费",
    // 多组
    fkAreaCountryId: "目的国家或地区 Destination Country or Region",
    isStudyInThisCountry: "你之前是否在申请目的国学习过？Have you ever studied in the destination country?",
    isStudyInThisCountry0: "是 Yes",
    isStudyInThisCountry1: "否 No",
    visaIssueDate: "签证签发日期 Visa Issuance Date",
    visaType: "签证类型 Visa Type",
    visaExpiryDate: "签证过期日期 Visa Expiry Date",
    studyLength: "学习长度(整月数) Length of study (in whole months)",
    // 固定
    isHaveReligion: "你有特别的宗教信仰吗？Willing to explain your religion?",
    isHaveReligion0: "否 No",
    isHaveReligion1: "是 Yes",
    religionNote: "宗教信仰说明 Religious Belief Description",
    isVisaRefused: "你是否被任何国家或地区拒签过？Have you ever been refused a visa application to any country or Region?",
    isVisaRefused0: "否 No",
    isVisaRefused1: "是 Yes",
    visaRefusedNote: "拒签说明 Visa Refused Note",
    isDisability: "残疾或者特殊需求 Disability/Special Needs",
    isDisability0: "否 No",
    isDisability1: "是 Yes",
    disabilityNote: "残疾需求说明 Description of Disability Needs",
    isEnglishMotherLanguage: "英语是你的第一语言吗？Is English your first language?",
    isEnglishMotherLanguage0: "否 No",
    isEnglishMotherLanguage1: "是 Yes",
    isStudyInEnglish: "你修读过英语为授课语言的高中或以上课程吗？Have you studied at secondary or tertiary levels with English as the language of instruction?",
    isStudyInEnglish0: "否 No",
    isStudyInEnglish1: "是 Yes",
    whoPay: "谁将支付你的费用？Who is expected to pay your fees?",
    whoPay0: "自费 Self-fund",
    whoPay1: "其他 Other",
    payNote: "支付说明 Payment Instructions",
  },
  languageInfo: {
    examination: "语言考试成绩 Language examination results",
    hasExamination: "是否有留学标化考试(如: 雅思/托福)？ Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    deleteExamType: "删除考试类型 Delete Exam Type",
    addExamType: "新增考试类型 Add Exam Type",
    toefl: "TOEFL Ibt 托福考试",
    ielts: "IELTS 雅思",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    duolingo: "Duolingo",
    pte: "PTE",
    builtInTest: "院校内部测试 School Internal Test",
    examType: "考试类型 Exam Type",
    examListen: "听 Listening",
    examRead: "读 Reading",
    examSpeak: "说 Speaking",
    examWrite: "写 Writing",
    examTotal: "总分 Overall",
    examScore: '分数 Score',
    registrationNo: '查询账号 Registration No.',
    examinationCheckAccount: "考试查询账户 Examination Check Account",
    examinationCheckPassword: "考试查询密码 Examination Check Password",
    examinationDate: "考试日期 Test Date",
    examinationLogin: "考试用户名 Login",
    examinationPin: "登录密码 Pin",
    examinationNumber: "考生号码 Candidate id number",
    testTakerId: '考生号码 Test Taker ID',
    fkStudentAppCountryId: "认证国家或地区 Awarding Country or Region",
    testCenterNumber: "测试中心号码 Test Center Number",
    ukviNumber: "UKVI号码 UKVI Number",
    isUkviIelts: "是否UKVI IELTS? It is for UKVI IELTS?",
    isUkviIelts0: "否 No",
    isUkviIelts1: "是 Yes",
    isReexamination: "是否想重考 If Retake?",
    isReexamination0: "否 No",
    isReexamination1: "是 Yes",
    reexaminationDate: "重考日期 Reexamination Date",
  },
  qualificationInfo: {
    qualificationInfo: "专业资格 Professional Qualification",
    addQualf: "添加专业资格 Add Professional Qualification",
    deleteQualf: "删除专业资格 Delete Professional Qualification",
    qualificationName: "资格名称 Qualification Name",
    qualificationGroup: "认证机构 Certification Agency",
    fkStudentAppCountryId: "资格认证的国家或地区 Country or Region of Qualification Certification",
    qualificationGrade: "评分级别(如适用) Grading level (if applicable)",
    qualificationStartDate: "资格课程开始日期 Qualification Course Start Date",
    qualificationGetDate: "取得资格日期/预计取得资格日期 Qualification Date/Estimated Qualification Date",
    qualificationGetStatus: "专业资格状态 Professional Qualification Status",
    qualificationGetStatus0: "进行中 In Progress",
    qualificationGetStatus1: "已获得 Acquired",
  },
  common: {
    saveTemporal: "信息待完善，暂存",
    save: "保存",
    cancel: "取消",
    search: "搜索",
    reset: "重置",
    opera: "操作 Operation",
    download: "文件下载",
    yes: "是 Yes",
    no: "否 No",
    has: "有 Yes",
    hasNot: "没有 No",
    confirm: "确定",
    want: "想 Yes",
    wantN: "不想 No",
    isNot: "不是",
    startDate: "开始日期(yyyy-mm-dd)",
    endDate: "结束日期(yyyy-mm-dd)",
    tip: "提示",
    del: "删除",
    undecided: "未决定",
    saveSucc: "保存成功",
    saveFail: "保存失败",
    submitFail: "提交失败",
    until: "至",
    update: "编辑",
    deleteSucc: "删除成功",
    deleteFail: "删除失败",
    updateSucc: "编辑成功",
    updateFail: "编辑失败",
    delete: "删除",
    systemErr: "系统错误 System error",
    pleaseSave: '请先保存{sth}, Please save first',
    filename: '文件名称',
    filetype: '文件类型',
    noUpload: '未上传',
    fileUpload: '文件上传',
    autoSaveStart: '系统正在为您自动保存表单',
    autoSaveOver: '开始为您重新计时保存',
    noSelectTips: '选择不到可进行手写输入 No matching selection, please input the name',
    // 智能识别
    intelligentIdentificationStudentInfo: '智能识别学生资料',
    intelligentIdentificationTips: '通过OCR & NLP识别技术，提取图像、文档中的关键信息，简化资料录入工作',
    uploadPassportNational: '上传其他地区护照图片',
    uploadPassport: '上传中国大陆护照图片',
    uploadBackground: '上传背景资料',
    continuetofill: '继续填写',
    stuName: '姓名（中文）',
    stuNameEn: '姓名（英文）',
    stuGender: '性别',
    stuNation: '民族',
    stuBirth: '出生日期',
    stuAddr: '联系地址',
    stuIdcard: '身份证',
    stuBirthPlace: '出生地',
    stuPassport: '护照号码',
    stuEduExp: '教育经历',
    stuWorkExp: '工作经历',
    notUpload: '未上传',
    branchSubjectType: '请选择分科类型',
    branchSubjectValue: '请选择或输入分数',
    followRemark: '跟进备注 Follow up remarks',
    saveAndSubmit: '保存并提交申请',
    saveAndSubmitTips: '当前已修改了国家/地区，请点击保存或暂存按钮',
    submitTime: '提交时间',
    studentList:'学生列表',
    checkRemark:'查看修改备注',
  },
  validate: {
    currentHasEdit: '当前有正在编辑的表单，请完成新增或编辑，点击保存后，再进行其他操作。 There is a form being edited. Please complete adding or editing, click Save, and then perform other operations.',
    noFormDataTip: '请完善或保存资料 Please complete or save the information',
    cancelEdit: '请确定取消编辑吗？ Are you sure cancel the edit status?',
    requiredField: "必填项 Required Field",
    emailValid: "请输入正确的邮箱地址 Please enter the correct email address",
    birthdayValid: "请检查出生日期 Please check the date of birth",
    issueDateValid: "请检查日期, 签发日期{issueDate}不能在有效期{expriDate}后 Please check the date, the issue date {issueDate} cannot be after the expiration date {expriDate}",
    limit4: "最多五个 Up to five",
    checkDestCtryNum: "至少选择1个目的国家或地区  Select at least 1 destination country or region",
    reDestCtryName: "您已经添加了这个国家或地区  You have added this country or region",
    checkDate: "请检查{name}，开始时间不能在结束时间后 Please check {name}, the start time cannot be after the end time",
    delMsg: "您确定要删除该{msg}吗？ Are you sure you want to delete this {msg}?",
    delMsgapp: "您确定要删除该院校申请吗？Are you sure you want to delete this Student Registration?",
    delMsgBgInfo: "您确定要删除该目的国家或地区吗？Are you sure you want to delete this Destination Country or Region?",
    delMsgEduInfo: "您确定要删除该背景资料吗？Are you sure you want to delete this Educational Background?",
    delMsgFile: "您确定要删除该文件吗？Are you sure you want to delete this File?",
    delMsgLang: "您确定要删除该语言成绩吗？Are you sure you want to delete this Examination results?",
    delMsgQualInfo: "您确定要删除该专业资格吗？Are you sure you want to delete this Professional Qualification?",
    delMsgReferInfo: "您确定要删除该推荐人吗？Are you sure you want to delete this Refereer?",
    delMsgReferLetterInfo: "您确定要删除该推荐信吗？Are you sure you want to delete this recommendation letter?",
    delMsgWorkInfo: "您确定要删除该工作经验吗？Are you sure you want to delete this Work Experience?",
    checkIeltsRegstNo: "查询账号的长度为16到19位 The length of the query account number is 16 to 19",
    checkIeltsTestCenterNo: "测试中心号码的长度为5到7位 The length of the test center number is 5 to 7 digits",
    checkIeltsCandNo: "考生号码的长度为5到20位 The length of the candidate number is 5 to 20 digits",
    checkToeflRegstNo: "查询账号的长度为16到19位 The length of the query account is 16 to 19 digits",
    checkToeflTestCenterNo: "测试中心号码的长度为9到12位 The length of the test center number is 9 to 12 digits",
    checkPteRegstNo: "查询账号的长度为9到13位 The length of the query account number is 9 to 13",
    checkPteTestCenterNo: "测试中心号码的长度为5到9位 The length of the test center number is 5 to 9 digits",
    checkPteTakerID: "考生号码的长度为9到12位 The length of the candidate number is 9 to 12 digits",
    qualDateValid:
      "请检查日期, 资格课程开始日期{startDate}不能在取得资格日期{getDate}后 Please check the date, the start date of the qualification course {startDate} cannot be after the qualification date {getDate}",
    checkSubmit: "请确认所有表单均已完成并已提交 Please confirm that all forms have been completed and submitted",
    checkPasswordTime: "护照将要过期，请提供有效护照 The passport is about to expired, please provide a valid passport. ",
    checkeQualificationGetStatus: "已获得专业资格，请检查取得資格日期 Professional qualifications have been obtained, please check the qualification date.",
    onlyEnlishInput: "只能输入英文或拼音 Only input English or Pinyin",
    onlyNumberOrCharInput: "只能输入数字或字符 Only input numbers or characters",
    personalInfo: "请完善或保存个人信息资料 Personal Information module information is incomplete, please confirm",
    backgroundInfo: "请完善或保存背景资料资料 Further Information module information is incomplete, please confirm",
    applicationSch: "请完善或保存院校申请资料 University Application module information is incomplete, please confirm",
    education: "请完善或保存教育背景资料 Type of Education Background module information is incomplete, please confirm",
    workExperience: "请完善或保存工作经验资料 Work Experience module information is incomplete, please confirm",
    language: "请完善或保存语言成绩资料 Examination results module information is incomplete, please confirm",
    qualifications: "请完善或保存专业资格资料 Professional Qualification module information is incomplete, please confirm",
    recommend: "请完善或保存推荐人资料 Refereer's module information is incomplete, please confirm",
    arrangements: "请完善或保存后续安排资料 Follow-up Arrangements module information is incomplete, please confirm",
    relativesContact: "请完善或保存父母联系方式资料 Parent Contact Way module information is incomplete, please confirm",
    fileData: "请完善或保存材料上传资料 Student Information Upload module information is incomplete, please confirm",
    pleaseCheckURL: "链接格式请以'https://'或'http://'为开头 Please start the link format with 'https://' or 'http://'",
    checkExamDate: '请检查考试日期 Please check the Test Date',
    onlyEnlishAddres: '请将中文地址修改为英文 Please change the Chinese address to English',

    checkRgstAddress: '超过45字数限制, 建议避免填上整個地址, 以及请把部分內容填在备选联系地址',
    gradeCategoryGpa4: 'GPA 介于1到4之间，可保留两位小数',
    gradeCategoryGpa4d2: 'GPA(两位小数点 上限4.2)',
    gradeCategoryGpa4d3: 'GPA(两位小数点 上限4.3)',
    gradeCategoryGpa4d5: 'GPA(两位小数点 上限4.5)',
    gradeCategoryGpa5: 'GPA 介于1到5之间，可保留两位小数',
    gradeCategoryGpa7: 'GPA 介于1到7之间，可保留两位小数',
    gradeCategoryGpa9: 'GPA(两位小数点, 上限9)',
    gradeCategoryGpa10: 'GPA(两位小数点, 上限10)',
    gradeCategoryGpa12: 'GPA(两位小数点, 上限12)',
    gradeCategoryGpa: 'GPA(两位小数点)',
    gradeCategoryGre: 'GRE(一位小数)',
    gradeCategoryGmat: 'GMAT(一位小数)',
    otherRequirements: 'other requirements(两位小数点)',
    iBDiploma: 'IB Diploma(两位整数, 上限45)',
    advancedPlacement: 'Advanced Placement(整数，介于1到5之间)',
    gaoKao: 'GaoKao(China) (整数, 上限100)',
    hKDSEHongKong: 'HKDSE (Hong Kong) (两位整数)',
    ontarioSSD: 'Ontario Secondary School Diploma (整数, 上限100)',
    sat1: 'SAT1 (USA) (整数，介于400到1600之间)',
    sat2: 'SAT2 (USA) (整数，介于400到1600之间)',
    act: 'ACT (USA) (两位整数)',
    gradeCategoryPercentage: 'GPA 平均分采用百分制并介于40到100之间,可保留两位小数',
    checkUKVI: 'UKVI号码的长度为19到26位 The range of UKVI Number\'s length is 19~26',
    pleaseInputChinaPost: '中国的邮编是6位 China\'s postal code is 6 digits',
    pleaseInputVietnamPost: '越南的邮编是5位 Vietnam\'s postal code is 5 digits',
    contactPostcodeTaiWanValid: '台湾邮政编码应该为3-6位！',
    contactPostcodeUKValid: '英国邮政编码应该为5-8位！',
    pleaseCheckPhoneNumber: '请检查所在区号的手机号码 Please check the mobile number of your area code',
    pleaseEnPunctuation: '请不要使用特殊符号，如需使用标点符号，需要使用英文输入法形式符号 Do not use special symbols. For punctuation, use English input symbols',
    agentEmailRequire: '请填写顾问邮箱 Please input email of consultant',
    agentEmailValid: '请输入正确的顾问邮箱地址 Please enter the correct email address of consultant',
    addSaveSuccess: '新增学生成功，当前表单链接将发送给顾问邮箱',
    addApplicationSchFailMso: '表单已提交，不能再申请新课程 The form has been submitted, and no new courses can be applied for',

    noNullAndNoChinese: '输入的内容需不为空且非中文 The input must be non-empty and non-Chinese!',

    stuPhoneNumberValid: '学生手机号不能与跟进人手机号一致',
    stuEmailValid: '学生邮箱不能与跟进人邮箱一致',
    examTotalTips:'总分必须大于0 The total score must be greater than 0',
  },
  applyCourse: {
    quickMatchCourse: '额外补充信息',
    currentSchoolDontOneClick: '当前学校暂无额外补充信息',
    currentSchoolCanOneClick: '当前学校有额外补充信息，请尽快完善左侧的表单\nThe current school has additional information,\nplease complete the form on the left as soon as possible',
    currentCourseDontOneClick: '该申请课程暂无需额外补充信息',
    matchResult: '以下为额外补充信息信息结果，\n若用户认为不合适可重新选择。',
    courseOfficeLink: '课程网申链接',
    courseLink: '课程链接',
    quickMatching: '快速匹配中...',
    recoverData: '获取数据中...',
    noAjustCourse: '没有合适的课程？',
    changeInputCourse: '切换输入课程',
    chaneSelectCourse: '切换选择课程',
    helpYouMatchingTitle: '系统已为您匹配到课程',
    chooseOnForm: '当前为您匹配到{num}个相似的申请课程，请选择您的课程',
    pleaseSelectAll: '请选完所有的下拉框 Please select all the drop-down boxes',
    pleaseSelectTips: '选择后，若需要取消选择下拉菜单右边有清除按钮 After selection,if youneed to cancel the selection, there is a clear button on the right side ofthe drop-down menu',
    saveTemporalTips: '确定需要【信息待完善，暂存】院校申请信息吗？',
    temporalCourse: '暂存课程',
    saveTemporalTips1:'确定需要【信息待完善，暂存】个人信息嗎？',
  },
  mso: {
    compelete: '完成',
    apply: '申请',
    pleaseLeaveTime: '请留意各种申请及资料上交时间',
    personalInfo: '个人信息',
    schoolApply: '院校申请',
    academicBackground: '学术工作背景',
    recommend: '推荐人',
    studentUpload: '学生资料上传',
    arrangements: '后续安排'
  }
};
