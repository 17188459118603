<template>
  <div>
    <title-button-comp
      v-if="!hideSubmitBtn()"
      :mainTitle="t('stuManagement.referee')"
      :buttonName="t('stuManagement.addReferee')"
      @handleAdd="handleAdd"
      :loading="loading"
      addShow
    >
    <el-upload
        v-if="[2, 3, 4, 5, 6].includes(companyId()) && !hideSubmitBtn() && isBmsLogin()"
        :disabled="uploadLoading"
        action=""
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="handleUploadFile"
        >
        <el-button slot="trigger" type="primary" :loading="uploadLoading" class="submit-form">
          <span class="file-identify">{{ t("stuManagement.fileIdentificationForReferee") }}</span>
        </el-button>
      </el-upload>
    </title-button-comp>
    <el-form
      v-for="(referee, index) in stuRefereeFormData.referees"
      :key="referee.uniqueKey"
      size="small"
      :class="[
        'referee-form',
        !isEditing(referee.uniqueKey) && 'disabled show-form',
      ]"
      :ref="'refereeForm'+referee.uniqueKey"
      :model="referee"
      label-position="top"
      :unique-key="referee.uniqueKey"
    >
      <div class="sub-title">
        {{ t("stuManagement.referee") }}
        {{ stuRefereeFormData.referees.length - index }}
        {{ referee.viewOrder === 1 ? t("stuManagement.mainReferee") : "" }}
        <owl-btn-set
          :updateShow="!isEditing(referee.uniqueKey)"
          @update="updateReferee(referee)"
          deleteShow
          @delete="deleteReferee(referee, index, referee.uniqueKey)"
          v-if="!hideSubmitBtn()"
        />
      </div>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.refereeNum')"
            prop="viewOrder"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.viewOrder"
              @change="handleChangeViewOrder(referee)"
              :options="viewOrderOption"
              :option-config="{ label: 'label', value: 'value' }"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.surname')"
            prop="lastName"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.lastName"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.givenName')"
            prop="firstName"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.firstName"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.title')"
            prop="title"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.title"
              :options="titleOptions"
              :option-config="{ label: 'value', value: 'key' }"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.instituCompany')"
            prop="company"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.company"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.position')"
            prop="position"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.position"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.email')"
            prop="email"
            :rules="[
              rules.needFiledRule,
              {
                type: 'email',
                message: t('validate.emailValid'),
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.email"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.phoneNumber')"
            prop="phoneNumber"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.phoneNumber"
              :characterOnly="true"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.contactAddress1')"
            prop="contactAddress1"
            :rules="rules.needFiledRule"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.contactAddress1"
              :maxlength="45"
            ></owl-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item :label="t('stuManagement.contactAddress2')">
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.contactAddress2"
              :maxlength="45"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.refereeCountry')"
            prop="fkStudentAppCountryId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.fkStudentAppCountryId"
              @change="handleSelectCountry(referee, referee.uniqueKey)"
              :options="countryOptions"
              :option-config="{ label: 'name', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.state')"
            prop="fkAreaStateId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.fkAreaStateId"
              allow-create
              @change="handleSelectState(referee, referee.uniqueKey)"
              :options="stateOptions[referee.uniqueKey]"
              :option-config="{ label: 'fullName', value: 'id' }"
              :isMobileCanSearch="isMobileCanSearch"
            ></owl-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.city')"
            prop="fkAreaCityId"
            :rules="rules.needFiledRule"
          >
            <owl-select
              :disabled="!isEditing(referee.uniqueKey)"
              allow-create
              v-model="referee.fkAreaCityId"
              :options="cityOptions[referee.uniqueKey]"
              :option-config="{ label: 'name', value: 'id' }"
            ></owl-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :xs="24">
          <el-form-item
            :label="t('stuManagement.postcode')"
            prop="contactPostcode"
            :rules="[rules.needFiledRule, checkPostCard(referee.fkStudentAppCountryId)]"
          >
            <owl-input
              :disabled="!isEditing(referee.uniqueKey)"
              v-model="referee.contactPostcode"
              :maxlength="20"
            ></owl-input>
          </el-form-item>
        </el-col>
      </el-row>
      <owl-btn-save
        :loading="loading"
        v-show="isEditing(referee.uniqueKey)"
        @save="handleSave(referee.uniqueKey, index)"
        @delete="cancelReferee(referee, index)"
        show-delete
        style="margin-bottom: 36px"
      />

      <div class="letter-upload-btn" v-if="!hideSubmitBtn() || isNewAppStatus()" v-show="referee.id" >
        <input ref="fileInput" type="file" class="file" @change="(e)=>uploadLetter(e, referee)" multiple/>
        <upload-icon class="icon"></upload-icon>
        <span>{{t('stuManagement.uploadLetter')}}</span>  
      </div>
      <div class="letter-table-wrap">
        <table class="letter-table" v-show="referee.mediaAndAttachedDtos && referee.mediaAndAttachedDtos.length">
          <tr class="header">
            <th style="width: 240px">{{t('stuManagement.letterName')}}</th>
            <th>{{t('stuManagement.letterMatchCourse')}}</th>
            <th style="width:272px" v-if="!hideSubmitBtn()">{{t('common.opera')}}</th>
          </tr>
          <tr v-for="(item, mediaIndex) in referee.mediaAndAttachedDtos" :key="mediaIndex">
            <td @click="getIssueFilePreview(item)" :style="{cursor:'pointer', textDecoration:'underline'}">{{item.fileNameOrc}}</td>
            <td>{{item.fkStudentInstitutionCourseId && item.fkStudentInstitutionCourseId.length? 
                getCourseNameByIds(item.fkStudentInstitutionCourseId) || t('stuManagement.beCurrent'): 
                t('stuManagement.beCurrent')
            }}</td>
            <td class="operate" v-if="!hideSubmitBtn()">
              <span class="btn" @click="operateLetter(item, referee.id)">{{t('stuManagement.specifyCourses')}}</span>
              <span class="sperate"></span>
              <span class="btn" @click="updateLetter(item)">{{t('stuManagement.updateLetterName')}}</span>
              <span class="sperate"></span>
              <span class="btn del" @click="deleteLetter(index,mediaIndex)">{{t('common.del')}}</span>
            </td>
          </tr>
        </table>
      </div>
    </el-form>
    <div class="form-no-data-tip" v-if="!stuRefereeFormData.referees.length">
      {{t('validate.noFormDataTip')}}
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="610"
    >
      <template slot="title">
        <div class="letter-dialog-title">{{t('stuManagement.specifyCoursesTitle')}}</div>
        <div class="letter-dialog-tip">{{t('stuManagement.specifyCoursesTip')}}</div>
      </template>
      
      <div class="inistition-tree">
        <el-tree
          :expand-on-click-node="false"
          :check-on-click-node="true"
          ref="courseTree"
          default-expand-all
          :data="institionTree"
          show-checkbox
          node-key="id"
          class="custome-tree"
          :props="{
            children: 'courses',
            label: 'name',
            disabled: isNodeDisabled
          }">
        </el-tree>
      </div>
      <div style="text-align:center" slot="footer">
        <el-button size="small" @click="dialogVisible = false">{{t('common.cancel')}}</el-button>
        <el-button size="small" type="primary" @click="letterBindCourse">{{t('stuManagement.saveSetting')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="uploadFilesObj.visible" width="480px" @close="cancelUpload">
      <template slot="title">
        <div class="letter-dialog-title">{{t('stuManagement.uploadLetter')}}</div>
        <div class="letter-dialog-tip" >{{t('stuManagement.inputLetterName')}}</div>
      </template>
      <div class="letter-name-list" v-for="(file, index) in uploadFilesObj.list" :key="index">
        <file-icon class="file-icon"></file-icon>
        <el-input size="small" class="npt" v-model="file.newName"></el-input>
        <span class="subfix">{{file.subfix}}</span>
      </div>
      <div style="text-align:center" slot="footer">
        <el-button size="small" @click="uploadFilesObj.visible = false">{{t('common.cancel')}}</el-button>
        <el-button size="small" type="primary" @click="confirmUpload">{{t('stuManagement.clickUpload')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import {
  enCvParseApi,
  getIssueTitle,
  getStateOptions,
  getCityOptions,
  deleteStudentRef,
  saveOrUpdateStudentRef,
  uploadIssueStudentRefeApi,
  updateRefeFileToCourseRelationApi,
  deleteStudentRefeFileApi,
  updateFileNameByIssueApi,
  getIssueFilePreviewApi,
} from "@/api/common.js";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlSelect from "/packages/components/OwlSelect.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";
import UploadIcon from "/packages/components/svg-icon/UpIcon.vue";
import FileIcon from "/packages/components/svg-icon/FileIcon";
export default {
  name: "StudentReferee",
  computed: {
    i18n() {
      return i18n;
    },
    countryOptions() {
      return this.ctryOptions();
    },
    institionTree(){
      let tree = [];
      for (let key in this.studentApplyInstitutionTree){
        tree.push(Object.assign({id: key}, this.studentApplyInstitutionTree[key] ))
      }
      return tree;
    },
    applyInstitutionList(){
      let list = [];
      for (let key in this.studentApplyInstitutionTree){
        list.push(...this.studentApplyInstitutionTree[key].courses)
      }
      return list
    },
    getLetterList(){
      let arr = [];
      for(let item of this.stuRefereeFormData.referees){
        if(item.mediaAndAttachedDtos && item.mediaAndAttachedDtos.length){
          for(let jtem of item.mediaAndAttachedDtos){
            arr.push({
              id: jtem.id,
              fkFileGuid: jtem.fkFileGuid,
              fkStudentInstitutionCourseId: jtem.fkStudentInstitutionCourseId,
              fileNameOrc: jtem.fileNameOrc
            })
          }
        }
      }
      return arr;
    },
    isNodeDisabled(){
      return (data, node)=>{
        if(node.checked) return false;
        return this.getLetterList.filter(item=>{
          if(item.fkFileGuid == this.letterIdWithOperation){
            if(item.fkStudentInstitutionCourseId && item.fkStudentInstitutionCourseId.indexOf(data.id)>=0){
              return false;
            }
          }
          return item.fkStudentInstitutionCourseId && item.fkStudentInstitutionCourseId.indexOf(data.id)>=0
        }).length >= 2
      }
    },
    getCourseNameByIds(){
      return (fkStudentInstitutionCourseId) => {
        let courseName = '';
        for(let item of fkStudentInstitutionCourseId){
          const course= this.applyInstitutionList.find(course=>course.id == item)
          if(course) courseName += course.name+'、'
        }
        return courseName && courseName.substring(0, courseName.length-1)
      }
    },
    // 手机端显示可搜索下拉
    isMobileCanSearch() {
      return this.platformLimitList().includes('get_scholarship');
    },
  },
  components: { 
    OwlInput,
    OwlSelect, 
    OwlBtnSave, 
    OwlBtnSet, 
    UploadIcon,
    FileIcon
  },
  mixins: [mixin, i18n],
  inject: ["stuid", "ctryOptions", "hideSubmitBtn", 'companyId', 'isBmsLogin', "platformType", "platformLimitList", "isNewAppStatus"],
  props: {
    studentApplyInstitutionTree: {
      type: Object,
      default: () => ({})
    },
    studentReferenceDtos: {
      type: Array,
      default: () => ([])
    },
    required:Boolean,
  },
  data() {
    return {
      uploadLoading: false,
      fkPlatformType:this.platformType(),
      dialogVisible: false,
      letterIdWithOperation: '',
      refereeIdWithOperation: 0,
      checkedId: [],
      loading: false,
      stateOptions: {},
      cityOptions: {},
      titleOptions: [],
      showDetailData: false,
      uploadFilesObj: {
        visible: false,
        list: [],
        confirmCallback: null,
        closeCallback: null,
      },
      viewOrderOption: [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ],
      stuRefereeFormData: {
        referees: [
          // {
          //   viewOrder: 1, // 排序
          //   lastName: "", // 姓
          //   firstName: "", // 名
          //   title: "", // 称谓
          //   company: "", // 院校或公司
          //   position: "", // 职位
          //   email: "", // 邮箱
          //   phoneNumber: "", // 电话号码
          //   contactAddress1: "", // 联系地址1
          //   contactAddress2: "", // 联系地址2
          //   fkStudentAppCountryId: "", // 国家或地区
          //   fkAreaStateId: "", // 省份、洲
          //   fkAreaCityId: "", // 城市
          //   contactPostcode: "", // 邮编
          //   id: null,
          //   fkStudentId: this.stuid(),
          //   mediaAndAttachedDtos: [] //推荐信
          // },
        ],
      },
    };
  },
  watch: {
    getLetterList: {
      handler(newVal){
        this.$emit('on-letter-change', newVal)
      },
      deep: true,
    }
  },
  mounted() {
    this.getIssueTitle();
  },
  methods: {
    handleChangeViewOrder(referee) {
      const viewOrder = referee.viewOrder;
      const referees = this.stuRefereeFormData.referees;
      let num = 0;
      referees.map((referee) => {
        if(viewOrder.toString() === referee.viewOrder.toString()){
          num++;
        }
      })
      if (num > 1) {
        this.$alrt(
          this.t("stuManagement.refereeNumMsg", {
            num: viewOrder,
          }),
          this.t('common.tip'),
          {
            confirmButtonText: this.t("common.confirm"),
          }
        );
        referee.viewOrder = null;
      }
    },
    beforeUpload(file) {
      const fileTypes = [
        'image/jpeg', 'image/png', 'application/pdf', 'image/bmp', 'image/gif'
      ];
      const type = file.type.toLowerCase();
      if (!fileTypes.includes(type)) {
        this.$msge.error('不支持该文件的格式，请重新上传符合格式要求的文件！');
        return false;
      }
      return true;
    },
    async handleUploadFile(params) {
      this.uploadLoading = true;
      const formData =  new FormData();
      const file = params.file;
      formData.append('file', file);
      formData.append('type', 'issueRecommendationLetterOrc');
      try {
        const { data } = await enCvParseApi(formData);
        this.uploadLoading = false;
        const resData = JSON.parse(data.data);
        console.log(resData)
        if (resData.data.error_code) {
          return this.$msge.error(resData.data.error_msg);
        }
        if ((Object.keys(resData.data).length) && (resData.status === 'success')) {
          const KEY = {
            'address': 'address',
            'associationName': 'company',
            'city': 'fkAreaCityId',
            'province': 'fkAreaStateId',
            'country': 'fkStudentAppCountryId',
            'email': 'email',
            'phone': 'phoneNumber',
            'position': 'position',
            'postCode': 'contactPostcode',
            'recommenderFamilyName': 'lastName',
            'recommenderFirstName': 'firstName',
          }
          const data = {};
          for (const key in resData.data) {
            if (Object.hasOwnProperty.call(resData.data, key)) {
              const obj = resData.data[key];
              if (KEY[key]) {
                if (obj.id) {
                  data[KEY[key]] = obj.id !== -1 ? obj.id:'';
                } else {
                  data[KEY[key]] = obj.text;
                }
              }
            }
          }
          const fkGuidAndTypeKey=new Object();
          if(resData.fkGuIds&&resData.fkGuIds.length){
            fkGuidAndTypeKey[resData.fkGuIds[0]]='REFERENCE_LETTER'
            Object.assign(data,{fkGuidAndTypeKey})
          }
          this.handleAdd(data)
        }
      } catch (err) {
        this.uploadLoading = false;
      }
    },
    async setInitData(data) {
      if(!data || !data.length) return;
      this.loading = true;
      let lng = data.length;
      const referees = [];
      for await (let item of data) {
        const key = Date.now();
        referees.unshift({
          mediaAndAttachedDtos: item.mediaAndAttachedDtos || [],
          viewOrder: item.viewOrder || lng--,
          id: item.id,
          lastName: item.lastName,
          firstName: item.firstName,
          title: ((item.title || item.title === 0) && item.title.toString()) || '',
          company: item.company,
          position: item.position,
          email: item.email,
          fkStudentId: this.stuid(),
          phoneNumber: item.phoneNumber,
          contactAddress1: item.contactAddress1,
          contactAddress2: item.contactAddress2,
          fkStudentAppCountryId: this.getIdTypeValue(item.fkStudentAppCountryId),
          fkAreaStateId: this.getIdTypeValue(item.fkAreaStateId),
          fkAreaCityId: this.getIdTypeValue(item.fkAreaCityId),
          contactPostcode: item.contactPostcode,
          uniqueKey: key,
        });
        await this.waitMoment(1);
        if (item.fkStudentAppCountryId) {
          await this.getStateOptions(item.fkStudentAppCountryId, key);
        }
        if (item.fkAreaStateId !== null && !isNaN(Number(item.fkAreaStateId))) {
          await this.getCityOptions(item.fkAreaStateId, key);
        }
      }
      this.stuRefereeFormData.referees = referees;
      this.loading = false;
    },
    async getStateOptions(country, uniqueKey) {
      if (country) {
        const { data } = await getStateOptions(country);
        this.$set(this.stateOptions, uniqueKey, data.datas || []);
      }
    },
    async getCityOptions(state, uniqueKey) {
      if (!state || isNaN(state)) return;
      if (
        this.stateOptions[uniqueKey] &&
        this.stateOptions[uniqueKey].length !== 0
      ) {
        const { data } = await getCityOptions(state);
        this.$set(this.cityOptions, uniqueKey, data.datas || []);
      }
    },
    async getIssueTitle() {
      const { data } = await getIssueTitle();
      this.titleOptions = data.data.titalEnum;
    },
    handleSelectCountry(referee, uniqueKey) {
      this.$delete(this.stateOptions, uniqueKey);
      this.$delete(this.cityOptions, uniqueKey);
      const country = referee.fkStudentAppCountryId;
      referee.fkAreaStateId = "";
      referee.fkAreaCityId = "";
      this.getStateOptions(country, uniqueKey);
    },
    handleSelectState(referee, uniqueKey) {
      this.$delete(this.cityOptions, uniqueKey);
      const state = referee.fkAreaStateId;
      referee.fkAreaCityId = "";
      if (referee) {
        this.getCityOptions(state, uniqueKey);
      }
    },
    handleAdd(item) {
      if(this.hasEdit()) return;
      // 推荐人是升序排序，第一个保存的在最前面
     
      const uniqueKey = Date.now();
      let referee = {
        viewOrder: this.stuRefereeFormData.referees.length + 1,
        mediaAndAttachedDtos: [],
        lastName: "",
        firstName: "",
        title: "",
        company: "",
        position: "",
        email: "",
        phoneNumber: "",
        contactAddress1: "",
        contactAddress2: "",
        fkStudentAppCountryId: null,
        fkAreaStateId: null,
        fkAreaCityId: null,
        contactPostcode: "",
        fkStudentId: this.stuid(),
        uniqueKey: uniqueKey,
        id: null,
      }
      if (item) {
        referee = Object.assign(referee, item);
      }
      this.stuRefereeFormData.referees.unshift(referee);
      this.switchEditStatus(uniqueKey);
      this.onFormStatusChanged();
    },
    // 删除推荐人
    async deleteReferee(referee, index, uniqueKey) {
      const vueComponent = this.$refs['refereeForm'+uniqueKey][0];
      if (vueComponent) {
        vueComponent.clearValidate();
      }
      await this.$confIssue(""+this.t("validate.delMsgReferInfo"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: ""+this.t("common.confirm"),
        cancelButtonText: ""+this.t("common.cancel"),
      })
      if (referee.id) {
        this.loading = true;
        try {
          await deleteStudentRef(referee.id)
          this.onFormStatusChanged();
        } catch {
          this.$msge({
            type: "error",
            message: this.t("common.deleteFail"),
          });
          return;
        } finally {
          this.loading = false;
        }
      }
      this.$msge({
        type: "success",
        message: this.t("common.deleteSucc"),
      });
      this.isEditing(referee.uniqueKey) && this.switchEditStatus(referee.uniqueKey)
      this.onFormStatusChanged();
      this.stuRefereeFormData.referees.splice(index, 1);
      if (!this.stuRefereeFormData.referees.length) {
        this.addUncompletedStatus()
      }
      this.$delete(this.stateOptions, referee.uniqueKey);
      this.$delete(this.cityOptions, referee.uniqueKey);
    },
    // 取消推荐人
    cancelReferee(referee, index) {
      if(this.loading) return;
      this.$confIssue(this.t("validate.cancelEdit"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        const backup = this.getEditBackupByKey(referee.uniqueKey)
        this.switchEditStatus(referee.uniqueKey)
        this.onFormStatusChanged();
        if(backup) {
          this.$set(this.stuRefereeFormData.referees, index, backup)
          this.loading = true;
          if (backup.fkStudentAppCountryId) {
            await this.getStateOptions(backup.fkStudentAppCountryId, referee.uniqueKey);
          }
          if (backup.fkAreaStateId !== null && !isNaN(Number(backup.fkAreaStateId))) {
            await this.getCityOptions(backup.fkAreaStateId, referee.uniqueKey);
          }
          this.loading = false;
        } else {
          this.stuRefereeFormData.referees.splice(index, 1)
        }
      })
    },
    // 提供检查当前表单状态方法
    async checkFormStatus() {
      if(!this.required) return {
        isEdit: this.editList.length == 0, 
        validate: true,
        error: this.t('validate.recommend')
      };
      let validate = true;
      try {
        const temp = Object.keys(this.$refs);
        if(this.$refs && temp && temp.length > 1) {
          for await(let key of temp) {
            if (key !== 'fileInput') {
              if (!this.$refs[key].length) {
                this.$refs[key] = null;
                continue;
              }
              await this.$refs[key][0].validate();
            }
          }
        }
      } catch {
        validate = false;
      }
      return {
        isEdit: this.editList.length == 0 && this.stuRefereeFormData.referees.length > 0,
        validate,
        error: this.t('validate.recommend')
      }
    },
    // 更新列表局部的子项
    updateReferee(referee) {
      if(this.hasEdit()) return;
      this.switchEditStatus(referee.uniqueKey, referee);
      this.onFormStatusChanged();
    },
    async handleSave(uniqueKey, index) {
      const item = this.stuRefereeFormData.referees[index];
      let error = false;
      for await (let item of ['lastName', 'firstName', 'title', 'company','position','email','phoneNumber','contactAddress1','fkStudentAppCountryId','fkAreaStateId','fkAreaCityId','contactPostcode']){
        const valid = await new Promise(res=>{
          this.$refs['refereeForm'+uniqueKey][0].validateField(item,(error)=>{
            res(error)
          })
        })
        if(valid) error = true;
      }
      if(error) return;
      this.loading = true;
      saveOrUpdateStudentRef(item)
        .then((res) => {
          this.switchEditStatus(uniqueKey);
          this.onFormStatusChanged();
          this.$msge({
            message: this.t("common.saveSucc"),
            type: "success",
          });
          if (this.stuRefereeFormData.referees.length === 1) {
            this.addCompletedStatus()
          }
          if (item.id) return;
          this.stuRefereeFormData.referees[index].id = res.data.data;
        })
        .catch((err) => {
          this.$msge({
            message: err|| this.t("common.saveFail"),
            type: "error",
          });
        }).finally(() => {
          this.loading = false;
        });
    },
    // 给外部使用自动保存功能
    async autoSave(){
      if(!this.editList.length) return;
      const key = this.editList[0]
      const index = this.stuRefereeFormData.referees.findIndex(item=>item.uniqueKey == key)
      if(index >= 0) await this.handleSave(key, index)
    },
    onFormStatusChanged(){
      let lng = this.stuRefereeFormData.referees.length
      let editing = this.editList.length;
      let proccess = 0;
      if(lng){
        proccess = Math.floor((lng - editing) / lng * 100);
      }
      this.$emit('on-porccess-changed', proccess)
    },
    operateLetter(letter, refereeId){
      this.letterIdWithOperation = letter.fkFileGuid;
      this.refereeIdWithOperation = refereeId
      this.dialogVisible = true;
      setTimeout(()=>{
        if(!this.$refs.courseTree) return;
        if( letter.fkStudentInstitutionCourseId) {
          let arr = [];
          for (let item of this.institionTree){
            for(let jtem of item.courses){
              letter.fkStudentInstitutionCourseId.indexOf(jtem.id) >= 0 && arr.push(jtem)
            }
          }
          this.$refs.courseTree.setCheckedNodes(arr)
        } else {
          this.$refs.courseTree.setCheckedNodes([])
        }
      })
    },
    async uploadLetter(e, referee){
      try {
        this.uploadFilesObj = {
          visible: true,
          list: Array.from(e.target.files).map((item)=>{
            const dotIndex = item.name.lastIndexOf('.');
            return {
              subfix: item.name.substring(dotIndex),
              newName: item.name.substring(0, dotIndex)
            };
          }),
          confirmCallback: async (fileNames) => {
            // 限制2M
            const fileData=e.target.files[0]
            const sizeLimit = 2;
            if ((fileData.size / 1024 / 1024) > sizeLimit) {
              e.target.value = '';
              this.uploadFilesObj.visible = false;
              this.uploadFilesObj.confirmCallback = null;
              this.$msge.error(this.t("stuManagement.uploadSize",{limit: sizeLimit}));
              return false;
            }
            const {data} = await uploadIssueStudentRefeApi({
              files: e.target.files,
              fileNames,
              stuId: this.stuid(),
              fkStudentReferenceId: referee.id
            })
            this.$msge.success(this.t('common.saveSucc')+'')
            for(let media of data.datas){
              media.fkFileGuid = media.fileGuid;
              const dotIndex = media.fileNameOrc.lastIndexOf('.');
              media.fileNameOrc = media.fileNameOrc.substring(0, dotIndex)
              media.id = media.fkMediaAndAttachedId
              media.fkStudentInstitutionCourseId = []
              referee.mediaAndAttachedDtos.push(media)
            }

            this.uploadFilesObj.visible = false;
            this.uploadFilesObj.confirmCallback = null;
          },
          closeCallback: ()=>{
            e.target.value = '';
            this.uploadFilesObj.closeCallback = null;
          }
        }
      } catch(e) {
        this.$msge.error(e.message || this.t('common.saveFail')+'')
      }
    },
    confirmUpload(){
      for (let item of this.uploadFilesObj.list){
        if(!item.newName || /^\s+$/.test(item.newName)){ // /^\s+$/.test 找出目标字符串中的所有空白字符  [\s]表示: 只要出现空白就匹配 [\S]表示: 非空白就匹配
          this.$msge.error(this.t('validate.noFormDataTip'))
          return;
        }
        if(item.newName.toString().match(/[\u4e00-\u9fa5]/)) {
          item.newName.toString().replace(/[\u4e00-\u9fa5]/g, '');
          this.$msge.error(this.t('validate.onlyEnlishInput'));
          return;
        }
      }
      this.uploadFilesObj.confirmCallback && this.uploadFilesObj.confirmCallback(this.uploadFilesObj.list.map(item=>item.newName+item.subfix))
    },
    getIssueFilePreview(fileData) {
      const fileKey = fileData.fileKey;
      getIssueFilePreviewApi(fileKey).then(res => {
        const url = res.data.data;
        if (url) {
          window.open(url);
        }
      })
    },
    async updateLetter(letter){
      try {
        const {value} = await this.$prmt(
          this.t('stuManagement.inputLetterName'),
          this.t('common.tip'),
          {
            confirmButtonText: this.t('common.confirm'),
            cancelButtonText: this.t('common.cancel'),
            inputPattern: /^(?![\u4e00-\u9fa5]*$)(?! *$)[^\u4e00-\u9fa5]*$/,
            inputErrorMessage: this.t('validate.noNullAndNoChinese')
          }
        )

        await updateFileNameByIssueApi({
          fkGuId: letter.fkFileGuid,
          newFileName: value
        })
        letter.fileNameOrc = value
      } catch {
      }
    },
    async letterBindCourse(){
      let arr = [];
      let checked = this.$refs.courseTree.getCheckedNodes()
      for (let item of checked){
        !item.courses && arr.push(item.id)
      }
      try {
        await updateRefeFileToCourseRelationApi({
          fkStudentInstitutionCourseId: arr,
          fkStudentId: this.stuid(),
          fkFileGuid: this.letterIdWithOperation
        })
        this.$msge.success(this.t('common.saveSucc')+'')
        this.dialogVisible = false;
        const item = this.stuRefereeFormData.referees.find(item=>item.id == this.refereeIdWithOperation)
        if(item){
          const jtem = item.mediaAndAttachedDtos.find(jtem=>jtem.fkFileGuid == this.letterIdWithOperation)
          jtem.fkStudentInstitutionCourseId = arr;
        }
      }catch(e) {
        this.$msge.error(e.message || this.t('common.saveFail')+'')
      }
    },
    deleteLetter(letterIndex,mediaIndex){
      const id = this.stuRefereeFormData.referees[letterIndex].mediaAndAttachedDtos[mediaIndex].id;
      this.$confIssue(this.t("validate.delMsgReferLetterInfo"), "", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.t("common.confirm"),
        cancelButtonText: this.t("common.cancel"),
      }).then(async ()=>{
        await deleteStudentRefeFileApi(id)
        this.$msge({
          type: "success",
          message: this.t("common.deleteSucc"),
        });
        this.stuRefereeFormData.referees[letterIndex].mediaAndAttachedDtos.splice(mediaIndex, 1)
      })
    },
    cancelUpload(){
      this.uploadFilesObj.closeCallback && this.uploadFilesObj.closeCallback()
    }
  },
};
</script>
<style lang="scss" scoped>
.custome-tree{
  /deep/ .el-tree-node__content:hover{
    color: #333;
  }
}
.letter-upload-btn{
  height: 40px;
  width: 100%;
  border: 1px solid #00B696;
  border: 1px solid var(--form-color-success);
  border-radius: 4px;
  color: #00B696;
  color: var(--form-color-success);
  transition: all .25s;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  .file{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }
  .icon{
    width: 24px;
    height: 18px;
    margin-right: 4px;
    fill: #00B696;
    fill: var(--form-color-success);
  }
  &:hover{
    opacity: .8;
  }
}
.letter-dialog-title{
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  padding: 10px 10px 0;
}
.letter-dialog-tip{
  color: #ff9700;
  text-align: left;
  color: var(--form-color-primary);
  font-size: 14px;
  padding: 0 10px 0;
}

.inistition-tree{
  height: 310px;
  overflow-y: auto;
  padding: 0 10px 0;
  /deep/ .el-tree{
    &>.el-tree-node{
      padding: 10px 0;
      border-bottom: 1px solid #dcdfe6;
      &:first-child{
        border-top: 1px solid #dcdfe6;
      }
      &>.el-tree-node__content .el-tree-node__label{
        font-weight: bold;
      }
    }
  }
}
.letter-table{
  border-spacing: 0;
  border-radius: 4px;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  min-width: 750px;
  td,th{
    vertical-align: middle;
    text-align: center;
    border: 1px solid #dcdfe6;
    padding: 10px;
    transition: background-color .2s;
  }
  td{
    font-size: 14px;
    color: #333;
    &.operate{
      white-space: nowrap;
      span{
        display: inline-block;
        vertical-align: middle;
      }
      .btn{
        cursor: pointer;
        transition: opacity .25s;
        &:hover{
          opacity: .8;
        }
        &.del{
          opacity: 1;
          color:#AEAEAE;
          &:hover{
            
            color: #ec808d;
          }
        }
      }
      .sperate{
        margin: 0 20px;
        height: 12px;
        border-left: 1px solid #333;
        
      }
    }
  }
  tr:nth-child(2n+1){
    td{
      background-color: #f1f1f1;
    }
  }
  tr:hover{
    td{
      background-color: #e1e1e1;
    }
  }
  .header{
    th{
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      background: #00B696;
      background: var(--form-color-success);
      border-color:#00B696;
      border-color: var(--form-color-success);
      border-right-color: #dcdfe6;
      &:last-child{
        border-right-color: #00B696;
        border-right-color: var(--form-color-success);
      }
    }
  }
}
.letter-name-list{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .file-icon{
    height: 32px;
    fill: #333;
    margin-right:12px;
  }
  .subfix{
    padding-left: 6px;
    font-size: 14px;
    white-space: nowrap;
  }
}
.letter-table-wrap{
  overflow-x: auto;
}
@media screen and(max-width:950px){
  /deep/ .el-dialog{
    width:calc(100vw - 24px);
  }
}
</style>