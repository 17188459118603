<template>
  <el-dialog
    custom-class="reset--dialog"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="510px">
    <div class="dialgo-header">
      <p class="title">{{ t('common.intelligentIdentificationStudentInfo') }}</p>
      <p class="tips"><span class="red-star">*</span> {{ t('common.intelligentIdentificationTips') }}</p>
    </div>
    <div class="dialog-content">
      <!-- <upload-block
      key="1"
      main-color="#FF6C00"
      :header-info="headerInfo.orange"
      :content-info="contents.orange"
      type="issueFileResolverPassportNational"
      @set-analysis-status="handleSetAnalysisStatus"
      @set-result-Identified="setResultIdentified"
      @set-loading="setLoading"
      ></upload-block>
      <upload-block
      key="2"
      main-color="#00C6A3"
      :header-info="headerInfo.green"
      :content-info="contents.green"
      type="issueFileResolverPassportCn"
      @set-analysis-status="handleSetAnalysisStatus"
      @set-result-Identified="setResultIdentified"
      @set-loading="setLoading"
      ></upload-block> -->
      <upload-block
      key="3"
      main-color="#1E88F0"
      :header-info="headerInfo.blue"
      :content-info="contents.blue"
      type="issueFileResolverEduBackground"
      @set-analysis-status="handleSetAnalysisStatus"
      @set-result-Identified="setResultIdentified"
      @set-loading="setLoading"
      ></upload-block>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" :loading="loading" @click="handleContinueTofill">{{t('common.continuetofill')}}</el-button>
      <el-button :loading="loading" @click="close">{{t('common.cancel')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import UploadBlock from './UploadBlock.vue';
import i18n from "@/locale/index";
export default {
  name: 'IntelligentIdentificationDialog',
  mixins: [i18n],
  components: {
    UploadBlock
  },
  model: {
    prop: 'identificationDialog',
    event: 'changeVisible'
  },
  props: {
    identificationDialog: Boolean,
  },
  computed: {
    visible: {
      get() {
        return this.identificationDialog
      },
      set(value) {
        this.$emit('changeVisible', value);
      }
    },
  },
  data: () => {
    return {
      isNotUpload: true,
      loading: false,
      headerInfo: {
        orange: {
          imgUrl: require('@/assets/orange-idcard.jpg'),
          name: 'orange-idcard',
          headerText: 'common.uploadPassportNational'
        },
        green: {
          imgUrl: require('@/assets/green-passport.jpg'),
          name: 'green-passport',
          headerText: 'common.uploadPassport'
        },
        blue: {
          imgUrl: require('@/assets/blue-background.jpg'),
          name: 'blue-background',
          headerText: 'common.uploadBackground'
        }
      },
      contents: {
        orange: {
          hasUploaded: false,
          status: [
            {
              title: 'common.stuName',
              key: 'nameZh',
              exist: false,
            },
            {
              title: 'common.stuNameEn',
              key: 'firstName',
              exist: false,
            },
            {
              title: 'common.stuGender',
              key: 'gender',
              exist: false,
            },
            {
              title: 'common.stuBirthPlace',
              key: 'cityBirth',
              exist: false,
            },
            {
              title: 'common.stuPassport',
              key: 'passportNumber',
              exist: false,
            },
          ],
        },
        green: {
          hasUploaded: false,
          status: [
            {
              title: 'common.stuName',
              key: 'nameZh',
              exist: false,
            },
            {
              title: 'common.stuNameEn',
              key: 'firstName',
              exist: false,
            },
            {
              title: 'common.stuGender',
              key: 'gender',
              exist: false,
            },
            {
              title: 'common.stuBirthPlace',
              key: 'cityBirth',
              exist: false,
            },
            {
              title: 'common.stuPassport',
              key: 'passportNumber',
              exist: false,
            },
          ],
        },
        blue: {
          hasUploaded: false,
          status: [
            {
              title: 'common.stuEduExp',
              key: 'eduBack',
              exist: false,
            },
            {
              title: 'common.stuWorkExp',
              key: 'workExp',
              exist: false,
            },
          ]
        }
      },
      personInfo: {},
      workExp: [],
      eduBack: [],
      fkGuids:{},
    }
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    setResultIdentified({ workExp, eduBack, personInfo,guid }) {
      if (personInfo) {
        this.personInfo = Object.assign(this.personInfo, personInfo);
      }
      if(guid&&eduBack&&eduBack.length){
        this.fkGuids={};
        this.fkGuids[guid]='CV';
        eduBack[0]['fkGuidAndTypeKey']=this.fkGuids
      }
      if (eduBack) {
        this.eduBack = eduBack;
      }
      if (workExp) {
        this.workExp = workExp;
      }
    },
    handleSetAnalysisStatus(obj) {
      const personInfo = obj.personInfo;
      switch (obj.type) {
        case 'issueFileResolverPassportNational': {
          const idCardStatus = this.contents.orange.status.map((item) => {
            if (personInfo[item.key] !== undefined) {
              item.exist = true;
            }
            return item;
          });
          this.contents.orange.status = idCardStatus;
          this.contents.orange.hasUploaded = true;
          break;
        }
        case 'issueFileResolverPassportCn': {
          const passportStatus = this.contents.green.status.map((item) => {
            if (personInfo[item.key] !== undefined) {
              item.exist = true;
            }
            return item;
          })
          this.contents.green.status = passportStatus;
          this.contents.green.hasUploaded = true;
          break;
        }
        case 'issueFileResolverEduBackground': {
          const eduBack = obj.eduBack;
          const workExp = obj.workExp;
          const backInfoStatus = this.contents.blue.status.map((item) => {
            if (item.key === 'eduBack') {
              item.exist = eduBack && eduBack.length > 0;
            } else if (item.key === 'workExp') {
              item.exist = workExp && workExp.length > 0;
            }
            return item;
          })
          this.contents.blue.status = backInfoStatus;
          this.contents.blue.hasUploaded = true;
        }
      }
    },
    handleContinueTofill() {
      const personInfo = Object.keys(this.personInfo).length ? this.personInfo : undefined;
      const obj = {
        personInfo,
        eduBack: this.eduBack,
        workExp: this.workExp,
      }
      this.$emit('get-result-Identified', obj);
      this.close();
    },
    close() {
      this.clearData();
      this.clearStatus();
      this.$emit('changeVisible', false);
    },
    clearData() {
      this.personInfo = {};
      this.workExp = [];
      this.eduBack = [];
    },
    clearStatus() {
      const contents = this.contents;
      ['orange', 'green', 'blue'].map((key) => {
        contents[key].hasUploaded = false;
        const status = contents[key].status;
        contents[key].status = status.map((item) => {
          item.exist = false;
          return item;
        })
      })
    }
  }
}
</script>

<style lang="scss">
.reset--dialog{
  & .el-dialog__header {
    background: #fff!important;
    border-radius: 4px;
    & .el-dialog__title {
      color: #232323;
      font-size: 20px;
    }
    & .el-dialog__headerbtn{
      width: 35px;
      height: 35px;
      top: 30px;
      right: 40px;
      background: #ECF1F1;
      border-radius: 50%;
      & :before {
        content: "\e6db";
        font-size: 20px;
      }
    }
  }
  & .el-dialog__body {
    padding: 0;
  }
  .dialgo-header {
    padding: 0 40px 20px;
    .title {
      font-weight: bold;
      font-size: 22px;
    }
    .red-star {
      color: #ff0000;
    }
    .tips {
      margin-top: 12px;
      font-size: 12px;
    }
  }
  .dialog-content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 40px;
    .dialog-block {
      width: 230px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      color: #fff;
      .information {
        height: 240px;
        width: 100%;
        margin-top: 4px;
        background: #F7F7F7;
      }
    }
  }
  .dialog-footer {
    height: 73px;
    line-height: 72px;
    text-align: center;
    border-top: 1px solid #F1F1F1;
    .el-button {
      width: 120px;
    }
  }
}
</style>