<template>
  <div class="apply-proccess">
    <step-circle v-if="list[0] && list[0].length" id="stepCircle1" :proccess="proccess1" color="#FFAB30" subColor="#FF4D35" :label="t('mso.personalInfo')"/>
    <step-circle v-if="list[1] && list[1].length" id="stepCircle2" :proccess="proccess2" color="#27A1FF" subColor="#5F5BFF" :label="t('mso.schoolApply')"/>
    <step-circle v-if="list[2] && list[2].length" id="stepCircle3" :proccess="proccess3" color="#A95DFF" subColor="#6F37FF" :label="t('mso.academicBackground')"/>
    <step-circle v-if="list[3] && list[3].length" id="stepCircle4" :proccess="proccess4" color="#00D180" subColor="#00AACE" :label="t('mso.recommend')"/>
    <step-circle v-if="list[4] && list[4].length" id="stepCircle5" :proccess="proccess5" color="#DA5F5F" subColor="#D23699" :label="t('mso.studentUpload')"/>
    <step-circle v-if="list[5] && list[5].length" id="stepCircle6" :proccess="proccess6" color="#C1CF4D" subColor="#20B33B" :label="t('mso.arrangements')"/>
  </div>
</template>

<script>
import i18n from "@/locale/index";
import StepCircle from '../components/StepCircle.vue'
export default {
  mixins: [i18n],
  props: {
    list: {
      type: Array,
      default: ()=>{
        return new Array(6).fill('')
      }
    },
    proccess1: Number,
    proccess2: Number,
    proccess3: Number,
    proccess4: Number,
    proccess5: Number,
    proccess6: Number,
  },
  components: {
    StepCircle
  }
}
</script>
<style lang="scss" scoped>
.apply-proccess{
  margin: 18px 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: #F5F9FD;
  border-radius: 4px;
  padding:10px 24px;
  flex-wrap: wrap;
  
}
@media screen and (max-width: 950px) {
  .apply-proccess{
    margin-left: 0;
    display: none;
  }
}
</style>