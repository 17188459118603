<template>
  <svg
    t="1625559752721"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3054"
    :width="width"
    :height="height"
    :fill="fill"
  >
    <path pid="0" d="M791.62 351.477c0-170.879-139.81-305.51-310.689-305.51s-310.69 134.631-310.69 305.51c0 113.92 62.139 217.483 160.524 269.264-134.632 51.782-233.017 170.88-258.908 310.69 0 10.356 0 20.712 5.178 25.89 0 15.535 5.178 20.713 15.535 20.713h5.178c15.534 0 31.069-10.357 36.247-25.891 31.069-165.7 176.057-279.62 341.758-284.798h5.178c170.88-5.179 305.511-144.989 310.69-315.868zm-559.24 0c0-134.632 108.74-243.373 243.373-243.373s243.373 113.92 243.373 243.373c0 134.632-108.741 238.195-238.195 243.373h-10.356C341.12 589.672 237.558 486.11 232.38 351.477zm486.746 315.868c0 10.356 5.178 20.712 10.356 25.89 5.179 5.179 15.535 10.357 25.891 10.357h170.88c20.712 0 36.246-15.535 36.246-36.247 0-20.713-15.534-36.247-36.247-36.247H755.373c-20.712 0-36.247 15.534-36.247 36.247zm207.126 98.385h-279.62c-20.713 0-36.247 15.534-36.247 36.247 0 20.712 15.534 36.247 36.247 36.247h279.62c20.713 0 36.247-15.535 36.247-36.247-5.178-20.713-20.712-36.247-36.247-36.247zm0 139.81H651.81c-20.712 0-36.247 15.534-36.247 36.247 0 20.712 15.535 36.247 36.247 36.247h274.442c10.357 0 20.713-5.178 25.891-10.357 5.178-5.178 10.356-15.534 10.356-25.89-5.178-20.713-20.712-36.247-36.247-36.247zm0 0" fill="#fff"></path>
  </svg>
</template>
<script>
export default {
  name: "OrangeIdcard",
  props: {
    fill: {
      default: "#ffffff"
    },
    width: {
      default: '40px'
    },
    height: {
      default: '40px'
    }
  },
};
</script>
