<template>
  <svg
    t="1622695498436"
    class="file-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3004"
  >
    <path
      d="M673.73056 56.32H234.24A101.17632 101.17632 0 0 0 133.12 157.32736v709.3504A101.17632 101.17632 0 0 0 234.24 967.68h560.64A101.17632 101.17632 0 0 0 896 866.67776V289.0752z m149.8112 233.20064H718.3872a48.4096 48.4096 0 0 1-48.37888-48.32768V128.74752z m19.72224 577.15712a48.40448 48.40448 0 0 1-48.37888 48.31744H234.24a48.40448 48.40448 0 0 1-48.37888-48.31744V157.32736a48.40448 48.40448 0 0 1 48.37888-48.32256h383.0272v132.19328a101.17632 101.17632 0 0 0 101.12 101.00736h123.97568V309.248l0.896 0.94208v556.48768z m-160.512-171.93472h-336.384a26.3424 26.3424 0 1 0 0 52.67968h336.384a26.3424 26.3424 0 1 0 0-52.67968z m0-195.91168h-336.384a26.3424 26.3424 0 1 0 0 52.67968h336.384a26.3424 26.3424 0 1 0 0-52.67968z m-336.384-143.232h153.91744a26.3424 26.3424 0 1 0 0-52.67968H346.368a26.3424 26.3424 0 1 0 0 52.67968z"
      p-id="3005"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "FileIcon",
  props: {
    fill: String,
  },
};
</script>
