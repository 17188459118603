<template>
  <el-dialog :title="t('applyCourse.helpYouMatchingTitle')" :visible.sync="_visible" width="500px">
    <div class="choose-tip">{{t('applyCourse.chooseOnForm', {num: courseRoute.length})}}</div>
    <div class="form-wrap" >
      <div 
        :class="['form-item', activeIndex == index && 'active']" 
        v-for="(item, index) in courseRoute" 
        :key="index"
        @click="activeIndex = index"
      >
        <el-radio :label="index" v-model="activeIndex">{{item.route}}</el-radio>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="_visible = false;">
        {{ t("stuManagement.nextStep") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import i18n from "@/locale/index";
export default {
  name: "ChhoseOneClick",
  mixins: [i18n],
  props:{
    faculty: Array,
    subject_area: Array,
    faculty_cdet: Array,
    cdet_crse_name4: Array,
    cdet_crse_name5: Array,
    initCourses: Array,
    visible: Boolean
  },
  data(){
    return {
      activeIndex: 0,
      courseRoute: []
    }
  },
  computed:{
    getLevel(){
      let level = [];
      if(this.faculty && this.faculty.length) level.push('faculty');
      if(this.subject_area && this.subject_area.length) level.push('subject_area');
      if(this.faculty_cdet && this.faculty_cdet.length) level.push('faculty_cdet');
      if(this.cdet_crse_name4 && this.cdet_crse_name4.length) level.push('cdet_crse_name4');
      if(this.cdet_crse_name5 && this.cdet_crse_name5.length) level.push('cdet_crse_name5');

      return level;
    },
    _visible:{
      get(){
        return this.visible
      },
      set(val){
        if(!val) this.$emit('confirm', this.courseRoute.length? this.courseRoute[this.activeIndex]: this.initCourses[0]);
        this.$emit('update:visible',val)
      }
    },
  },
  watch:{
    initCourses:{
      handler(val){
        this.activeIndex = 0;
        let courseRoute = val.map(item=> {
          item.route = this.setInitByMatchCourse(item);
          return item;
        });
        this.courseRoute = courseRoute.map(item=> {
          item.route = item.route.join(' > ')
          return item;
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods:{
    setInitByMatchCourse(val){
      let {key, name} = val;
      if(!name) return '';
      name = name.replace(/_(a|b)$/, '')
      let ret = [];
      let level = this.getLevel;

      let index = level.indexOf(name);
      while(index >= 0){
        let name = level[index];
        if(name == 'faculty'){
          // 如果是顶级
          const item = this[name].find(item => item.key == key);
          if(item) {
            ret.unshift(item.value);
          }
        } else {
          for(let value of this[name]){
            const item = value.find(item=> item.key == key);
            if(item) {
              ret.unshift(item.value);
              key = item.parentId;
              continue;
            }
          }
        }
        index--;
      }
      return ret;
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-tip{
  margin-bottom: 24px;
}
.form-wrap{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 240px;
  overflow-y:auto;
  .form-item{
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 4px 12px;
    margin-bottom: 6px;
    width: 100%;
    &::v-deep{
      .el-radio{
        width: 100%;
        display: flex;
        
        white-space: pre-wrap;
        text-align: left;
      }
    }
    &.active{
      border-color: #ff9700;
      border-color: var(--form-color-primary)
    }
  }
}
</style>