<template>
  <div class="nav-comp" :style="{ maxWidth: lang.indexOf('en') > -1 ? '280px':'210px'} ">
    <ul class="nav-comp-ul" ref="listWrap" @click="handleClickLi">
      <!-- <li
        data-name="studentReg"
        :class="[curActiveName == 'studentReg' && 'active']"
      >
        <span class="require-field">*</span>{{ t("stuManagement.navStudentReg") }}
      </li> -->
      <li
        data-name="personalInfo"
        :class="[curActiveName == 'personalInfo' && 'active']"
      >
        <span :class="['dot', { 'current': curActiveName == 'personalInfo', 'completed': completed.includes('personalInfo'), 'un-comleted': UnCompleted.includes('personalInfo') }]"></span>
        <!-- <span class="require-field">*</span> -->
        {{ t("stuManagement.navPersonalInfo") }}
      </li>
      <li
        v-if="!!stuid()"
        data-name="applicationSch"
        :class="[curActiveName == 'applicationSch' && 'active']"
      >
        <span :class="['dot', { 'current': curActiveName == 'applicationSch', 'completed': completed.includes('applicationSch'), 'un-comleted': UnCompleted.includes('applicationSch') }]"></span>
        <!-- <span class="require-field">*</span> -->
        {{ t("stuManagement.navApplicationSch") }}
      </li>
      <li
        v-if="showBackgroundInfo"
        data-name="backgroundInfo"
        :class="[curActiveName == 'backgroundInfo' && 'active']"
      >
        <span :class="['dot', { 'current': curActiveName == 'backgroundInfo', 'completed': completed.includes('backgroundInfo'), 'un-comleted': UnCompleted.includes('backgroundInfo') }]"></span>
        <!-- <span class="require-field">*</span> -->
        {{ t("stuManagement.navBackgroundInfo") }}
      </li>
      <li
        v-for="(item, index) in navDynamic"
        :key="index"
        :data-name="item.divisionKey"
        :class="[curActiveName == item.divisionKey && 'active']"
      >
        <template v-if="item.divisionKey === 'relativesContact'">
          <span :class="['dot', { 'current': curActiveName == item.divisionKey, 'completed': completed.includes(item.divisionKey) }, UnCompleted.includes(item.divisionKey) && (item.isRequired&&isRequireParentContact? 'un-comleted':'not-start')]"></span>
          <!-- <span v-if="item.isRequired && isRequireParentContact" class="require-field">*</span> -->
        </template>
        <template v-else-if="item.divisionKey === 'fileData'">
          <span :class="['dot', { 'current': curActiveName == item.divisionKey }, fileCompletedStatus(item.isRequired)]"></span>
          <!-- <span v-if="item.isRequired" class="require-field">*</span> -->
        </template>
        <template v-else>
          <span :class="['dot', { 'current': curActiveName == item.divisionKey, 'completed': completed.includes(item.divisionKey) }, UnCompleted.includes(item.divisionKey) && (item.isRequired? 'un-comleted':'not-start')]"></span>
          <!-- <span v-if="item.isRequired" class="require-field">*</span> -->
        </template>
         {{ t(`stuManagement.${item.divisionKey}`) }}
      </li>
    </ul>
    <div v-show="submitTime" style="font-size:14px;color:green;">
      <p>{{ t('common.submitTime') }}</p>
      <p>{{ submitTime }}</p>
    </div>
    <el-button type="success"  class="scholarship-back-student-list" @click="handleBankStudentList" v-if="platformLimitList().includes('get_scholarship')">
      {{t("common.studentList")}}
    </el-button>
    <el-button :loading="submitBtnLoading" type="primary" :class="[{ 'hti-button-color' : formType === 'hti' },{ 'scholarship-submit-form' : platformLimitList().includes('get_scholarship') }]" class="submit-form" @click="handleApplyInfo" v-if="issueInnerAccount() || !hideSubmit">
      {{ formType === 'hti' ? t("stuManagement.commit") : t("stuManagement.commitApp") }}
    </el-button>
    <el-button class="export-btn" @click="$emit('export-form')" style="z-index:2" v-if="!platformLimitList().includes('get_scholarship')">
      <span class="el-icon-document icon"></span>
      <span class="pc" v-if="!isMobile">{{ t("stuManagement.exportForm") }}</span>
    </el-button>
    <el-button class="export-btn" @click="$emit('all-files-downLoad')" v-if="!platformLimitList().includes('get_scholarship')">
      <span class="pc" v-if="!isMobile">{{ t("stuManagement.downloadAllFileNav") }}</span>
    </el-button>
    <slot></slot>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import wx from 'weixin-js-sdk'
export default {
  name: "NavComp",
  mixins: [i18n],
  inject: ['stuid', 'platformType', 'companyId', 'hideSubmitBtn', 'isBmsLogin', "platformLimitList","issueInnerAccount"],
  props: {
    navDynamic: Array, // 根据配置动态获取导航信息
    fileUploadList: Array,
    hideSubmit: Boolean,
    curActiveName: String,
    showBackgroundInfo: Boolean,
    isRequireParentContact: Boolean,
    showAllFilesDownload: Boolean,
    formType:{
      type: String,
      default: 'issue'  // mso,hti,issue
    },
    lang: {
      type: String,
      default: ''
    },
    submitTime: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isMobile: this.$isMobile(),
      submitBtnLoading: false,
    };
  },
  computed: {
    completed() {
      return this.$root.toolState.completed || []
    },
    UnCompleted() {
      return this.$root.toolState.UnCompleted || []
    },
    uploadFiles() {
      return this.$root.toolState.uploadFiles || []
    },
    needUploadFile() {
      let arr = []
      arr = this.fileUploadList && this.fileUploadList.reduce((pre, cur) => {
        pre.push(...cur.list)
        return pre;
      }, []) || []
      const needFiles = arr && arr.reduce((pre, cur) => {
        if (cur.isRequired) {
          pre.push(cur)
        }
        return pre
      }, []) || []
      return needFiles;
    },
    fileCompletedStatus() {
      let needFilesKey = this.needUploadFile.map((item) => item.typeKey);
      const uploadFiles = this.uploadFiles
      const needUploadFile = this.needUploadFile
      return (isRequired) => {
        if (uploadFiles.length < needUploadFile.length) {
          if (isRequired) {
            return 'un-comleted';
          } else {
            return 'not-start';
          }
        } else {
          uploadFiles.map((item) => {
            const index = needFilesKey.findIndex((key) => key === item.issueTypeKey)
            index > -1 && needFilesKey.splice(index, 1);
          })
          if (needFilesKey.length) {
            if (isRequired) {
              return 'un-comleted';
            } else {
              return 'not-start';
            }
          } else {
            return 'completed'
          }
        }
      }
    }
  },
  methods: {
    handleClickLi(e) {
      const dom = e.target;
      const name = dom.dataset.name || "";
      if (!name) return;
      // 手机端
      const left =
        dom.offsetLeft -
        (this.$refs.listWrap.clientWidth - dom.clientWidth) / 2;
      this.$refs.listWrap.scrollTo({ left: left, behavior: "smooth" });
      this.$emit("onClickNavItem", name);
    },
    handleApplyInfo() {
      this.$emit("handleApplyInfo");
    },
    handleBankStudentList(){
      console.log(123)
      wx.miniProgram.navigateBack({delta: 1})
    }
  },
};
</script>
<style lang="scss" scoped>
.require-field {
  color: #ff9700;
  color: var(--form-color-primary);
  margin-right: 4px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
}
.completed {
  background: #00C6A3;
}
.not-start {
  background: #D4D4D4;
}
.un-comleted {
  background: #E74C3C;
}
.current {
  background: #1E88F0;
}
</style>
