<template>
  <div class="owl-mobile-picker-wrap" >
    <div
      @click.stop="showPicker" 
      :class="['owl-npt', !_value && 'empty']" 
    >
      <span>{{showTxt || placeholder}}</span>
      <i class="el-icon-arrow-down arrow" :style="{
        'transform': active?'rotate(180deg)': 'rotate(0)'
      }"></i>
    </div>
    <div ref="date-wrap" :class="['owl-mobile-picker select', active && 'active']" @click.stop="cancel">
      <div class="owl-picker-wrap">
        <div class="tit-wrap">
          <span class="cancel" @click="cancel">{{t('common.cancel')}}</span>
          <span class="tit">{{placeholder || t('stuManagement.pleaseSelect')}}</span>
          <span class="confirm" @click="confirm">{{t('common.confirm')}}</span>
        </div>
        <div class="picker-wrap">
          <div class="top-cover"></div>
          <div class="bottom-cover"></div>
          <div 
            class="pickers single"
            :style="{
              transform: `translate3d(0, ${-1*(translateY+scrollingY)}px, 0)`
            }"
            @touchstart="scrollStart">
            <div 
              v-for="(item, index) of options" 
              :key="index" 
              :class="['pcker', currentValue == item[optionConfig.value] && 'active']"
              :style="{'font-size':item[optionConfig.label] && item[optionConfig.label].length> 50? '13px':
              item[optionConfig.label] && item[optionConfig.label].length> 40? '14px' : '16px'}"
            >{{item[optionConfig.label]}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  model:{
    prop: "value",
    event: "change"
  },
  props: {
    value: [String, Number],
    optionConfig: {
      type: Object,
      require: false,
      defalut: {
        label: "label",
        value: "value"
      }
    },
    options: {
      type: Array,
    },
    placeholder: String
  },
  data(){
    return {
      active: false,
      filter: '',
      currentValue: '',
      translateY: 0,
      scrollingY: 0,
    }
  },
  // watch:{
  //   active(val){
  //     document.body.style.overflow = val? 'hidden': ''
  //   }
  // },
  computed:{
    _value:{
      get(){
        return typeof this.value === "number" ? String(this.value) : this.value
      },
      set(val){
        this.$emit('change',val)
      }
    },
    showTxt(){
      const item = this.options.find(item=>item[this.optionConfig.value] == this.value)
      return item ? item[this.optionConfig.label] : this.value;
    }
  },
  mounted(){
    document.body.appendChild(this.$refs['date-wrap'])
  },
  beforeDestroy(){
    document.body.removeChild(this.$refs['date-wrap'])
  },
  methods: {
    showPicker(){
      this.active = true;
      const index = this.options.findIndex(item=>item[this.optionConfig.value] == this.value)
      this.currentValue = index>= 0 ? this.value: this.options.length ? this.options[0][this.optionConfig.value]: '';
      this.translateY = (index > 0 ? index : 0) * 40 - 110;

    },
    cancel(){
      this.active = false;
    },
    confirm(){
      this.$emit('change',this.currentValue)
    },
    scrollStart(e){
      const startY = e.pageY || (e.touches[0] && e.touches[0].pageY);
      const startTime = Date.now();
      let onScroll = (e) => {
        const scrollingY = e.pageY || (e.touches[0] && e.touches[0].pageY);
        this.scrollingY = startY - scrollingY
      }
      let onScrollStop =  async () => {
        
        this.translateY += this.scrollingY;
        const disTime = (Date.now() - startTime)/1000; // 秒
        const v0 = this.scrollingY/disTime; // 速度
        this.scrollingY = 0;
        
        const index = await this.autoScroll(v0)
        this.currentValue = this.options[index][this.optionConfig.value];
        document.body.removeEventListener('touchmove', onScroll)
        document.body.removeEventListener('touchend', onScrollStop)
        onScroll = null;
        onScrollStop = null;
      }
      document.body.addEventListener('touchmove', onScroll)
      document.body.addEventListener('touchend', onScrollStop)
    },
    // pos 当前位置， v0 初速度
    async autoScroll(v0){
      v0 = v0 /100;
      await new Promise(res=>{
        const acceleration = v0 > 0? -.5 :.5;
        let time = Date.now();
        let frictionSpeed = ()=>{
          const curTime = Date.now();
          const disTime = (curTime - time)/5
          time = curTime; //ms
          v0 = acceleration*disTime+v0;
          if(acceleration<0){
            if(v0 <= 0){
              frictionSpeed = null;
              return res()
            };
          } else {
            if(v0 >= 0) {
              frictionSpeed = null;
              return res()
            };
          }
          this.translateY += v0*disTime;
          requestAnimationFrame(frictionSpeed)
        }
        frictionSpeed();
      })
      let index = Math.round((this.translateY + 110) / 40)
      if(index > this.options.length - 1){
        index = this.options.length - 1;
      }
      if(index < 0) index = 0;
     
      const yTranslate = index * 40 - 110;

      let type = yTranslate > this.translateY? 'add': yTranslate < this.translateY? 'less': 'equal';
      if(type == 'equal') {
        return index;
      }
      const add = (yTranslate - this.translateY) / 10;
      await new Promise(res=>{
        let backCorrect = () => {
          if(type == 'add'){
            if((this.translateY + add) < yTranslate ){
              this.translateY += add
              requestAnimationFrame(backCorrect)
            } else {
              this.translateY = yTranslate;
              res()
              backCorrect = null;
            }
          } else {
            if((this.translateY + add) > yTranslate){
              this.translateY += add
              requestAnimationFrame(backCorrect)
            } else {
              this.translateY = yTranslate;
              res()
              backCorrect = null;
            }
          }
        }
        backCorrect()
      })
      return index;
    },
  }
}
</script>