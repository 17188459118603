<template>
  <div class="stu-accommodation">
    <el-form
      style="padding-top: 12px;"
      :class="['stu-accommodation-form no-border', isDisabled && 'disabled show-form' ]"
      size="small"
      ref="accommodationForm"
      label-position="top"
      :rules="accommodationRules"
      :model="accommodationFormData"
    >
      <div class="sub-title">
        <owl-btn-set
          :updateShow="isDisabled"
          @update="getUpdate"
          v-if="!hideSubmitBtn()"
        />
      </div>
      <div class="sub-option-area form-item">
        <el-row :gutter="10">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('stuManagement.isNeedAccommodation')"
              prop="isNeedAccommodation"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="accommodationFormData.isNeedAccommodation"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs="24" v-if="accommodationFormData.isNeedAccommodation">
            <el-form-item
              :label="t('stuManagement.accommodationType')"
              prop="accommodationType"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="accommodationFormData.accommodationType"
                :options="chooseStayOption"
                :option-config="{ label: 'name', value: 'id' }"
                @change="accommodationTypeChange"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
          <template v-if="accommodationFormData.accommodationType === 1">
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('stuManagement.isAccommodationSmoke')"
                prop="isAccommodationSmoke"
                :rules="rules.needFieldRadio"
              >
                <owl-radio
                  :disabled="isDisabled"
                  v-model="accommodationFormData.isAccommodationSmoke"
                  :options="yesOrNoOption"
                  :option-config="{ label: 'name', value: 'id' }"
                >
                </owl-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('stuManagement.isAccommodationPet')"
                prop="isAccommodationPet"
                :rules="rules.needFieldRadio"
              >
                <owl-radio
                  :disabled="isDisabled"
                  v-model="accommodationFormData.isAccommodationPet"
                  :options="yesOrNoOption"
                  :option-config="{ label: 'name', value: 'id' }"
                >
                </owl-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('stuManagement.isAccommodationChildren')"
                prop="isAccommodationChildren"
                :rules="rules.needFieldRadio"
              >
                <owl-radio
                  :disabled="isDisabled"
                  v-model="accommodationFormData.isAccommodationChildren"
                  :options="yesOrNoOption"
                  :option-config="{ label: 'name', value: 'id' }"
                >
                </owl-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('stuManagement.isAccommodationFood')"
                prop="isAccommodationFood"
                :rules="rules.needFieldRadio"
              >
                <owl-radio
                  :disabled="isDisabled"
                  v-model="accommodationFormData.isAccommodationFood"
                  :options="yesOrNoOption"
                  :option-config="{ label: 'name', value: 'id' }"
                >
                </owl-radio>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :xs="24"
              v-if="accommodationFormData.isAccommodationFood === 1"
            >
              <el-form-item
                :label="t('stuManagement.accommodationFoodNote')"
                prop="accommodationFoodNote"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  v-model="accommodationFormData.accommodationFoodNote"
                  :disabled="isDisabled"
                  :rows="3"
                  type="textarea"
                  :maxlength="100"
                ></owl-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :xs="24">
              <el-form-item
                :label="t('stuManagement.isAccommodationOther')"
                prop="isAccommodationOther"
                :rules="rules.needFieldRadio"
              >
                <owl-radio
                  :disabled="isDisabled"
                  v-model="accommodationFormData.isAccommodationOther"
                  :options="yesOrNoOption"
                  :option-config="{ label: 'name', value: 'id' }"
                >
                </owl-radio>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              :xs="24"
              v-if="accommodationFormData.isAccommodationOther === 1"
            >
              <el-form-item
                :label="t('stuManagement.accommodationOtherNote')"
                prop="accommodationOtherNote"
                :rules="rules.needFiledRule"
              >
                <owl-input
                  v-model="accommodationFormData.accommodationOtherNote"
                  :disabled="isDisabled"
                  :rows="3"
                  type="textarea"
                  :maxlength="100"
                ></owl-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </div>
      <div class="sub-option-area form-item">
        <el-row :gutter="10">
          <el-col :span="24" :xs="24">
            <el-form-item
              :label="t('stuManagement.isNeedPickUp')"
              prop="isNeedPickUp"
              :rules="rules.needFieldRadio"
            >
              <owl-radio
                :disabled="isDisabled"
                v-model="accommodationFormData.isNeedPickUp"
                :options="isRequiredOption"
                :option-config="{ label: 'name', value: 'id' }"
              >
              </owl-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <owl-btn-save v-show="!isDisabled" @save="handleSave" show-delete @delete="handleCancel"/>
    </el-form>
  </div>
</template>
<script>
import i18n from "@/locale/index";
import mixin from "@/utils/mixin";
import OwlRadio from "/packages/components/OwlRadio.vue";
import OwlInput from "/packages/components/OwlInput.vue";
import OwlBtnSave from "/packages/components/OwlBtnSave.vue";
import OwlBtnSet from "/packages/components/OwlBtnSet.vue";

import { saveOrUpdateStudentAccommData } from "@/api/common.js";
export default {
  name: "StudentAccommodation",
  mixins: [mixin, i18n],
  inject: ["stuid", "hideSubmitBtn","platformType"],
  components: { OwlRadio, OwlInput, OwlBtnSave, OwlBtnSet },
  props: {
    accommodationDto: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    isDisabled(){
      return this.hideSubmitBtn() || !this.isFormEditing;
    }
  },
  data() {
    return {
      fkPlatformType:this.platformType(),
      isRequiredOption: [],
      // 1=Homestay寄宿家庭,2=University Accommodation 學校宿舍，3=Off Campus Accommodation 校外宿舍
      chooseStayOption: [],
      yesOrNoOption: [],
      accommodationFormData: {
        isNeedAccommodation: 0, //  你是否需要安排住宿？
        isNeedPickUp: 0, // 你是否需要安排接机？
        accommodationType: "", // 如需要学校安排住宿请提供你的选择
        isAccommodationSmoke: 0, // 你是否抽烟
        isAccommodationPet: 0, // 你是否能接受有宠物的家庭？
        isAccommodationChildren: 0, // 你是否能接受有小孩的家庭？
        isAccommodationFood: 0, // 你对食物是否有特殊要求
        accommodationFoodNote: "", // 食物特殊要求详细说明
        isAccommodationOther: 0, // 你是否有其他要求？
        accommodationOtherNote: "", // 其他要求说明
        id: this.stuid(), // 学生id
      },
      backup:'',
      accommodationRules: {},
      isFormEditing: false,  // 是否编辑
    };
  },
  mounted() {
    this.isRequiredOption = [
      { id: 0, name: this.t("common.no") },
      { id: 1, name: this.t("common.yes") },
      // { id: 2, name: this.t("common.undecided") },
    ];
    // 1=Homestay寄宿家庭,2=University Accommodation 學校宿舍，3=Off Campus Accommodation 校外宿舍
    this.chooseStayOption = [
      { id: 1, name: this.t("stuManagement.homestay") },
      { id: 2, name: this.t("stuManagement.universityAccmmodation") },
      { id: 3, name: this.t("stuManagement.offCampusAccmmodation") },
    ];
    this.yesOrNoOption = [
      { id: 0, name: this.t("common.no") },
      { id: 1, name: this.t("common.yes") },
    ];
  },
  methods: {
    getUpdate() {
      this.isFormEditing = true;
      this.backup = JSON.stringify(this.accommodationFormData)
      this.onFormStatusChanged();
    },
    // 初始
    setInitData(showData) {
      for (let key in showData) {
        switch (key){
          case 'accommodationType':
          case 'accommodationFoodNote':
          case 'accommodationOtherNote':{
            this.accommodationFormData[key] = showData[key];
            break;
          }
          default: {
            this.accommodationFormData[key] = showData[key] ? 1: 0;
            break;
          }
        }
      }
      this.accommodationFormData.id = this.stuid()
      this.isFormEditing = false;
    },
    accommodationTypeChange() {
      // 1 寄宿 2学校宿舍  3校外宿舍
      this.accommodationFormData.isAccommodationSmoke = 0;
      this.accommodationFormData.isAccommodationPet = 0;
      this.accommodationFormData.isAccommodationChildren = 0;
      this.accommodationFormData.isAccommodationFood = 0;
      this.accommodationFormData.accommodationFoodNote = '';
      this.accommodationFormData.isAccommodationOther = 0;
      this.accommodationFormData.accommodationOtherNote = '';
    },
    async checkFormStatus() {
      const isEdit = !this.isFormEditing
      if(!this.required) return {isEdit, validate: true, error: this.t('validate.arrangements')};
      let validate = true;
      try {
        await this.$refs.accommodationForm.validate()
      } catch {
        validate = false;
      }
      return {
        isEdit,
        validate,
        error: this.t('validate.arrangements')
      }
    },
    // 保存
    handleSave() {
      saveOrUpdateStudentAccommData(this.accommodationFormData)
        .then((res) => {
          if (res.data.code === "1003") {
            this.$msge({
              message: this.t("common.saveSucc"),
              type: "success",
            });
            this.isFormEditing = false;
            this.onFormStatusChanged();
            this.addCompletedStatus()
          } else {
            this.$msge({
              message: this.t("common.saveFail"),
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$msge({
            message: this.t("common.saveFail"),
            type: "error",
          });
        });
    },
    // 自动保存正在编辑的
    async autoSave(){
      if(!this.isFormEditing) return;
      await this.handleSave()
    },
    // 取消
    handleCancel(){
      if(this.backup) this.accommodationFormData = JSON.parse(this.backup);
      this.isFormEditing = false;
      this.onFormStatusChanged();
    },
    // 当表单状态改变
    onFormStatusChanged(){
      this.$emit('on-porccess-changed', this.isDisabled? 100: 0)
    }
  },
};
</script>