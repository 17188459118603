export default {
  stuManagement: {
    studentReg: "Student Registration",
    nameZh: "Chinese Name or Full Name",
    lastName: "Surname",
    firstName: "First Name",
    gender: "Gender",
    female: "Female",
    male: "Male",
    dateBirth: "Date of Birth",
    phoneNumber: "Contact Number",
    phoneRegion: "Area Code",
    email: "Email Address",
    emailPassword: "Email Password",
    country: "Country or Region of residence",
    state: "Province",
    city: "City",
    region: "District",
    postcode: "Postal Code",
    address: "Contact Address",
    alternativeAddr: "Alternative Contact Address",
    fillApply: "Save and fill out the admission application",
    personalInfo: "Personal Information",
    title: "Title",
    birthday: "Birthday",
    surname: "Surname/Family Name",
    givenName: "Given/First Name",
    nationality: "Country of citizenship",
    ancestry: "Ethnic origin",
    firstLanguage: "First Language",
    birthCountry: "Country or Region of Birth",
    birthCity: "Birth City",
    isBornLive: "Have you lived in this country or region since birth",
    liveCountry: "Country or Region of residence",
    moveToCountryTime: "when did you move to your country or region of permanent residence?",
    getPermanentResidentTime: "When did you get permanent residence of this country or region?",
    passportInfo: "Passport Information",
    isHasPassport: "Do you have a passport?",
    issueCountry: "Countrty or Region of Issue",
    passportIssueDate: "Date of Issued",
    passportIssueExpried: "Date of Expired",
    passportNum: "Passport No.",
    contactPerson: "Contact Person",
    contactNotice: "(It is suggested to provide the mobile phone number of the consultant teacher so that the system can push the progress of the student case.) ",
    atLeastOne: "At least one）",
    backgroundInfo: "Further Information",
    destCtry: "Destination Country or Region",
    deleteDestCtry: "Delete Destination Country or Region",
    isStudiesInCtry: "Have you ever studied in the country or region of application?",
    updatedVisa: "Please provide the latest visa",
    visaIssueDate: "Visa Issuance Date",
    visaType: "Visa Type",
    visaExpiryDate: "Visa Expiry Date",
    lengthStudy: "Semester period (number of whole months)",
    addBackgroundInfo: "Add background information",
    isHasReligion: "Are there any special religious beliefs?",
    explainReligion: "Please explain religious belief",
    isRefusedRegion: "Whether the visa has been rejected by any country or region",
    fullDetails: "Please provide details",
    specialNeed: "Disability or special needs",
    listSeparately: "Please list them separately",
    isEnglishFirLang: "Is English the first language?",
    isStudiedInstruction: "Have you taken a high school or above course with English as the language of instruction?",
    expectedPayWay: "Who will pay the fee?",
    expectedPayWayTips: "(I or my parents funded are all selected fees)",
    selfFund: "Self-funded",
    otherFund: "Other",
    whatIsDestinationCountry: "What is your destination country/region?",
    applicationSch: "University Application",
    addApplicationSch: "New Course",
    course: "Course",
    deleteCourse: "Delete Course",
    school: "Institution",
    courseLevel: "Course Level",
    courseCountry: "Country or Region",
    openingTime: "Starting Date",
    fkAreaRegion: 'Region',
    educationType: "Type of Education Background",
    educationTypeTips: 'Please Select Type of Education Background at first',
    institution: "Institution",
    institutionAnalysis: "Tips: Intelligent identification of the school",
    copy: 'Copy',
    institutionAddress: "School address",
    otherInstitution: "Other Institution",
    instructedInEng: "Using English as the language of teaching and testing?",
    durationSch: "Duration",
    isComplated: "Complete the study or not?",
    isWillComplated: "Will it be completed in future?",
    highSchool: "High School",
    higherEducation: 'Higher Education',
    university: "University",
    delEduBackground: "Delete Education Background",
    eduBackground: "Educational Background ",
    education: 'Educational Background',
    institutionNum: "Institution Number(The smaller number, the more recent)",
    addEduBackground: "Add educational background",
    eduBackgroundFile: "Upload educational background information",
    diploma: "Diploma",
    associatesDegree: "Associate Degree",
    bachelor: "Bachelor",
    master: "Master",
    preMaster: "Pre Master",
    phD: "PhD",
    foundation: "Foundation",
    class: "Class",
    scoreTypeFour: "out of 4 points",
    scoreTypeFourToTwo: "out of 4.2, retain two decimal places.",
    scoreTypeFourToFive: " out of 4.5, retain two decimal places.",
    scoreTypeFive: "out of 5 points",
    scoreTypeSeven: "out of 7 points",
    scoreTypeNine: "out of 9, retain two decimal places.",
    percentage: "Percentage Percentage",
    grading: "Grading Grading",
    classFirst: "1st",
    classTwoToOne: "2:1",
    classTwoToTwo: "2:2",
    classThird: "Third Class",
    classOrdnary: "Ordnary",
    twoDecimals: "Can keep two decimal places",
    eduBackgroundNumMsg: 'Education Background Number Recurrences: {num}',
    edubgmNumCount: 'You can only save up to three degrees',
    inputSubjectScores: 'Input subject scores',
    checkSubjectScores: 'check subject scores',
    moreEmailTips:' (Please separate multiple email addresses with (;+space))',
    shareLink:'share link',
    // 語言成績
    examination: "Language examination results",
    language: 'Language examination results',
    hasExamination: "Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    examType: "Exam Type",
    toefl: "TOEFL Ibt TOEFL Test",
    ielts: "IELTS",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    pte: "PTE",
    builtInTest: "School Internal Test",
    listening: "Listening",
    reading: "Reading",
    writing: "Writing",
    speaking: "Speaking",
    overall: "Total Score",
    registrationNo: "Check account number",
    testCenterNum: "Test Center Number",
    testDate: "Test Date",
    testTakerId: "Candidate Number",
    awardingCtryRegion: "Certification Country or Region",
    ifRetake: "Do you want to retake the exam",
    isForUKVI: "Is it for UKVI IELTS?",
    examUserName: "Exam User Name",
    examUserPwd: "Login Password",
    score: "Score",
    deleteExamType: "Delete Exam Type",
    major: "Major",
    qualification: "Education",
    gradeCategory: "Grade Category",
    scoreValue: "Total score/Average score/Percentage system",
    inEngAndExam: "English as the language of teaching and examination",
    inEngNotExam: "English is the language of instruction but not including exams",
    inschoolTime: "At school",
    profQual: "Professional Qualification",
    qualifications: 'Professional Qualification',
    addProfQual: "Add professional qualification",
    qualName: "Qualification Name",
    qualGroup: "Certification Agency",
    fkStudentAppCtry: "Certified Country or Region",
    qualGrade: "Grading level (if applicable)",
    qualStartDate: "Qualification Course Start Date",
    qualGetDate: "Qualification Date",
    qualGetStatus: "Professional Qualification Status",
    inProgress: "In Progress",
    obtained: "Obtained",
    deleteProfQual: "Delete Professional Qualification",
    referee: "Refereer",
    recommend: 'Refereer',
    addReferee: "Add referrer",
    instituCompany: "School/Company",
    position: "Position",
    contactAddress1: "Contact Address One",
    contactAddress2: "Contact Address Two",
    deleteReferee: "Delete Referrer",
    mainReferee: '(Main Refereer)',
    mainRefereeText: 'Set as Main Refereer',
    refereeNum: 'Refereer Number',
    refereeNumMsg: 'Referee Number Recurrences: {num}',
    refereeCountry: "Country or Region of Institution/Company",
    ofLetter: '\'s letter of recommendation',
    recommendationLetter: 'Letter of recommendation',
    inputLetterName: 'Please input name of recommendation',
    uploadLetter: "Upload recommendation",
    letterName: "recommendation",
    letterMatchCourse: 'Recommended courses',
    updateLetterName: 'Modify name of letter',
    specifyCourses: 'Specify courses',
    specifyCoursesTitle: 'Specify a course for using the letter of recommendation',
    specifyCoursesTip: '*If the recommendation letter is applicable to all courses, it does not need to be set. It is applicable to all courses by default',
    beCurrent: 'Universal',
    saveSetting: 'Save settings',
    
    experience: "Work Experience",
    workExperience: 'Work Experience',
    addWorkExperience: "Add work experience",
    workTime: "On the job",
    workStartTime: "Start date of Employment",
    workEndTime: "End date of Employment",
    duties: "Duties",
    countryOranisation: "Country or Region of Organisationd",
    stateOranisation: "Province of Organisationd",
    cityOranisation: "City of Organisation",
    companyName: "Name of Organisation",
    isOnDuty: "Are you currently employed in this position?",
    workMode: "Full-time or Part-Time",
    fullTime: "Full Time",
    partTime: "Part-Time",
    recentWork: "Most recent work",
    workExperienceNum: 'Work Experience Number(The smaller number, the more recent)',
    workExperienceNumMsg: 'Work Experience Number Recurrences: {num}',
    
    clickUpload: "Upload",
    clickUploadAgain: "Upload Again",
    stuInfoUpload: "Student Information Upload",
    fileData: 'Student Information Upload',
    uploadTip: "Below 2m | format: PDF",
    uploadLimit: "The uploaded file can only be in PDF format!",
    uploadSize: "The uploaded file cannot exceed {limit}M!",
    uploadDesc: "Upload description:",
    file: "File",
    downloadAllFile: "Package and download all files",
    downloadAllFileNav:"Package and download all files",
    fileIdentification: "Upload data identification information",
    fileIdentificationForIELTS: "Upload identification of IELTS",
    fileIdentificationForHKDSE: "Upload identification of HKDSE",
    fileIdentificationForReferee: "upload identification of recommendation",
    download: "DownLoad",
    downloadTemplate: "Download template",
    isNeedAccommodation: "Do you need to arrange accommodation?",
    isNeedPickUp: "Do you need to arrange pick-up?",
    accommodationType: "If you need the school to arrange accommodation, please provide your choice",
    homestay: "Homestay",
    universityAccmmodation: "University Accommodation",
    offCampusAccmmodation: "Off Campus Accommodation",
    FollowUpArrangements: "Follow-up Arrangements",
    arrangements: 'Follow-up Arrangements',
    isAccommodationSmoke: "Do you smoke",
    isAccommodationPet: "Do you want to live in a home with pets ?",
    isAccommodationChildren: "Do you want to live in a home with children ?",
    isAccommodationFood: "Do you need any special food(eg vegetarian) ?",
    accommodationFoodNote: "Details",
    isAccommodationOther: "Do you have other requirements ?",
    accommodationOtherNote: "Other requirements",
    fatherLastName: "Father's Last Name",
    fatherFirstName: "Father's First Name",
    fatherEmail: "Father's Email Address",
    fatherPhoneNumber: "Father's Contact Number",
    monterLastName: "Mother's Last Name",
    monterFirstName: "Mother's First Name",
    monterEmail: "Mother's email address",
    monterPhoneNumber: "Mother's Contact Number",
    parentContactWay: "Parent Contact Way",
    relativesContact: 'Parent Contact Way',
    // suitAge18: "Applicable to applying for group courses or students under the age of 18.",
    jumpOver: "If not applicable, please go directly to the next step.",
    commitApp: "Submit application",
    navStudentReg: "Student registration,",
    navPersonalInfo: "Personal information",
    navBackgroundInfo: "Background information",
    navApplicationSch: "College application",
    applyCofirm: "Apply for Confirmation",
    applyStatement: "The information table is declared as follows:",
    applyTip: "Please check the box below to make sure you understand the complete application process",
    applyBox1: "I have uploaded all supporting documents for application in the system",
    applyBox2: "All the student application documents I provided are true",
    applyBox3: "I agree that the MSO platform can use the application form materials and relevant application documents (including but not limit to Personal statement,  transcript,  Graduation certificate,  Degree certificate,   English test certificate,  Reference letter, Internship certificate, Study certificate, VISA, Passport etc) to apply for admission on my behalf. ",
    applyName: "Applicant's name:",
    toBack: "Back",
    hadConfirm: "Confirm",
    studentInformation: "Student information",
    plsUploadFile: "Please upload the file",
    applicationMsg: "Please complete the previous content selection first!",
    addCourseAgain: "New Course",
    faculty: "Faculty",
    zone: 'Campus',
    exportForm: 'Export form as Word'
  },
  // 父母的联系方式
  parentContact: {
    fatherLastName: "Father's Last Name",
    fatherFirstName: "Father's First Name",
    fatherEmail: "Father's Email Address",
    fatherPhoneNumber: "Father's Contact Number",
    monterLastName: "Mother's Last Name",
    monterFirstName: "Mother's First Name",
    monterEmail: "Mother's email address",
    monterPhoneNumber: "Mother's Contact Number",
    parentContactWay: "Parent Contact Way",
    suitAge18: "Applicable to applying for group courses or students under the age of 18, or for some schools applying online who require emergency contact information, please provide information on one or both parents.",
    jumpOver: "If not applicable, please go directly to the next step.",
    fkStudentAppCountryIdParent: "Country or Region",
    fkAreaStateIdParent: "Province",
    fkAreaCityIdParent: "City",
    parentContactAddress1: "Contact Address",
    parentContactAddress2: "Alternative Contact Address",
    parentContactPostcode: "Postal Code",
  },
  // 个人信息
  personInfo: {
    personalInfo: "Personal Information",
    gender: "Gender",
    gender0: "Female",
    gender1: "Male",
    title: "Title",
    title1: "Mr.",
    title2: "Ms",
    title3: "Miss",
    title4: "Mrs",
    birthday: "Birthday",
    lastName: "Surname",
    firstName: "First Name",
    fkStudentAppCountryId: "Student Nationality",
    fkStudentAppAncestryId: "Student Race",
    fkStudentAppMotherLanguageId: "Student's native language",
    fkStudentAppCountryIdBirth: "Country or Region of Birth",
    cityBirth: "Birth City",
    isLivedSinceBirth: "Have you lived in this country or region since birth?",
    isLivedSinceBirth0: "No",
    isLivedSinceBirth1: "Yes",
    fkStudentAppCountryIdLive: "Current country/region",
    stuPhoneNumber: 'Student Contact Number',

    migrationTime: "Migration Time",
    stayingRightTime: "Take the right of abode time",
    isHavePassport: "Do you have a passport",
    isHavePassport0: "No",
    isHavePassport1: "Yes",
    fkStudentAppCountryIdPassport: "Issuing Country or Region",
    passportIssueDate: "Issue Date",
    passportIssueExpried: "Valid until",
    passportNumber: "Passport Number",
    followUpPhoneCode: "Area Code",
    followUpPhoneNumber1: "Follow up the contact's phone number 1",
    followUpPhoneCode1: "Area Code 1",
    followUpEmail1: "Follow Up Email 1",
    followUpPhoneNumber2: "Follow up the contact's phone number 2",
    followUpPhoneCode2: "Area Code 2",
    followUpEmail2: "Follow Up Email 2",
    followUpPhoneNumber3: "Follow up the contact's phone number 3",
    followUpPhoneCode3: "Area Code 3",
    followUpEmail3: "Follow Up Email 3",
    passportInfo: "Passport Information",
    contactPerson: "Contact Person in Charge",
    contactNotice: "(It is suggested to provide the mobile phone number of the consultant teacher so that the system can push the progress of the student case.)",
    atLeastOne: "(Fill in at least one)",
  },
  registerInfo: {
    nameZh: "Chinese Name or Full Name",
    lastName: "Surname",
    firstName: "First Name",
    gender: "Gender",
    gender0: "Female",
    gender1: "Male",
    birthday: "Date of Birth",
    phoneNumber: "Telephone Number for Correspondence",
    phoneRegion: "Area Code",
    email: "Email",
    emailPassword: "Email Password",
    fkStudentAppCountryIdFrom: "Country or Region of residence",
    fkAreaStateIdFrom: "Province",
    fkAreaCityIdFrom: "City",
    fkAreaCotuDovosopmIdFrom: "Area",
    contactPostcode: "Postal Code",
    contactAddress1: "Contact Address",
    contactAddress2: "Alternative contact address",
  },
  backgroundInfo: {
    backgroundInfo: "Further Information",
    updatedVisa: "Please provide the latest visa",
    deleteDestCtry: "Delete Destination Country or Region",
    whoPayTips: "I or parents choose to pay at their own expense.",
    fkAreaCountryId: "Destination Country or Region",
    isStudyInThisCountry: "Have you ever studied in the destination country?",
    isStudyInThisCountry0: "Yes",
    isStudyInThisCountry1: "No",
    visaIssueDate: "Visa Issuance Date",
    visaType: "Visa Type",
    visaExpiryDate: "Visa Expiry Date",
    studyLength: "Length of study (in whole months)",
    isHaveReligion: "Willing to explain your religion?",
    isHaveReligion0: "No",
    isHaveReligion1: "Yes",
    religionNote: "Religious Belief Description",
    isVisaRefused: "Have you ever been refused a visa application to any country or Region?",
    isVisaRefused0: "No",
    isVisaRefused1: "Yes",
    visaRefusedNote: "Visa Refused Note",
    isDisability: "Disability/Special Needs",
    isDisability0: "No",
    isDisability1: "Yes",
    disabilityNote: "Description of Disability Needs",
    isEnglishMotherLanguage: "Is English your first language",
    isEnglishMotherLanguage0: "No",
    isEnglishMotherLanguage1: "Yes",
    isStudyInEnglish: "Have you studied at secondary or tertiary levels with English as the language of instruction?",
    isStudyInEnglish0: "No",
    isStudyInEnglish1: "Yes",
    whoPay: "Who is expected to pay your fees?",
    whoPay0: "Self-fund",
    whoPay1: "Other",
    payNote: "Payment Instructions",
  },
  languageInfo: {
    examination: "Language examination results",
    hasExamination: "Is there a standardized test for studying abroad (e.g. IELTS/TOEFL)?",
    deleteExamType: "Delete Exam Type",
    addExamType: "Add Exam Type",
    toefl: "TOEFL Ibt",
    ielts: "IELTS",
    gmat: "GMAT",
    gre: "GRE",
    sat: "SAT",
    act: "ACT",
    hkdse: 'HKDSE',
    duolingo: "Duolingo",
    pte: "PTE",
    builtInTest: "School Internal Test",
    examType: "Exam Type",
    examListen: "Listening",
    examRead: "Reading",
    examSpeak: "Speaking",
    examWrite: "Writing",
    examTotal: "Overall",
    examScore: 'Score',
    registrationNo: 'Registration No.',
    examinationCheckAccount: "Examination Check Account",
    examinationCheckPassword: "Examination Check Password",
    examinationDate: "Test Date",
    examinationLogin: "Login",
    examinationPin: "Pin",
    examinationNumber: "Candidate id number",
    testTakerId: 'Test Taker ID',
    fkStudentAppCountryId: "Awarding Country or Region",
    testCenterNumber: "Test Center Number",
    ukviNumber: "UKVI Number",
    isUkviIelts: "It is UKVI IELTS?",
    isUkviIelts0: "No",
    isUkviIelts1: "Yes",
    isReexamination: "If Retake?",
    isReexamination0: "No",
    isReexamination1: "Yes",
    reexaminationDate: "Reexamination Date",
  },
  qualificationInfo: {
    qualificationInfo: "Professional Qualification",
    addQualf: "Add Professional Qualification",
    deleteQualf: "Delete Professional Qualification",
    qualificationName: "Qualification Name",
    qualificationGroup: "Certification Agency",
    fkStudentAppCountryId: "Country or Region of Qualification Certification",
    qualificationGrade: "Grading level (if applicable)",
    qualificationStartDate: "Qualification Course Start Date",
    qualificationGetDate: "Qualification Date/Estimated Qualification Date",
    qualificationGetStatus: "Professional Qualification Status",
    qualificationGetStatus0: "In Progress",
    qualificationGetStatus1: "Acquired",
  },
  common: {
    saveTemporal: "Information to be completed, temporary ",
    save: "Save",
    cancel: "Cancel",
    search: "Search",
    reset: "Reset",
    opera: "Operation",
    download: "Download",
    yes: "Yes",
    no: "No",
    has: "Yes",
    hasNot: "No",
    confirm: "OK",
    want: "Think Yes",
    wantN: "I don't want to No",
    isNot: "Not",
    startDate: "Start Date (yyyy-mm-dd)",
    endDate: "End Date (yyyy-mm-dd)",
    tip: "Tips",
    del: "Delete",
    undecided: "Undecided",
    saveSucc: "Save successfully",
    saveFail: "Save failed",
    submitFail: "Submit failed",
    until: "to",
    update: "Edit",
    deleteSucc: "Delete successfully",
    deleteFail: "Delete failed",
    updateSucc: "Edited successfully",
    updateFail: "Edit failed",
    delete: "Delete",
    systemErr: "System error!",
    filename: 'File name',
    filetype: 'File Category',
    noUpload: 'Not uploaded',
    fileUpload: 'Upload',
    noSelectTips: 'No matching selection, please input the name',
    // 智能识别
    intelligentIdentificationStudentInfo: 'intelligent identification information',
    intelligentIdentificationTips: 'Through OCR & NLP recognition technology, key information in images and documents can be extracted to simplify data entry',
    uploadPassportNational: 'Upload passport pictures from other regions',
    uploadPassport: 'Upload Mainland China passport picture',
    uploadBackground: 'Upload background information',
    continuetofill: 'Continue to fill',
    stuName: 'Name(Chinese)',
    stuNameEn: 'Name(English)',
    stuGender: 'gender',
    stuNation: 'Country / Region',
    stuBirth: 'Birthdate',
    stuAddr: 'Address',
    stuIdcard: 'Identity Card',
    stuBirthPlace: 'Birthplace',
    stuPassport: 'Passport',
    stuEduExp: 'Educational experience',
    stuWorkExp: 'Work experience',
    notUpload: 'not uploaded ',
    branchSubjectType: 'Please select a subject type',
    branchSubjectValue: 'Please select or enter a score',
    followRemark: 'Follow up remarks',
    saveAndSubmit: 'Save and Submit the application',
    saveAndSubmitTips: 'The country/region is currently modified, please click the Save or Save button ',
    submitTime: 'Submit Time',
    studentList:'student list',
    checkRemark:'View modification notes',
  },
  validate: {
    currentHasEdit: 'There is a form being edited. Please complete adding or editing, click Save, and then perform other operations.',
    noFormDataTip: 'Please complete the information',
    cancelEdit: 'Are you sure cancel the edit status?',
    requiredField: "Required Field",
    emailValid: "Please enter the correct email address",
    birthdayValid: "Please check the date of birth",
    issueDateValid: "Please check the date, the issue date {issueDate} cannot be after the expiration date {expriDate}",
    limit4: "Up to five",
    checkDestCtryNum: "Select at least 1 destination country or region!",
    reDestCtryName: "You have added this country or region!",
    checkDate: "Please check {name}, the start time cannot be after the end time",
    delMsg: "Are you sure you want to delete this {msg}?",
    delMsgapp: "Are you sure you want to delete this Student Registration?",
    delMsgBgInfo: "Are you sure you want to delete this Destination Country or Region?",
    delMsgEduInfo: "Are you sure you want to delete this Educational Background?",
    delMsgFile: "Are you sure you want to delete this File?",
    delMsgLang: "Are you sure you want to delete this Examination results?",
    delMsgQualInfo: "Are you sure you want to delete this Professional Qualification?",
    delMsgReferInfo: "Are you sure you want to delete this Refereer?",
    delMsgReferLetterInfo: "Are you sure you want to delete this recommendation letter?",
    delMsgWorkInfo: "Are you sure you want to delete this Work Experience?",

    checkIeltsRegstNo: "The length of the query account number is 16 to 19",
    checkIeltsTestCenterNo: "The length of the test center number is 5 to 7 digits",
    checkIeltsCandNo: "The length of the candidate number is 5 to 20 digits",
    checkToeflRegstNo: "The length of the query account is 16 to 19 digits",
    checkToeflTestCenterNo: "The length of the test center number is 9 to 12 digits",
    checkPteRegstNo: "The length of the query account number is 9 to 13",
    checkPteTestCenterNo: "The length of the test center number is 5 to 9 digits",
    checkPteTakerID: "The length of the candidate number is 9 to 12 digits",
    qualDateValid: "Please check the date, the start date of the qualification course {startDate} cannot be after the qualification date {getDate}",
    checkSubmit: "Please confirm that all forms have been completed and submitted",
    checkPasswordTime: "The passport is about to expired, please provide a valid passport. ",
    checkeQualificationGetStatus: "Professional qualifications have been obtained, please check the qualification date.",
    onlyEnlishInput: "Only input English or Pinyin",
    onlyNumberOrCharInput: "Only input numbers or characters",
    studentReg: "Student Registration module information is not complete, please confirm!",
    personalInfo: "Personal Information module information is incomplete, please confirm!",
    backgroundInfo: "Further Information module information is incomplete, please confirm!",
    applicationSch: "University Application module information is incomplete, please confirm!",
    education: "Type of Education Background module information is incomplete, please confirm!",
    workExperience: "Work Experience module information is incomplete, please confirm!",
    language: "Examination results module information is incomplete, please confirm!",
    qualifications: "Professional Qualification module information is incomplete, please confirm!",
    recommend: "Refereer's module information is incomplete, please confirm!",
    arrangements: "Follow-up Arrangements module information is incomplete, please confirm!",
    relativesContact: "Parent Contact Way module information is incomplete, please confirm!",
    fileData: "Student Information Upload module information is incomplete, please confirm!",
    checkExamDate: 'Please check the Test Date',
    onlyEnlishAddres: 'Please change the Chinese address to English',

    // 個人信息
    checkRgstAddress: 'contact address exceeds 45 characters limit, please do not try to input the whole address, and input part of address in alternative contact address.',
    // 教育背景
    gradeCategoryGpa4: 'GPA(out of 4 points, retain two decimal places)',
    gradeCategoryGpa4d2: 'GPA(out of 4.2, retain two decimal places.)',
    gradeCategoryGpa4d3: 'GPA(out of 4.3, retain two decimal places.)',
    gradeCategoryGpa4d5: 'GPA(out of 4.5, retain two decimal places.)',
    gradeCategoryGpa5: 'GPA(out of 5 points, retain two decimal places)',
    gradeCategoryGpa7: 'GPA(out of 7 points, retain two decimal places)',
    gradeCategoryGpa9: 'GPA(out of 9, retain two decimal places.)',
    gradeCategoryGpa10: 'GPA(out of 10, retain two decimal places.)',
    gradeCategoryGpa12: 'GPA(out of 12, retain two decimal places.)',
    gradeCategoryGpa: 'GPA(retain two decimal places.)',
    gradeCategoryGre: 'GRE(retain one decimal places.)',
    gradeCategoryGmat: 'GMAT(retain one decimal places.)',
    otherRequirements: 'other requirements(retain two decimal places.)',
    iBDiploma: 'IB Diploma(Two-digit Integer, out of 45)',
    advancedPlacement: 'Advanced Placement(Integer range of 1~5)',
    gaoKao: 'GaoKao(China) (Integer, out of 100)',
    hKDSEHongKong: 'HKDSE (Hong Kong) (Two-digit Integer)',
    ontarioSSD: 'Ontario Secondary School Diploma (Integer, out of 100)',
    sat1: 'SAT1 (USA) (Integer range of 400~1600)',
    sat2: 'SAT2 (USA) (Integer range of 400~1600)',
    act: 'ACT (USA) (Two-digit Integer)',
    gradeCategoryPercentage: 'GPA with percentage(Integer range of 40~100, retain two decimal places)',
    checkUKVI: 'The range of UKVI Number\'s length is 19~26',
    pleaseInputChinaPost: 'China\'s postal code is 6 digits',
    pleaseInputVietnamPost: 'Vietnam\'s postal code is 5 digits',
    contactPostcodeTaiWanValid: 'Taiwan\'s postal code should be 3-6 digits!',
    contactPostcodeUKValid: 'UK postal codes should be 5-8 digits!',
    pleaseCheckPhoneNumber: 'Please check the mobile number of your area code',
    pleaseEnPunctuation: 'Do not use special symbols. For punctuation, use English input symbols',
    pleaseCheckURL: "Please start the link format with 'https://' or 'http://'",
    addApplicationSchFailMso: 'The form has been submitted, and no new courses can be applied for',

    noNullAndNoChinese: 'The input must be non-empty and non-Chinese!',

    stuPhoneNumberValid: "The student's phone number must be different of Follow up the contact's",
    stuEmailValid: "The student's email must be different of Follow up the contact's",
    examTotalTips:'The total score must be greater than 0',
  },
  applyCourse: {
    quickMatchCourse: 'Additional supplementary information',
    currentSchoolDontOneClick: 'The current school has no additional supplementary information',
    currentSchoolCanOneClick: 'The current school has additional information,\nplease complete the form on the left as soon as possible',
    currentCourseDontOneClick: 'No additional information for the application course temporarily',
    matchResult: 'The following is the result of additional supplementary information. If the user thinks it is inappropriate, he can reselect it.',
    courseOfficeLink: 'Course Online Application Link',
    courseLink: 'Course Link',
    quickMatching: 'Matching...',
    recoverData: 'Fetching...',
    noAjustCourse: 'No appropriate courses?',
    changeInputCourse: 'Switch input courses',
    chaneSelectCourse: 'Switch select courses',
    helpYouMatchingTitle: 'The system has matched you to the course',
    chooseOnForm: 'Currently {num} similar application courses have been matched for you. Please select your courses',
    pleaseSelectTips: ' After selection,if youneed to cancel the selection, there is a clear button on the right side ofthe drop-down menu',
    saveTemporalTips: 'Are you sure you need [information to be completed, temporarily stored] college application information?',
    temporalCourse: 'Temporal Course',
    saveTemporalTips1:'Are you sure you need [information to be completed, temporarily stored] personal information?',
  },
};
