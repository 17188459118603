<template>
  <router-view
    id="app"
    :student-id.sync="studentId"
    :public-level="publicLevel"
    :agent-id="agentId"
    :fk-platform-type="fkPlatformType"
    :lang="lang"
    :interceptors-request="interceptorsRequest"
    :at="at"
    :isBmsLogin="isBmsLogin"
    :companyId="companyId"
    :issueInnerAccount="issueInnerAccount"
  />
</template>

<script>
import { loginByPwd } from "@/api/common.js";
export default {
  data() {
    return {
      studentId: process.env.NODE_ENV == "development" ? 995 : null,
      publicLevel: "5",
      agentId: 3,
      fkPlatformType: "get_issue",
      lang: "zh",
      interceptorsRequest: null,
      at: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbl9pZCI6ImFwcGx5Xzk2YTE5OV8zMTkyMSIsIm9zc0ltYWdlc1VybCI6bnVsbCwiZ2VuZGVyIjpudWxsLCJ1c2VyX25hbWUiOiIxMDc0NSIsInNlc3Npb25faWQiOiIyMjVmY2VjOTYwM2M0N2Q5YmE4YzU4ZWM0MGUyOTI5ZSIsImlzc3VlU291cmNlIjoiR0VBIiwiY29tcGFueUFjY291bnRUeXBlIjpudWxsLCJtc29Vc2VyVHlwZSI6bnVsbCwiYXV0aG9yaXRpZXMiOlsiMTA3NDUiXSwiY2xpZW50X2lkIjoiaXNzdWVDZW50ZXIiLCJpc19hZG1pbiI6ImZhbHNlIiwid2VjaGF0X29wZW5pZCI6bnVsbCwibGljZW5zZSI6IkdFVCIsIm9zc0ZpbGVzVXJsIjpudWxsLCJsb2dpbl90aW1lIjoxNjk2NzU3NTA0NjE5LCJzY29wZSI6WyJhbGwiXSwic3RhZmZfaWQiOiIxMDc0NSIsInVzZXJIZWFkSWNvbiI6bnVsbCwibmFtZSI6Iu-8iOWGhemDqOeUs-ivt-i0puWPt--8iUlTU1VF5rWL6K-V5Luj55CGMiIsIm9hdXRoX2lkIjoiIiwiZmtfY29tcGFueV9pZCI6IjIiLCJleHAiOjE3MDc1NTc1MDQsImp0aSI6IjIxODgxZmU3LTg4MzktNGRkOS04Mzk2LTkzNzY2NWY1NzRkMSIsImZrX3BsYXRmb3JtX3R5cGUiOiJnZXRfaXNzdWUifQ.zud39IaAobR409b7G1lQldp_eEpUl6f5WF9t5KHNOds",
      authorization: "",
      loginPs: "",
      loginId: "",
      isAndroid: true,
      isBmsLogin: true,
      companyId: 2,
      issueInnerAccount: true,
    };
  },
  created() {
    window.RNLogin = (data)=>{
      this.receiveMessage(data)
    }
  },
  methods: {
    async receiveMessage(data) {
      let {
        studentId,
        publicLevel,
        agentId,
        fkPlatformType,
        lang,
        loginPs,
        loginId,
      } = data;
      if (data.type !== "msoApp") return;
      lang = lang || "zh";
      fkPlatformType = fkPlatformType || "get_mso";
      try {
        const ret = await loginByPwd({
          loginId,
          loginPs,
          fkPlatformType: "get_mso",
        });
        if (ret.data.code !== "1003") {
          this.$msge.error('login fail')
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "error",
                message: "login fail",
              })
            );
          return;
        }
        this.$msge.success('login success')
        this.studentId = studentId;
        this.publicLevel = publicLevel;
        this.agentId = agentId;
        this.fkPlatformType = fkPlatformType;
        this.lang = lang;
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "connected",
              studentId: studentId,
            })
          );
      } catch {
        this.$msge.error('login fail')
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "error",
              message: "login fail",
            })
          );
      }
    },
  },
};

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
body{
  background-color: #f1f1f1;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media screen and(max-width: 768px) {
  .stu-all-form-info {
    .forms-box {
      padding: 0 12px;
    }
  }
}
</style>