<template>
  <svg
    t="1622695612647"
    class="upload-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3135"
  >
    <path
      d="M768 352a224 224 0 0 1 0 448h-32a32 32 0 0 1 0-64h32a160 160 0 0 0 0-320 147.2 147.2 0 0 0-27.84 2.56 32 32 0 0 1-38.08-27.52 192 192 0 0 0-380.16 0 32 32 0 0 1-38.08 27.52A147.2 147.2 0 0 0 256 416a160 160 0 0 0 0 320h32a32 32 0 0 1 0 64h-32a224 224 0 0 1 0-448h8.32a256 256 0 0 1 495.36 0z"
      p-id="3136"
    ></path>
    <path
      d="M544 896a32 32 0 0 1-64 0V608a32 32 0 0 1 64 0z"
      p-id="3137"
    ></path>
    <path
      d="M424 757.12a32 32 0 1 1-48-42.24l112-128a32 32 0 0 1 48 0l112 128a32 32 0 0 1-48 42.24l-88-100.48z"
      p-id="3138"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "UploadIcon",
  props: {
    fill: String,
  },
};
</script>
