import {
  Col,
  Row,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  Input,
  Option,
  OptionGroup,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Menu,
  MenuItem,
  Submenu,
  Tooltip,
  Upload,
  Divider,
  Dialog,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Popover,
  Tree,
  Drawer,
} from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);
export default {
  install(vue) {
    vue.use(Row);
    vue.use(Col);
    vue.use(Form);
    vue.use(FormItem);
    vue.use(Radio);
    vue.use(RadioGroup);
    vue.use(Input);
    vue.use(Option);
    vue.use(OptionGroup);
    vue.use(Select);
    vue.use(Checkbox);
    vue.use(DatePicker);
    vue.use(Button);
    vue.use(Menu);
    vue.use(MenuItem);
    vue.use(Submenu);
    vue.use(Tooltip);
    vue.use(Divider);
    vue.use(Upload);
    vue.use(Dialog);
    vue.use(CheckboxGroup);
    vue.use(Collapse);
    vue.use(CollapseItem);
    vue.use(Popover);
    vue.use(Tree)
    vue.use(Drawer)
  },
};
