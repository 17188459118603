import ElementUI from "@/utils/element-ui-setting";
import "@/assets/style/index.scss";
import Message from '../packages/components/message'
import MessageBox from '/packages/components/message-box'

// 包入口
import StudentInfo from "../packages/student-info/index.js";
import StudentBackgroundInfo from "../packages/student-background-info/index.js";
import StudentApplication from "../packages/student-application/index.js";
import StudentEducationBackground from "../packages/student-edu-background/index.js";
import StudentWorkExperience from "../packages/student-work-experience/index.js";
import StudentLanguageProficiency from "../packages/student-language-proficiency/index.js";
import StudentProfessionalQualification from "../packages/student-professional-qualification/index.js";
import StudentReferee from "../packages/student-referee/index.js";
import StudentAccommodation from "../packages/student-accommodation/index.js";
import StudentParentContact from "../packages/student-parent-contact/index.js";
import StudentFileUpload from "../packages/student-file-upload/index.js";
// mso的表单组件
import MsoStudentForm from "../packages/mso-student-form/index.js";
// 所有组件总和组件
import StudentAllFormInfo from "../packages/student-all-form-info/index.js";

// components
import TitleButtonComp from "../packages/components/title-button-comp/index.js";

import OwlMobileDate from '../packages/components/OwlMobileDate.vue'

// 以数组的结构保存组件，便于遍历
const components = [
  StudentInfo,
  StudentBackgroundInfo,
  StudentApplication,
  StudentEducationBackground,
  StudentWorkExperience,
  StudentLanguageProficiency,
  StudentProfessionalQualification,
  StudentReferee,
  StudentFileUpload,
  StudentAccommodation,
  StudentParentContact,
  StudentAllFormInfo,
  TitleButtonComp,
  MsoStudentForm,
];
// 定义 install 方法
const install = function (Vue) {
  if (install.installed) return;
  install.installed = true;
  Vue.use(ElementUI);
  Vue.prototype.$msge = Message
  Vue.prototype.$confIssue = MessageBox.confirm;
  Vue.prototype.$alrt = MessageBox.alert;
  Vue.prototype.$prmt = MessageBox.prompt;
  Vue.component('OwlMobileDate',OwlMobileDate)
  Vue.prototype.$isMobile = function(){
    const isMobileReg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return window && isMobileReg.test(window.navigator.userAgent);
  }
  Vue.prototype.$isPad = function(){
    return window && window.navigator.userAgent.indexOf('iPad')>=0;
  }
  // 遍历并注册全局组件
  components.map((component) => {
    Vue.component(component.name, component);
  });
};

// 判断是否直接引入文件，如果是，则不需要Vue.use() 非模块化开发时使用
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  // 导出的对象必须具备一个 install 方法
  install,
  // 组件列表
  ...components,
};
