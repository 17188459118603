<template>
  <div class="form-update-btn" v-if="isShow">
    <span class="txt edit" v-show="updateShow" @click="$emit('update')">{{t("common.update")}}</span>
    <span class="split" v-show="updateShow && deleteShow">|</span>
    <span class="txt delete" v-show="deleteShow" @click="$emit('delete')">{{t("common.del")}}</span>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  props:{
    isShow: {
      type: Boolean,
      default: true,
      require: false,
    },
    updateShow: {
      type: Boolean,
      default: false,
      require: false
    },
    deleteShow: {
      type: Boolean,
      default: false,
      require: false
    },
  }
}
</script>