<template>
  <div class="form-save-btn-wrap" v-if="isShow">
    <el-button :disabled="loading" :class="{'hti-button-color':formType()=='hti'?true:false}" @click="$emit('save')" class="form-save-btn" type="primary" size="small">
      <!--解决issue兼容问题-->
      <slot v-if="$slots.default"></slot>
      <span v-else>{{t("common.save")}}</span>
    </el-button>
    <el-button v-show="showSaveAndSubmit" :disabled="loading" :class="{ 'hti-button-color': formType() == 'hti' }" @click="$emit('saveAndSubmit')" class="form-save-btn" style="width:fit-content" type="primary" size="small" >
      {{ t("common.saveAndSubmit") }}
    </el-button>
    <el-button v-show="showSaveTemporal" :disabled="loading" :class="{ 'hti-button-color': formType() == 'hti' }" @click="$emit('saveTemporal')" class="form-save-btn" style="width:fit-content;margin-left:5px" type="primary" size="small" >
      {{ t("common.saveTemporal") }}
    </el-button>
    <el-button :disabled="loading" v-show="showDelete" @click="$emit('delete')" class="form-save-btn" size="small">{{t("common.cancel")}}</el-button>
  </div>
</template>
<script>
import i18n from "@/locale/index";
export default {
  mixins: [i18n],
  inject: ['formType'],
  props:{
    showSaveAndSubmit: {
      type: Boolean,
      default: false,
      require: false,
    },
    showSaveTemporal: {
      type: Boolean,
      default: false,
      require: false,
    },
    isShow: {
      type: Boolean,
      default: true,
      require: false,
    },
    loading: {
      required: false,
      defalut: false,
      type: Boolean
    },
    showDelete:{
      required: false,
      defalut: false,
      type: Boolean
    }
  }
}
</script>